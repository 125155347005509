import { CarrierActionTypes } from './constants';

type CarrierAction = { type: string, payload: {} | string };

export const carrierApiResponseSuccess = (actionType: string, data: any): CarrierAction => ({
    type: CarrierActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const carrierApiResponseError = (actionType: string, error: string): CarrierAction => ({
    type: CarrierActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllCarriers = (): CarrierAction => ({
    type: CarrierActionTypes.READ_ALL_CARRIER_REQUEST,
    payload: {},
});

export const createCarrier = (carrier): CarrierAction => ({
    type: CarrierActionTypes.CREATE_CARRIER_REQUEST,
    payload: { carrier },
});

export const updateCarrier = (carrier): CarrierAction => ({
    type: CarrierActionTypes.UPDATE_CARRIER_REQUEST,
    payload: carrier,
});

export const deleteCarrier = (id): CarrierAction => ({
    type: CarrierActionTypes.DELETE_CARRIER_REQUEST,
    payload: { id },
});
