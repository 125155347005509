import { IntegrationsActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    uploading: false,
    loadingIntegrations: false,
    error: null,
    integrations: null,
    loadingImportTemplates: false,
    importTemplates: null,
    startingCsvMapping: false,
    csvMapping: null,
    errorCsvMapping: null,
    pagination: PAGINATION_DEFAULT_VALUE,
    tokens: []
};

type IntegrationsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string, csvMapping?: any },
};

//TODO: define data type for integrations
type State = { loadingIntegrations: Boolean, error?: null, integrations: any, csvMapping: any, pagination: any };

const Integrations = (state: State = INIT_STATE, action: IntegrationsAction): any => {
    //console.log(action);
    switch (action.type) {
        case IntegrationsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case IntegrationsActionTypes.CREATE_CSV_INTEGRATION:
                    return { ...state, uploading: false }; //TODO: should the API return anything for success?
                case IntegrationsActionTypes.GET_CSV_MAPPING: {
                    return { ...state, startingCsvMapping: false, csvMapping: action.payload.data };
                }
                case IntegrationsActionTypes.GET_ALL_INTEGRATIONS: {
                    return {
                        ...state,
                        loadingIntegrations: false,
                        integrations: action.payload.data.integrations,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                case IntegrationsActionTypes.GET_ALL_TEMPLATES: {
                    return { ...state, loadingImportTemplates: false, importTemplates: action.payload.data };
                }
                case IntegrationsActionTypes.GET_INTEGRATION_APIS:
                    return { ...state, tokens: action.payload.data };
                case IntegrationsActionTypes.CREATE_INTEGRATION_API:
                    return { ...state };
                case IntegrationsActionTypes.UPDATE_INTEGRATION_API:
                    return { ...state };
                default:
                    return { ...state, loadingIntegrations: false, startingCsvMapping: false, uploading: false, loadingImportTemplates: false };
            }

        case IntegrationsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case IntegrationsActionTypes.CREATE_CSV_INTEGRATION:
                    return { ...state, uploading: false };
                case IntegrationsActionTypes.GET_CSV_MAPPING: {
                    return { ...state, startingCsvMapping: false, errorCsvMapping: action.payload.error };
                }
                default:
                    return { ...state, loadingIntegrations: false, startingCsvMapping: false, uploading: false, loadingImportTemplates: false };
            }

        case IntegrationsActionTypes.CREATE_CSV_INTEGRATION:
            return { ...state, uploading: true };
        case IntegrationsActionTypes.GET_CSV_MAPPING:
            return { ...state, startingCsvMapping: true, csvMapping: null, errorCsvMapping: null };
        case IntegrationsActionTypes.GET_ALL_INTEGRATIONS:
            return { ...state, loadingIntegrations: true, integrations: null };
        case IntegrationsActionTypes.GET_ALL_TEMPLATES:
            return { ...state, loadingImportTemplates: true, importTemplates: null };
        case IntegrationsActionTypes.GET_INTEGRATION_APIS:
            return { ...state };
        case IntegrationsActionTypes.CREATE_INTEGRATION_API:
            return { ...state };
        case IntegrationsActionTypes.UPDATE_INTEGRATION_API:
            return { ...state };
        case IntegrationsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default Integrations;
