const rateSetResponseToRateSetObject = (response) => {
    return {
        id: response.id,
        createdAt: response.created_at,
        name: response.name,
        startDate: response.start_date,
        endDate: response.end_date,
        enabled: response.enabled,
        carrier: response.carrier,
        services: response.services,
        type: response.type,
        fileType: response.file_type,
        priceType: response.price_type,
        description: response.description,
        belongsToUserId: response.belongs_to_user_id,
        reviewerUserId: response.reviewer_user_id,
        reviewedByUserId: response.reviewed_by_user_id,
        notes: response.notes
    };
};

export { rateSetResponseToRateSetObject };
