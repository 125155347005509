export const NewOrderActionTypes = {
    API_RESPONSE_SUCCESS: '@@new-order/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@new-order/API_RESPONSE_ERROR',
    GET_PRICE: '@@new-order/GET_PRICE',
    UPDATE_ORDER_SPEC: '@@new-order/UPDATE_ORDER_SPEC',
    RESET_ORDER_SPEC: '@@new-order/RESET_ORDER_SPEC',
    GET_RATES: '@@new-order/GET_RATES',
    CREATE_ORDER: '@@new-order/CREATE_ORDER',
    RESET_RATES: '@@new-order/RESET_RATES',
    RESET_STATE: '@@new-order/RESET_STATE',
    RESET_ALL_ERRORS: '@@new-order/RESET_ALL_ERRORS',
};
