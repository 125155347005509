// @flow
import { AutomationActionTypes } from './constants';

type AutomationAction = { type: string, payload: {} | string };

export const automationApiResponseSuccess = (actionType: string, data: any): AutomationAction => ({
    type: AutomationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const automationApiResponseError = (actionType: string, error: string): AutomationAction => ({
    type: AutomationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const automationGetAllAutomation = (): AutomationAction => ({
    type: AutomationActionTypes.GET_AUTOMATION,
    payload: {},
});

export const automationUpdateAutomation = (id, data: any): AutomationAction => ({
    type: AutomationActionTypes.UPDATE_AUTOMATION,
    payload: { id, data },
});

export const automationPartialUpdateAutomation = (id, data: any): AutomationAction => ({
    type: AutomationActionTypes.PARTIAL_UPDATE_AUTOMATION,
    payload: { id, data },
});

export const automationApplyRulesAutomation = (orderId: string, action: any): AutomationAction => ({
    type: AutomationActionTypes.APPLY_RULES,
    payload: { orderId, action },
});

export const automationCreateAutomationRules = (data: any): AutomationAction => ({
    type: AutomationActionTypes.CREATE_AUTOMATION,
    payload: { data },
});

export const automationUpdateAutomationPosition = (data: any): AutomationAction => ({
    type: AutomationActionTypes.UPDATE_POSITION_AUTOMATION,
    payload: { data },
});
