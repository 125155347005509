import { CarrierActionTypes } from './constants';

export const INIT_STATE = {
    loadingCarrier: false,
    error: null,
    carriers: null,
};

type CarrierAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loadingCarrier: Boolean,
    error?: null,
    carriers: any,
};

const Carrier = (state: State = INIT_STATE, action: CarrierAction): any => {
    switch (action.type) {
        case CarrierActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case CarrierActionTypes.READ_ALL_CARRIER_REQUEST: {
                    return { ...state, carriers: action.payload.data, loadingCarrier: false };
                }
                default:
                    return { ...state, loadingCarrier: false };
            }

        case CarrierActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case CarrierActionTypes.READ_ALL_CARRIER_REQUEST: {
                    return { ...state, loadingCarrier: false, error: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case CarrierActionTypes.READ_ALL_CARRIER_REQUEST:
            return { ...state, loadingCarrier: true, carriers: null };
        default:
            return { ...state };
    }
};

export default Carrier;
