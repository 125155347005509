import { ProductActionTypes } from './constants';

type ProductsAction = { type: string, payload: {} | string };

export const productsApiResponseSuccess = (actionType: string, data: any): ProductsAction => ({
    type: ProductActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const productsApiResponseError = (actionType: string, error: string): ProductsAction => ({
    type: ProductActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const productsGetAllProducts = (query: string): ProductsAction => ({
    type: ProductActionTypes.READ_ALL_PRODUCTS_REQUEST,
    payload: { query },
});

export const productsCreateProduct = (product: any, callback): ProductsAction => ({
    type: ProductActionTypes.CREATE_PRODUCT_REQUEST,
    payload: { product, callback },
});

export const productsUpdateProduct = (productId, product: any): ProductsAction => ({
    type: ProductActionTypes.UPDATE_PRODUCT_REQUEST,
    payload: { productId, product },
});

export const productsDeleteProduct = (productId: any): ProductsAction => ({
    type: ProductActionTypes.DELETE_PRODUCT_REQUEST,
    payload: { productId },
});