import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    readAllAddresses,
    createAddress,
    readAddress,
    updateAddress,
    deleteAddress,
    verifyAddress,
} from '../../helpers';
import {
    addressBookApiResponseSuccess,
    addressBookApiResponseError,
    addressDisableValidation,
    addressBookGetAllAddresses,
    addressBookGetDefaultAddress,
} from './actions';
import { AddressBookActionTypes } from './constants';
import {
    addressBookEntryResponseToAddressBookEntryObject,
    addressBookEntryObjectToAddressBookEntryRequest,
} from '../../helpers/mappers/addressbook';
import { verifyAddressObjectToRequest } from '../../helpers/mappers/verifyAddress';
import { US_COUNTRY_CODE } from '../../constants';
import { readDefaultAddress } from '../../helpers/api/addresses';

export function* readAllAddressesSaga({ payload: { query } }) {
    try {
        const response = yield call(readAllAddresses, query);
        const addressEntries = response.data.map((addressResponse) =>
            addressBookEntryResponseToAddressBookEntryObject(addressResponse)
        );
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.READ_ALL_ADDRESSES_REQUEST, addressEntries));
    } catch (error) {
        yield put(addressBookApiResponseError(AddressBookActionTypes.READ_ALL_ADDRESSES_REQUEST, error));
    }
}

export function* createAddressSaga({ payload: { address, callback } }) {
    try {
        const addressRequest = addressBookEntryObjectToAddressBookEntryRequest(address);
        const response = yield call(createAddress, addressRequest);
        const addressResponse = addressBookEntryResponseToAddressBookEntryObject(response);
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.CREATE_ADDRESS_REQUEST, addressResponse));
        yield put(addressBookGetAllAddresses());
        yield put(addressBookGetDefaultAddress());
    } catch (error) {
        yield put(addressBookApiResponseError(AddressBookActionTypes.CREATE_ADDRESS_REQUEST, error));
    } finally {
        callback?.();
    }
}

function* readAddressSaga({ payload: { addressId } }) {
    try {
        const response = yield call(readAddress, addressId);
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.READ_ADDRESS_REQUEST, response));
    } catch (error) {
        yield put(addressBookApiResponseError(AddressBookActionTypes.READ_ADDRESS_REQUEST, error));
    }
}

function* updateAddressSaga({ payload: { addressId, address } }) {
    try {
        const addressRequest = addressBookEntryObjectToAddressBookEntryRequest(address);
        const response = yield call(updateAddress, addressId, addressRequest);
        const addressResponse = addressBookEntryResponseToAddressBookEntryObject(response);
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.UPDATE_ADDRESS_REQUEST, addressResponse));
        yield put(addressBookGetDefaultAddress());
        yield put(addressBookGetAllAddresses());
    } catch (error) {
        yield put(addressBookApiResponseError(AddressBookActionTypes.UPDATE_ADDRESS_REQUEST, error));
    }
}

function* deleteAddressSaga({ payload: { addressId } }) {
    try {
        // response should be HTTP success with empty body
        console.log('deleting', addressId);
        const response = yield call(deleteAddress, addressId);
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.DELETE_ADDRESS_REQUEST, response));
        yield put(addressBookGetAllAddresses());
    } catch (error) {
        yield put(addressBookApiResponseError(AddressBookActionTypes.DELETE_ADDRESS_REQUEST, error));
    }
}

function* validateAddressSaga({ payload: { address } }) {
    try {
        if (address.countryCode !== US_COUNTRY_CODE) {
            yield put(addressDisableValidation());
            return;
        }

        const response = yield call(verifyAddress, verifyAddressObjectToRequest(address));
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.VALIDATE_ADDRESS_REQUEST, response.data));
    } catch (error) {
        yield put(addressBookApiResponseError(AddressBookActionTypes.VALIDATE_ADDRESS_REQUEST, error));
    }
}

function* readDefaultAddressSaga() {
    try {
        const response = yield call(readDefaultAddress);
        const addressEntry = addressBookEntryResponseToAddressBookEntryObject(response.data);
        yield put(addressBookApiResponseSuccess(AddressBookActionTypes.READ_DEFAULT_ADDRESS_REQUEST, addressEntry));
    } catch (_) {}
}

export function* watchReadDefaultAddressSaga(): any {
    yield takeLatest(AddressBookActionTypes.READ_DEFAULT_ADDRESS_REQUEST, readDefaultAddressSaga);
}

export function* watchReadAllAddressesSaga(): any {
    yield takeLatest(AddressBookActionTypes.READ_ALL_ADDRESSES_REQUEST, readAllAddressesSaga);
}

export function* watchCreateAddressSaga(): any {
    yield takeLatest(AddressBookActionTypes.CREATE_ADDRESS_REQUEST, createAddressSaga);
}

export function* watchReadAddressSaga(): any {
    yield takeLatest(AddressBookActionTypes.READ_ADDRESS_REQUEST, readAddressSaga);
}

export function* watchUpdateAddressSaga(): any {
    yield takeLatest(AddressBookActionTypes.UPDATE_ADDRESS_REQUEST, updateAddressSaga);
}

export function* watchDeleteAddressSaga(): any {
    yield takeLatest(AddressBookActionTypes.DELETE_ADDRESS_REQUEST, deleteAddressSaga);
}

export function* watchValidateAddressSaga(): any {
    yield takeLatest(AddressBookActionTypes.VALIDATE_ADDRESS_REQUEST, validateAddressSaga);
}

function* addressBookSaga() {
    yield all([
        fork(watchReadAllAddressesSaga),
        fork(watchCreateAddressSaga),
        fork(watchReadAddressSaga),
        fork(watchUpdateAddressSaga),
        fork(watchDeleteAddressSaga),
        fork(watchValidateAddressSaga),
        fork(watchReadDefaultAddressSaga),
    ]);
}

export default addressBookSaga;
