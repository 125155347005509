import { BrowserRouter, useLocation } from 'react-router-dom';
import { AllRoutes } from './index';
import Toast from '../components/Toast';

const Routes = () => {

    return (
        <BrowserRouter>
            <Toast />
            <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
