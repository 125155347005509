const invitationResponseToInvitationObject = (response) => {
    return {
        invitationId: response.invitation_id,
        userId: response.user_id,
        name: response.name,
        email: response.email,
        status: response.status,
        active: response.active,
        ownerId: response.owner_id,
        owner: response.owner,
        roles: response.roles,
        invitationUpdatedAt: response.invitation_updated_at,
        invitationCreatedAt: response.invitation_created_at,
    };
};

export { invitationResponseToInvitationObject };
