// @flow
import { ToastActionTypes } from './constants';

export type ToastState = {
    type: 'success' | 'error',
    time: Date,
    desc: string,
};

const initialState: { toasts: ToastState[] } = {
    toasts: [],
};

function ToastReducer(state = initialState, action) {
    switch (action.type) {
        case ToastActionTypes.ADD_TOAST:
            return {
                ...state,
                toasts: [...state.toasts, action.payload],
            };

        case ToastActionTypes.REMOVE_TOAST:
            return {
                ...state,
                toasts: state.toasts.filter((toast, index) => index !== action.payload),
            };

        default:
            return state;
    }
}

export default ToastReducer;
