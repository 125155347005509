// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    getUser as getUserApi,
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
    forgotPasswordConfirm,
    create2FA as create2FAApi,
    verify2FA as verify2FAApi,
    edit2FA as edit2FAApi,
    verify2FAEdit as verify2FAEditApi,
    delete2FA as delete2FAApi,
    verify2FADeletion as verify2FADeletionApi,
    login2FA as login2FAApi,
} from '../../helpers/';

import { APICore, getUserFromLocalStorage, setAuthorization } from '../../helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import { addToast, profileGetProfile, profileGetProfile as profileGetProfileAction } from '../actions';

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - email and password
 */
function* login({ payload: { email, password, login2FACallback } }) {
    try {
        const loginResponse = yield call(loginApi, { email, password });

        // account already enabled 2FA
        if (loginResponse.data.status === 'pending') {
            login2FACallback?.(loginResponse.data);
        } else {
            let userId = loginResponse.headers.id;
            const token = loginResponse.headers.token;
            setAuthorization(token);

            const userResponse = yield call(getUserApi, { userId });

            const user = {
                id: userId,
                email: userResponse.data.email,
                role: 'Admin',
                token: token,
                firstName: userResponse.data.firstName,
                middleName: userResponse.data.middleName,
                lastName: userResponse.data.lastName,
                companyName: userResponse.data.companyName,
                line1: userResponse.data.line1,
                line2: userResponse.data.line2,
                line3: userResponse.data.line3,
                city: userResponse.data.city,
                stateProvince: userResponse.data.stateProvince,
                postalCode: userResponse.data.postalCode,
            };

            api.setLoggedInUser(user);
            yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
            yield put(profileGetProfileAction(userId));
        }
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0].message, type: 'error' }));
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* login2FA({ payload }) {
    try {
        const loginResponse = yield call(login2FAApi, payload);

        let userId = loginResponse.headers.id;
        const token = loginResponse.headers.token;
        setAuthorization(token);

        const userResponse = yield call(getUserApi, { userId });

        const user = {
            id: userId,
            email: userResponse.data.email,
            role: 'Admin',
            token: token,
            firstName: userResponse.data.firstName,
            middleName: userResponse.data.middleName,
            lastName: userResponse.data.lastName,
            companyName: userResponse.data.companyName,
            line1: userResponse.data.line1,
            line2: userResponse.data.line2,
            line3: userResponse.data.line3,
            city: userResponse.data.city,
            stateProvince: userResponse.data.stateProvince,
            postalCode: userResponse.data.postalCode,
        };

        api.setLoggedInUser(user);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
        yield put(profileGetProfileAction(userId));
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0].message, type: 'error' }));
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout() {
    try {
        // temporily comment out the logout api call since BE doesn't support this API.
        // yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: { email, password, firstName, lastName, token: registerToken, invitationId = '' } }) {
    try {
        const registerResponse = yield call(
            signupApi,
            {
                email,
                password,
                first_name: firstName,
                last_name: lastName,
                ...(invitationId && { invitation_id: invitationId }),
            },
            registerToken
        );
        // const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        // yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));

        const loginResponse = yield call(loginApi, { email, password });
        const token = loginResponse.headers.token;
        const userId = loginResponse.headers.id;
        setAuthorization(token);

        const userResponse = yield call(getUserApi, { userId });

        const user = {
            id: userId,
            email: userResponse.data.email,
            role: 'Admin',
            token: token,
            firstName: userResponse.data.firstName,
            middleName: userResponse.data.middleName,
            lastName: userResponse.data.lastName,
            companyName: userResponse.data.companyName,
            line1: userResponse.data.line1,
            line2: userResponse.data.line2,
            line3: userResponse.data.line3,
            city: userResponse.data.city,
            stateProvince: userResponse.data.stateProvince,
            postalCode: userResponse.data.postalCode,
        };
        console.log(token);
        console.log(userId);
        console.log(user);

        api.setLoggedInUser(user);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { username } }) {
    try {
        const response = yield call(forgotPasswordApi, { username });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* forgotPasswordChange({ payload: { data } }) {
    try {
        const response = yield call(forgotPasswordConfirm, data);
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, response.data));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

function* verifyLogin({ payload: { email, password, callback, loadingCallback } }) {
    try {
        const loginResponse = yield call(loginApi, { email, password });
        const token = loginResponse.headers.token;
        setAuthorization(token);

        if (loginResponse) {
            callback?.();
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

function* create2FA({ payload: { channel, target, callback, loadingCallback } }) {
    try {
        const response = yield call(create2FAApi, { channel, target });

        if (response?.data) {
            callback?.(response.data.sid);
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

function* verify2FA({ payload: { channel, target, otp, sid, callback, loadingCallback } }) {
    try {
        const response = yield call(verify2FAApi, { channel, target, otp, sid });

        if (response && response.data.status === 'approved') {
            callback?.();
        } else {
            yield put(addToast({ desc: response.message, type: 'error' }));
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

function* edit2FA({ payload: { channel, target, callback, loadingCallback } }) {
    try {
        const response = yield call(edit2FAApi, { channel, target });

        if (response?.data) {
            callback?.(response.data.sid);
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

function* verify2FAEdit({ payload: { channel, target, otp, sid, callback, loadingCallback } }) {
    try {
        const response = yield call(verify2FAEditApi, { channel, target, otp, sid });

        if (response && response.data.status === 'approved') {
            callback?.();
        } else {
            yield put(addToast({ desc: response.message, type: 'error' }));
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

function* delete2FA({ payload: { channel, target, callback, loadingCallback } }) {
    try {
        const response = yield call(delete2FAApi, { channel, target });

        if (response?.data) {
            callback?.(response.data.sid);
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

function* verify2FADeletion({ payload: { channel, target, otp, sid, callback, loadingCallback } }) {
    try {
        const response = yield call(verify2FADeletionApi, { channel, target, otp, sid });

        if (response && response.data.status === 'approved') {
            callback?.();
        } else {
            yield put(addToast({ desc: response.message, type: 'error' }));
        }
        yield put(profileGetProfile(getUserFromLocalStorage()?.id));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        loadingCallback?.();
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogin2FA(): any {
    yield takeEvery(AuthActionTypes.LOGIN_2FA, login2FA);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

export function* watchVerifyLogin(): any {
    yield takeEvery(AuthActionTypes.VERIFY_LOGIN, verifyLogin);
}

export function* watchCreate2FA(): any {
    yield takeEvery(AuthActionTypes.CREATE_2FA, create2FA);
}

export function* watchVerify2FA(): any {
    yield takeEvery(AuthActionTypes.VERIFY_2FA, verify2FA);
}

export function* watchEdit2FA(): any {
    yield takeEvery(AuthActionTypes.EDIT_2FA, edit2FA);
}

export function* watchVerify2FAEdit(): any {
    yield takeEvery(AuthActionTypes.VERIFY_2FA_EDIT, verify2FAEdit);
}

export function* watchDelete2FA(): any {
    yield takeEvery(AuthActionTypes.DELETE_2FA, delete2FA);
}

export function* watchVerify2FADeletion(): any {
    yield takeEvery(AuthActionTypes.VERIFY_2FA_DELETION, verify2FADeletion);
}

function* authSaga(): any {
    yield all([
        fork(watchLoginUser),
        fork(watchLogin2FA),
        fork(watchLogout),
        fork(watchSignup),
        fork(watchForgotPassword),
        fork(watchForgotPasswordChange),
        fork(watchVerifyLogin),
        fork(watchCreate2FA),
        fork(watchVerify2FA),
        fork(watchEdit2FA),
        fork(watchVerify2FAEdit),
        fork(watchDelete2FA),
        fork(watchVerify2FADeletion),
    ]);
}

export default authSaga;
