export const PackageItemsActionTypes = {
    API_RESPONSE_SUCCESS: '@@package-items/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@package-items/API_RESPONSE_ERROR',

    GET_PACKAGE_ITEMS: '@@package-items/GET_PACKAGE_ITEMS',

    GET_PACKAGE_ITEM: '@@package-items/GET_PACKAGE_ITEM',

    CREATE_PACKAGE_ITEM: '@@package-items/CREATE_PACKAGE_ITEM',

    UPDATE_PACKAGE_ITEM: '@@package-items/UPDATE_PACKAGE_ITEM',

    REMOVE_PACKAGE_ITEM: '@@package-items/REMOVE_PACKAGE_ITEM',

    RESET_ALL_ERRORS: '@@package-items/RESET_ALL_ERRORS',
};
