// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import ordersSaga from './orders/saga';
import shipmentsSaga from './shipments/saga';
import manifestsSaga from './manifests/saga';
import integrationsSaga from './integrations/saga';
import editOrderSaga from './edit-order/saga';
import viewOrderSaga from './view-order/saga';
import newOrderSaga from './new-order/saga';
import addressBookSaga from './addressbook/saga';
import manifestSaga from './manifest/saga';
import profileSaga from './profile2/sagas';
import localStorageSaga from './local-storage/saga';
import packageItemsSaga from './package-items/saga';
import packageSaga from './package/saga';
import billingModalSaga from './billing-modal/saga';
import billingSaga from './billing/saga';
import analyticsRecordsSaga from './analytics-records/saga';
import analyticsRatesSaga from './analytics-rates/saga';
import integrationErrorsSaga from './integration-errors/saga';
import automationSaga from './automation/saga';
import { navigateErrorSaga } from './navigate-error/saga';
import packageSettingsSaga from './package-settings/saga';
import carrierSaga from './carrier/saga';
import labelSettingsSaga from './labels-docs-settings/saga';
import invitationSaga from './invitation/saga';
import batchesSaga from './batches/saga';
import orderBatchErrorSaga from './order-batch-errors/saga';
import productsSaga from './products/saga';
import shopifySaga from './shopify/saga';
import orderAnalyticsSaga from './order-analytics/saga';
import shipmentAnalyticsSaga from './shipment-analytics/saga';
import zoneAnalyticsSaga from './zone-analytics/saga';
import rateSetsSaga from './rate-sets/saga';
import invoiceAnalyticsSaga from './invoice-analytics/saga';
import analyticsRatingErrorsSaga from './analytics-rating-errors/saga';

export default function* rootSaga(): any {
    yield all([
        authSaga(),
        layoutSaga(),
        ordersSaga(),
        shipmentsSaga(),
        integrationsSaga(),
        editOrderSaga(),
        viewOrderSaga(),
        newOrderSaga(),
        addressBookSaga(),
        manifestsSaga(),
        manifestSaga(),
        profileSaga(),
        localStorageSaga(),
        packageItemsSaga(),
        packageSaga(),
        analyticsRecordsSaga(),
        analyticsRatesSaga(),
        integrationErrorsSaga(),
        navigateErrorSaga(),
        billingModalSaga(),
        billingSaga(),
        packageSettingsSaga(),
        billingSaga(),
        carrierSaga(),
        automationSaga(),
        labelSettingsSaga(),
        invitationSaga(),
        batchesSaga(),
        orderBatchErrorSaga(),
        productsSaga(),
        shopifySaga(),
        orderAnalyticsSaga(),
        shipmentAnalyticsSaga(),
        zoneAnalyticsSaga(),
        rateSetsSaga(),
        invoiceAnalyticsSaga(),
        analyticsRatingErrorsSaga(),
    ]);
}
