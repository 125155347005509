import { ZoneAnalyticsActionTypes } from './constants';

type ZoneAnalyticsAction = { type: string, payload: {} | string };

export const zoneAnalyticsApiResponseSuccess = (actionType: string, data: any): ZoneAnalyticsAction => ({
    type: ZoneAnalyticsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const zoneAnalyticsApiResponseError = (actionType: string, error: string): ZoneAnalyticsAction => ({
    type: ZoneAnalyticsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const zoneAnalyticsGetAvgDomesticZone = (query: string): ZoneAnalyticsAction => ({
    type: ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE,
    payload: { query },
});

export const zoneAnalyticsGetZoneCount = (query: string): ZoneAnalyticsAction => ({
    type: ZoneAnalyticsActionTypes.GET_ZONES_COUNT,
    payload: { query },
});

export const zoneAnalyticsGetZonesShipmentsCost = (query: string): ZoneAnalyticsAction => ({
    type: ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST,
    payload: { query },
});