import React from 'react';
import { createRoot } from 'react-dom/client';

import './i18n';

import App from './App';

import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import { readFromEnv } from './config';

const container = document.getElementById('root');
const root = createRoot(container);

const environment = readFromEnv('ENVIRONMENT');

document.title = `CommerceShip ${environment === 'sandbox' ? '- SANDBOX' : ''}`;

root.render(
    <Provider store={configureStore({})}>
        <App />
    </Provider>
);
