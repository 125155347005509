import { PackageSettingsActionTypes } from './constants';

export const getPackagesSettings = () => {
    return {
        type: PackageSettingsActionTypes.GET_PACKAGES,
        payload: {},
    };
};

export const createPackageSettings = (data) => {
    return {
        type: PackageSettingsActionTypes.CREATE_PACKAGE,
        payload: {
            data,
        },
    };
};

export const updatePackageSettings = (data) => {
    return {
        type: PackageSettingsActionTypes.UPDATE_PACKAGE,
        payload: {
            data,
        },
    };
};

export const removePackageSettings = (id) => {
    return {
        type: PackageSettingsActionTypes.REMOVE_PACKAGE,
        payload: {
            id,
        },
    };
};

export const packageSettingsApiResponseSuccess = (actionType, data) => {
    return {
        type: PackageSettingsActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    };
};

export const packageSettingsApiResponseError = (actionType, error) => {
    return {
        type: PackageSettingsActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    };
};

export const packageSettingsResetState = () => {
    return {
        type: PackageSettingsActionTypes.RESET_STATE,
        payload: {},
    };
};

export const packageSettingsResetAllErrors = () => {
    return {
        type: PackageSettingsActionTypes.RESET_ALL_ERRORS,
        payload: {},
    };
};
