// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getNoCustomers(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/customers';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getNoOrders(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/orders';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getTotalOrderRevenue(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/total_order_revenue';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getDailyRevenue(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/daily_revenue';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getTopCustomers(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/top_customers';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getTopProducts(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/top_products';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getTopSources(query): any {
    let baseUrl = config.ORDER_ANALYTICS_URL;
    baseUrl += '/top_sources';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

export {
    getNoCustomers,
    getNoOrders,
    getTotalOrderRevenue,
    getDailyRevenue,
    getTopCustomers,
    getTopProducts,
    getTopSources,
};
