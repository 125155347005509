const productResponseToProductObject = (response) => {
    return {
        id: response.id,
        name: response.name,
        category: response.category,
        dateAdded: response.date_added,
        channel: response.channel,
        externalProductId: response.external_product_id,
        sku: response.sku,
        variantSku: response.variant_sku,
        upc: response.upc,
        harmonizedCode: response.harmonized_code,
        dimensions: response.dimensions,
        weight: response.weight,
        collection: response.collection,
        keywords: response.keywords,
        complianceCerts: response.compliance_certs,
        dangerousGoods: response.dangerous_goods,
        price: response.price,
        discounts: response.discounts,
        cost: response.cost,
        currencyUnit: response.currency_unit,
        stockLocation: response.stock_location,
        reorderLevel: response.reorder_level,
        quantityUnit: response.quantity_unit,
        supplierDetails: response.supplier_details,
        manufacturerDetails: response.manufacturer_details
    };
};

const productObjectToProductRequest = (object) => {
    return {
        id: object.id,
        name: object.name,
        category: object.category,
        date_added: object.dateAdded,
        channel: object.channel,
        external_product_id: object.externalProductId,
        sku: object.sku,
        variant_sku: object.variantSku,
        upc: object.upc,
        harmonized_code: object.harmonizedCode,
        dimensions: object.dimensions,
        weight: object.weight,
        collection: object.collection,
        keywords: object.keywords,
        compliance_certs: object.complianceCerts,
        dangerous_goods: object.dangerousGoods,
        price: object.price,
        discounts: object.discounts,
        cost: object.cost,
        currency_unit: object.currencyUnit,
        stock_location: object.stockLocation,
        reorder_level: object.reorderLevel,
        quantity_unit: object.quantityUnit,
        supplier_details: object.supplierDetails,
        manufacturer_details: object.manufacturerDetails
    };
};

export { productResponseToProductObject, productObjectToProductRequest };
