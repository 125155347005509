import { convertObjectKeysToCamelCase, convertObjectKeysToSnakeCase } from '../utils';

const carrierEntryResponseToCarrierEntryObject = (response) => {
    return {
        carrier: response.carrier,
        createdAt: response.created_at,
        enabled: response.enabled,
        id: response.id,
        params: convertObjectKeysToCamelCase(response.params),
    };
};

const carrierEntryObjectToCarrierEntryRequest = (object) => {
    return {
        carrier: object.carrier,
        enabled: object.enabled,
        id: object.id,
        params: convertObjectKeysToSnakeCase(object.params),
    };
};

export { carrierEntryObjectToCarrierEntryRequest, carrierEntryResponseToCarrierEntryObject };
