import secureLocalStorage from 'react-secure-storage';

import { LocalStorageActionTypes } from './constants';

export const DEFAULT_DANGEROUS_GOODS = {
    count: 0,
    forceHidden: false
}

const initialState = {
    data: {
        orderSpec: secureLocalStorage.getItem('orderSpec') || null,
        packageItems: secureLocalStorage.getItem('packageItems') || null,
        hyper_user: secureLocalStorage.getItem('hyper_user') || null,
        dangerousGoods: secureLocalStorage.getItem('dangerousGoods'),
    },
    error: null,
};

function localStorageReducer(state = initialState, action) {
    switch (action.type) {
        case LocalStorageActionTypes.SET_LOCAL_STORAGE_ITEM:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: action.payload.value,
                },
            };

        case LocalStorageActionTypes.REMOVE_LOCAL_STORAGE_ITEM:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: null,
                },
            };
        case LocalStorageActionTypes.GET_LOCAL_STORAGE_ITEM:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.key]: action.payload.value,
                },
            };
        case LocalStorageActionTypes.LOCAL_STORAGE_ITEM_ERROR:
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
}

export default localStorageReducer;
