import { ViewOrderActionTypes } from './constants';

const INIT_STATE = {
    error: null,
    loadingOrder: false,
    order: null,
    trackingEvents: null,
};

type ViewOrderAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = {
    error?: null,
    loadingOrder: Boolean,
    order?: null,
    trackingEvents?: null,
};

const ViewOrder = (state: State = INIT_STATE, action: ViewOrderAction): any => {
    //console.log(action);
    switch (action.type) {
        case ViewOrderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ViewOrderActionTypes.GET_ORDER: {
                    return { ...state, loadingOrder: false, order: action.payload.data };
                }
                case ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS: {
                    return { ...state, trackingEvents: action.payload.data };
                }
                default:
                    return { ...state };
            }

        case ViewOrderActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ViewOrderActionTypes.GET_ORDER: {
                    return { ...state, loadingOrder: false, error: action.payload.error };
                }
                case ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS: {
                    return { ...state, error: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case ViewOrderActionTypes.GET_ORDER:
            return { ...state, loadingOrder: true, order: null };
        case ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS:
            return { ...state, trackingEvents: null };
        case ViewOrderActionTypes.RESET_STATE:
            const x = INIT_STATE;
            return x;
        default:
            return { ...state };
    }
};

export default ViewOrder;
