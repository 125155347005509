// @flow
import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';

import { PaymentMethodsApi } from '../../helpers/';

import { BillingModalActions } from './actions';
import { BillingModalActionTypes } from './constants';
import { BillingActions, addToast } from '../actions';

/**
 * Load a client secret that can be used to create a payment method in Stripe.
 * Based on the secret Stripe SDK will render HTML form for the payment method
 * types that were specified in the payload. In case of multiple types
 * it will allow to select a type that user wants to add.
 * @param {*} payload - payment method types
 */
function* loadNewPaymentMethodSecret({ payload: { paymentMethodTypes } }) {
    try {
        const request = { paymentMethodTypes };
        const response = (yield call(PaymentMethodsApi.getNewPaymentMethodSecret, request));
        const secret = response.data.client_secret;
        yield put(BillingModalActions.apiResponseSuccess(BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET, secret));
    } catch (error) {
        yield put(addToast({ desc: error.message || error.errors[0].message, type: 'error' }));
        yield put(BillingModalActions.apiResponseError(BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET, error));
    }
}

function* createPaymentMethod({ payload: { providerPaymentMethodId, provider } }) {
    try {
        const request = {
            provider_payment_method_id: providerPaymentMethodId,
            provider_type: provider,
        };
        const response = yield call(PaymentMethodsApi.create, request);
        yield put(BillingModalActions.apiResponseSuccess(BillingModalActionTypes.CREATE_PAYMENT_METHOD));
        yield put(addToast({ desc: 'Saved the payment method', type: 'success' }));
        yield put(BillingActions.loadPaymentMethods());
    } catch (error) {
        yield put(addToast({ desc: error.message || error.errors[0].message, type: 'error' }));
        yield put(BillingModalActions.apiResponseError(BillingModalActionTypes.CREATE_PAYMENT_METHOD, error));
    }
}

export function* watchLoadNewPaymentMethodSecret(): any {
    yield takeEvery(BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET, loadNewPaymentMethodSecret);
}

export function* watchCreateNewPaymentMethod(): any {
    yield takeLatest(BillingModalActionTypes.CREATE_PAYMENT_METHOD, createPaymentMethod);
}

function* saga(): any {
    yield all([
        fork(watchLoadNewPaymentMethodSecret),
        fork(watchCreateNewPaymentMethod),
    ]);
}

export default saga;
