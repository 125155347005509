// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getAllIntegrationsApiKey(): any {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;

    return api.get(`${baseUrl}/${userId}/api_keys`);
}

function createIntegrationsApiKey(): any {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;

    return api.create(`${baseUrl}/${userId}/api_keys`);
}

function updateIntegrationsApiKey(id): any {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;

    // temporarily set status = deactivate when update integration API key
    return api.updatePatch(`${baseUrl}/${userId}/api_keys/${id}`, undefined, { params: { status: 'deactivate' } });
}

export { getAllIntegrationsApiKey, createIntegrationsApiKey, updateIntegrationsApiKey };
