import { ProfileActionTypes } from './constants';

export function profileGetProfile(userId) {
    return {
        type: ProfileActionTypes.GET_PROFILE,
        payload: { userId },
    };
}

export function profileApiResponseSuccess(actionType, data) {
    return {
        type: ProfileActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    };
}

export function profileApiResponseError(actionType, error) {
    return {
        type: ProfileActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    };
}

export function profileUpdateProfile(userId, profile) {
    return {
        type: ProfileActionTypes.UPDATE_PROFILE,
        payload: { userId, profile },
    };
}

export function profileUpdateEmail(email, callback) {
    return {
        type: ProfileActionTypes.UPDATE_EMAIL,
        payload: { email, callback },
    };
}

export function profileUpdateEmailConfirmation(email, sid, code, callback) {
    return {
        type: ProfileActionTypes.UPDATE_EMAIL_CONFIRMATION,
        payload: { email, sid, code, callback },
    };
}

export function profileResetAllErrors() {
    return {
        type: ProfileActionTypes.RESET_ALL_ERRORS,
        payload: {},
    };
}

export const profileGetAllUser = () => ({
    type: ProfileActionTypes.GET_ALL_USERS,
    payload: {},
});
