// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getPackages(orderId: string): any {
    const baseUrl = config.ORDERS_URL;
    return api.get(`${baseUrl}/${orderId}/packages`);
}

function getPackage(orderId: string, packageId: string): any {
    const baseUrl = config.ORDERS_URL;
    return api.get(
        `${baseUrl}/${orderId}/package_items/${packageId}`
    );
}

function createPackage(orderId: string, packageData: any): any {
    const baseUrl = config.ORDERS_URL;
    return api.create(`${baseUrl}/${orderId}/packages`, packageData);
}

function updatePackage(orderId: string, packageId: string , packageItemData: any): any {
    const baseUrl = config.ORDERS_URL;
    return api.update(`${baseUrl}/${orderId}/packages/${packageId}`, packageItemData);
}

function deletePackage(orderId: string, packageId: string): any {
    const baseUrl = config.ORDERS_URL;
    return api.delete(`${baseUrl}/${orderId}/packages/${packageId}`);
}

export { getPackages, getPackage, createPackage, updatePackage, deletePackage };
