import { AnalyticsRecordsActionTypes } from './constants';

type AnalyticsRecordsAction = { type: string, payload: {} | string };

export const analyticsRecordsApiResponseSuccess = (actionType: string, data: any): AnalyticsRecordsAction => ({
    type: AnalyticsRecordsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const analyticsRecordsApiResponseError = (actionType: string, error: string): AnalyticsRecordsAction => ({
    type: AnalyticsRecordsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllAnalyticsRecords = (query): AnalyticsRecordsAction => ({
    type: AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS,
    payload: { query },
});

export const analyticsRecordsResetAllErrors = (): AnalyticsRecordsAction => ({
    type: AnalyticsRecordsActionTypes.RESET_ALL_ERRORS,
    payload: {},
});

export const launchRatingJob = (integrationId): AnalyticsRecordsAction => ({
    type: AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB,
    payload: { integrationId },
});