// @flow

import { LabelsDocsSettingsActionTypes } from './constants';

type LabelsDocsSettingsAction = { type: string, payload: {} | string };

export const labelsDocsSettingsApiResponseSuccess = (actionType: string, data: any): LabelsDocsSettingsAction => ({
    type: LabelsDocsSettingsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const labelsDocsSettingsApiResponseError = (actionType: string, error: string): LabelsDocsSettingsAction => ({
    type: LabelsDocsSettingsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const labelsDocsSettingsGetLabelsDocsSettings = (): LabelsDocsSettingsAction => ({
    type: LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS,
    payload: {},
});

export const labelsDocsSettingsUpdateLabelsDocsSettings = (
    labelSettingId: string,
    data: any
): LabelsDocsSettingsAction => {
    return {
        type: LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS,
        payload: { data, labelSettingId },
    };
};

export const labelsDocsSettingsUpdateLabelsDocsSettingsSuccess = (data: any): LabelsDocsSettingsAction => ({
    type: LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS_SUCCESS,
    payload: { data },
});

export const labelsDocsSettingsUpdateLabelsDocsSettingsError = (error: string): LabelsDocsSettingsAction => ({
    type: LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS_ERROR,
    payload: { error },
});
