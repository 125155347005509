import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    readAllProducts,
    createProduct,
    updateProduct,
    deleteProduct,
} from '../../helpers';
import {
    productsApiResponseSuccess,
    productsApiResponseError,
    productsGetAllProducts,
} from './actions';
import { ProductActionTypes } from './constants';
import {
    productResponseToProductObject,
    productObjectToProductRequest,
} from '../../helpers/mappers/products';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

export function* readAllProductsSaga({ payload: { query } }) {
    try {
        const response = yield call(readAllProducts, query);
        const products = response.data.map((productResponse) =>
            productResponseToProductObject(productResponse)
        );
        const pagination = { ...paginationHeadersResponse(response.headers), ...query };
        yield put(productsApiResponseSuccess(ProductActionTypes.READ_ALL_PRODUCTS_REQUEST, { products, pagination }));
    } catch (error) {
        yield put(productsApiResponseError(ProductActionTypes.READ_ALL_PRODUCTS_REQUEST, error));
    }
}

export function* createProductSaga({ payload: { product, callback } }) {
    try {
        const productRequest = productObjectToProductRequest(product);
        const response = yield call(createProduct, productRequest);
        const productResponse = productResponseToProductObject(response);
        yield put(productsApiResponseSuccess(ProductActionTypes.CREATE_PRODUCT_REQUEST, productResponse));
        yield put(productsGetAllProducts());
    } catch (error) {
        yield put(productsApiResponseError(ProductActionTypes.CREATE_PRODUCT_REQUEST, error));
    } finally {
        callback?.();
    }
}

function* updateProductSaga({ payload: { productId, product } }) {
    try {
        const productRequest = productObjectToProductRequest(product);
        const response = yield call(updateProduct, productId, productRequest);
        const productResponse = productResponseToProductObject(response);
        yield put(productsApiResponseSuccess(ProductActionTypes.UPDATE_PRODUCT_REQUEST, productResponse));
        yield put(productsGetAllProducts());
    } catch (error) {
        yield put(productsApiResponseError(ProductActionTypes.UPDATE_PRODUCT_REQUEST, error));
    }
}

function* deleteProductSaga({ payload: { productId } }) {
    try {
        // response should be HTTP success with empty body
        console.log('deleting', productId);
        const response = yield call(deleteProduct, productId);
        yield put(productsApiResponseSuccess(ProductActionTypes.DELETE_PRODUCT_REQUEST, response));
        yield put(productsGetAllProducts());
    } catch (error) {
        yield put(productsApiResponseError(ProductActionTypes.DELETE_PRODUCT_REQUEST, error));
    }
}

export function* watchReadAllProductsSaga(): any {
    yield takeLatest(ProductActionTypes.READ_ALL_PRODUCTS_REQUEST, readAllProductsSaga);
}

export function* watchCreateProductSaga(): any {
    yield takeLatest(ProductActionTypes.CREATE_PRODUCT_REQUEST, createProductSaga);
}

export function* watchUpdateProductSaga(): any {
    yield takeLatest(ProductActionTypes.UPDATE_PRODUCT_REQUEST, updateProductSaga);
}

export function* watchDeleteProductSaga(): any {
    yield takeLatest(ProductActionTypes.DELETE_PRODUCT_REQUEST, deleteProductSaga);
}

function* productsSaga() {
    yield all([
        fork(watchReadAllProductsSaga),
        fork(watchCreateProductSaga),
        fork(watchUpdateProductSaga),
        fork(watchDeleteProductSaga)
    ]);
}

export default productsSaga;
