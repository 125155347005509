// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function readAllProducts(query): any {
    let baseUrl = config.PRODUCTS_URL;

    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }

    return api.get(`${baseUrl}`, {});
}

function createProduct(productRequest: any): any {
    const baseUrl = config.PRODUCTS_URL;
    return api.create(`${baseUrl}`, productRequest);
}

function updateProduct(productId: string, productRequest: any): any {
    const baseUrl = config.PRODUCTS_URL + '/' + productId;
    return api.update(`${baseUrl}`, productRequest);
}

function readProduct(productId: string): any {
    const baseUrl = config.PRODUCTS_URL + '/' + productId;
    return api.get(`${baseUrl}`, {});
}

function deleteProduct(productId: string): any {
    const baseUrl = config.PRODUCTS_URL + '/' + productId;
    return api.delete(`${baseUrl}`, {});
}

export {
    readAllProducts,
    createProduct,
    updateProduct,
    readProduct,
    deleteProduct
};
