import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ProfileActionTypes } from './constants';
import {
    profileApiResponseError,
    profileApiResponseSuccess,
    profileGetProfile as profileGetProfileAction,
} from './actions';
import { getAllUsers, getProfile, updateProfile, updateUserEmail, updateUserEmailConfirmation } from '../../helpers';
import { addToast } from '../actions';
import { invitationResponseToInvitationObject } from '../../helpers/mappers/invitation';
import { getUserFromLocalStorage } from '../../helpers/api/apiCore';

function* profileGetProfile({ payload: { userId } }) {
    try {
        const response = yield call(getProfile, userId);
        yield put(profileApiResponseSuccess(ProfileActionTypes.GET_PROFILE, response.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(profileApiResponseError(ProfileActionTypes.GET_PROFILE, error));
    }
}

function* profileUpdateProfile({ payload: { userId, profile } }) {
    try {
        const response = yield call(updateProfile, userId, profile);
        yield put(profileApiResponseSuccess(ProfileActionTypes.UPDATE_PROFILE, response.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(profileApiResponseError(ProfileActionTypes.UPDATE_PROFILE, error));
    }
}

function* profileGetAllUsers() {
    try {
        const response = yield call(getAllUsers);
        yield put(
            profileApiResponseSuccess(
                ProfileActionTypes.GET_ALL_USERS,
                response.data.map(invitationResponseToInvitationObject)
            )
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(profileApiResponseError(ProfileActionTypes.GET_ALL_USERS, error));
    }
}

function* profileUpdateEmail({ payload: { email, callback } }) {
    try {
        const response = yield call(updateUserEmail, email);
        callback?.(response.data);
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

function* profileUpdateEmailConfirmation({ payload: { email, sid, code, callback } }) {
    try {
        const response = yield call(updateUserEmailConfirmation, email, sid, code);
        callback?.();
        // retrieve user profile
        const id = getUserFromLocalStorage()?.id;
        if (id) {
            yield put(profileGetProfileAction(id));
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

function* initProfile() {
    const id = getUserFromLocalStorage()?.id;
    if (id) {
        yield put(profileGetProfileAction(id));
    }
}

function* watchProfileGetProfile() {
    yield takeLatest(ProfileActionTypes.GET_PROFILE, profileGetProfile);
}

function* watchProfileUpdateProfile() {
    yield takeLatest(ProfileActionTypes.UPDATE_PROFILE, profileUpdateProfile);
}

function* watchProfileGetAllUsers() {
    yield takeLatest(ProfileActionTypes.GET_ALL_USERS, profileGetAllUsers);
}

function* watchProfileUpdateEmail() {
    yield takeLatest(ProfileActionTypes.UPDATE_EMAIL, profileUpdateEmail);
}

function* watchProfileUpdateEmailConfirmation() {
    yield takeLatest(ProfileActionTypes.UPDATE_EMAIL_CONFIRMATION, profileUpdateEmailConfirmation);
}

function* profileSaga() {
    yield all([
        fork(initProfile),
        fork(watchProfileGetProfile),
        fork(watchProfileUpdateProfile),
        fork(watchProfileGetProfile),
        fork(watchProfileGetAllUsers),
        fork(watchProfileUpdateEmail),
        fork(watchProfileUpdateEmailConfirmation),
    ]);
}

export default profileSaga;
