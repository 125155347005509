// @flow
import { all, fork, put, takeLatest, call, join } from 'redux-saga/effects';

import { 
    getOrder as getOrderApi, 
    updateOrder as updateOrderApi, 
    getRates as getRatesApi,
    createLabelForOrder as createLabelForOrderApi
} from '../../helpers';
import { orderObjectToOrderRequest, orderResponseToOrderObject } from '../../helpers/mappers/order';

import { rateObjectToRateRequest, ratesResponseToRateObject } from '../../helpers/mappers/rate';

import { editOrderApiResponseSuccess, editOrderApiResponseError } from './actions';
import { EditOrderActionTypes } from './constants';
import { addToast, editOrderResetRates } from '../actions';

function* editOrderPurchaseOrder({ payload: { orderId, returnLabel, fullResponse } }) {
    try {
        const purchaseOrderResponse = yield call(createLabelForOrderApi, orderId, returnLabel, fullResponse);
        console.log(purchaseOrderResponse);
        // don't do anything with the response, since we'll redirect to view-order after purchase
        yield put(editOrderApiResponseSuccess(EditOrderActionTypes.PURCHASE_ORDER));
    } catch (error) {
        console.log(error);
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(editOrderApiResponseError(EditOrderActionTypes.PURCHASE_ORDER, error));
    }
}

function* getRates({ payload: { orderSpec, fullResponse } }) {
    try {
        const ratesRequest = rateObjectToRateRequest(orderSpec);
        const ratesResponse = yield call(getRatesApi, ratesRequest, fullResponse);
        const rates = ratesResponseToRateObject(ratesResponse.data);
        yield put(editOrderApiResponseSuccess(EditOrderActionTypes.GET_RATES, rates));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(editOrderApiResponseError(EditOrderActionTypes.GET_RATES, error));
    }
}

function* editOrderGetOrder({ payload: { orderId, returnLabel, fullResponse } }) {
    try {
        const getOrderResponse = yield call(getOrderApi, orderId, returnLabel, fullResponse);
        const order = orderResponseToOrderObject(getOrderResponse.data);
        yield put(editOrderApiResponseSuccess(EditOrderActionTypes.GET_ORDER, order));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(editOrderApiResponseError(EditOrderActionTypes.GET_ORDER, error));
    }
}

function* editOrderUpdateOrder({ payload: { order } }) {
    try {
        const orderRequest = orderObjectToOrderRequest(order);
        const updateOrderResponse = yield call(updateOrderApi, order.id, orderRequest);
        yield put(editOrderApiResponseSuccess(EditOrderActionTypes.UPDATE_ORDER, updateOrderResponse.data));
        //yield put(editOrderResetRates()); why was this here in the first place?
        yield put(addToast({ desc: 'Order updated successfully', type: 'success' }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(editOrderApiResponseError(EditOrderActionTypes.UPDATE_ORDER, error));
    }
}

export function* watchEditOrderPurchaseOrder(): any {
    yield takeLatest(EditOrderActionTypes.PURCHASE_ORDER, editOrderPurchaseOrder);
}

export function* watchEditOrderGetRates(): any {
    yield takeLatest(EditOrderActionTypes.GET_RATES, getRates);
}

export function* watchEditOrderGetOrder(): any {
    yield takeLatest(EditOrderActionTypes.GET_ORDER, editOrderGetOrder);
}

export function* watchEditOrderUpdateOrder(): any {
    yield takeLatest(EditOrderActionTypes.UPDATE_ORDER, editOrderUpdateOrder);
}

function* editOrderSaga(): any {
    yield all([
        fork(watchEditOrderPurchaseOrder),
        fork(watchEditOrderGetRates),
        fork(watchEditOrderGetOrder), 
        fork(watchEditOrderUpdateOrder)
    ]);
}

export default editOrderSaga;
