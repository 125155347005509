// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getAvgDomesticZone(query): any {
    let baseUrl = config.ZONES_ANALYTICS_URL;
    baseUrl += '/average_domestic_zone';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getZonesCount(query): any {
    let baseUrl = config.ZONES_ANALYTICS_URL;
    baseUrl += '/zones_count';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getZonesShipmentsCost(query): any {
    let baseUrl = config.ZONES_ANALYTICS_URL;
    baseUrl += '/zones_shipments_cost';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

export {
    getAvgDomesticZone,
    getZonesCount,
    getZonesShipmentsCost,
};
