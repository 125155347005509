const prefix = '@@billing-modal';

export const BillingModalActionTypes = {
    API_RESPONSE_SUCCESS: `${prefix}/API_RESPONSE_SUCCESS`,
    API_RESPONSE_ERROR: `${prefix}/API_RESPONSE_ERROR`,

    RESET_STATE: `${prefix}/RESET_STATE`,
    LOAD_NEW_PAYMENT_METHOD_SECRET: `${prefix}/LOAD_NEW_PAYMENT_METHOD_SECRET`,
    CREATE_PAYMENT_METHOD: `${prefix}/CREATE_PAYMENT_METHOD`,
};
