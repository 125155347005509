import { OrderBatchErrorsActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loading: false,
    error: null,
    orderBatchErrors: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type OrderBatchErrorsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    error?: null,
    orderBatchErrors: any
};

const OrderBatchErrors = (state: State = INIT_STATE, action: OrderBatchErrorsAction): any => {
    switch (action.type) {
        case OrderBatchErrorsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS: {
                    return {
                        ...state,
                        loading: false,
                        orderBatchErrors: action.payload.data,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                default:
                    return { ...state, loading: false };
            }

        case OrderBatchErrorsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS: {
                    return { ...state, loading: false, error: action.payload.error };
                }
                default:
                    return { ...state, loading: false };
            }

        case OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS:
            return { ...state, loading: true, orderBatchErrors: null };
        case OrderBatchErrorsActionTypes.RESET_STATE:
            return INIT_STATE;
        case OrderBatchErrorsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default OrderBatchErrors;
