// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getAllLabelSettings(): any {
    const userId = api.getLoggedInUser().id;
    return api.get(`${config.USERS_URL}/${userId}/label_settings`);
}

function updateLabelSettings(labelSettingId: string, data: any): any {
    const userId = api.getLoggedInUser().id;
    return api.update(`${config.USERS_URL}/${userId}/label_settings/${labelSettingId}`, data);
}

export { getAllLabelSettings, updateLabelSettings };
