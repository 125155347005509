import { IntegrationErrorsActionTypes } from './constants';

type IntegrationErrorsAction = { type: string, payload: {} | string };

export const integrationErrorsApiResponseSuccess = (actionType: string, data: any): IntegrationErrorsAction => ({
    type: IntegrationErrorsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const integrationErrorsApiResponseError = (actionType: string, error: string): IntegrationErrorsAction => ({
    type: IntegrationErrorsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllIntegrationErrors = (integrationId,): IntegrationErrorsAction => ({
    type: IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS,
    payload: { integrationId },
});

export const integrationErrorsResetState = (): IntegrationErrorsAction => ({
    type: IntegrationErrorsActionTypes.RESET_STATE,
    payload: {},
});

export const integrationErrorsResetAllErrors = (): IntegrationErrorsAction => ({
    type: IntegrationErrorsActionTypes.RESET_ALL_ERRORS,
    payload: {},
});