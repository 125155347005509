// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getPackageItems(orderId: string, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL;
    return api.get(`${baseUrl}/${orderId}/package_items`, {}, { headers: { 'full-response': fullResponse } });
}

function getPackageItem(orderId: string, packageItemId: string, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL;
    return api.get(
        `${baseUrl}/${orderId}/package_items/${packageItemId}`,
        {},
        { headers: { 'full-response': fullResponse } }
    );
}

function createPackageItem(orderId: string, packageItemData: any, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL;
    return api.create(`${baseUrl}/${orderId}/package_items`, packageItemData, {
        headers: { 'full-response': fullResponse },
    });
}

function updatePackageItem(orderId: string, packageItemId: string , packageItemData: any, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL;
    return api.update(`${baseUrl}/${orderId}/package_items/${packageItemId}`, packageItemData, {
        headers: { 'full-response': fullResponse },
    });
}

function deletePackageItem(orderId: string, packageItem: string, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL;
    return api.delete(`${baseUrl}/${orderId}/package_items/${packageItem.id}`, {
        headers: { 'full-response': fullResponse },
    });
}

export { getPackageItems, getPackageItem, createPackageItem, updatePackageItem, deletePackageItem };
