// @flow
import { LocalStorageActionTypes } from './constants';

export const setLocalStorageItem = (key, value) => ({
    type: LocalStorageActionTypes.SET_LOCAL_STORAGE_ITEM,
    payload: { key, value },
});

export const getLocalStorageItem = (key) => ({
    type: LocalStorageActionTypes.GET_LOCAL_STORAGE_ITEM,
    payload: { key },
});

export const removeLocalStorageItem = (key) => ({
    type: LocalStorageActionTypes.REMOVE_LOCAL_STORAGE_ITEM,
    payload: { key },
});

export const setLocalStorageItemError = (error) => {
    return {
        type: LocalStorageActionTypes.LOCAL_STORAGE_ITEM_ERROR,
        payload: { error },
    };
};
