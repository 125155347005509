import { ToastActionTypes } from './constants';
import type { ToastState } from './reducers';

export function addToast(toast: ToastState) {
    return {
        type: ToastActionTypes.ADD_TOAST,
        payload: toast,
    };
}

export function removeToast(id) {
    return {
        type: ToastActionTypes.REMOVE_TOAST,
        payload: id
    };
}
