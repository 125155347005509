import { error } from 'jquery';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';
import { getComparisonRatesTotalChargeAmount, launchRatingJob } from './actions';
import { AnalyticsRecordsActionTypes } from './constants';

const INIT_STATE = {
    loading: false,
    error: null,
    analyticsRecords: null,
    pagination: PAGINATION_DEFAULT_VALUE,
    launchingRatingJob: false,
    ratingJobId: null,
};

type AnalyticsRecordsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    error?: null,
    analyticsRecords: any,
    pagination: any,
    launchingRatingJob: Boolean,
    ratingJobId: string,
};

const AnalyticsRecords = (state: State = INIT_STATE, action: AnalyticsRecordsAction): any => {
    //console.log(action);
    switch (action.type) {
        case AnalyticsRecordsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS: {
                    return {
                        ...state,
                        loading: false,
                        analyticsRecords: action.payload.data.analyticsRecords,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                case AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB: {
                    return { ...state, launchingRatingJob: false, ratingJobId: action.payload.data };
                }
                default:
                    return { ...state, loading: false, launchRatingJob: false };
            }

        case AnalyticsRecordsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS: {
                    return { ...state, loading: false, error: action.payload.error };
                }
                case AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB: {
                    return { ...state, launchingRatingJob: false, error: action.payload.error };
                }
                default:
                    return { ...state, loading: false };
            }

        case AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS:
            return { ...state, loading: true, analyticsRecords: null };
        case AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB:
            return { ...state, launchingRatingJob: true, ratingJobId: null };
        case AnalyticsRecordsActionTypes.RESET_ALL_ERRORS:
            return INIT_STATE;
        default:
            return { ...state };
    }
};

export default AnalyticsRecords;
