// @flow

import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    getPackageItems as getPackageItemsApi,
    getPackageItem as getPackageItemApi,
    createPackageItem as createPackageItemApi,
    updatePackageItem as updatePackageItemApi,
    deletePackageItem as deletePackageItemApi,
} from '../../helpers';
import { getPackageItems, packageItemsApiResponseError, packageItemsApiResponseSuccess } from './actions';
import { PackageItemsActionTypes } from './constants';
import { addToast } from '../actions';
import { itemObjectToItemRequest, itemRequestToItemObject } from '../../helpers/mappers/misc';

function* getPackageItemsSaga({ payload: { orderId, fullResponse } }) {
    try {
        const packageItemsResponse = yield call(getPackageItemsApi, orderId, fullResponse);
        const packageItems = packageItemsResponse.data;
        const formattedPackageItems = packageItems.map((packageItem) => itemRequestToItemObject(packageItem));
        yield put(packageItemsApiResponseSuccess(PackageItemsActionTypes.GET_PACKAGE_ITEMS, formattedPackageItems));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageItemsApiResponseError(PackageItemsActionTypes.GET_PACKAGE_ITEMS, error));
    }
}

function* getPackageItemSaga({ payload: { orderId, id, fullResponse } }) {
    try {
        const packageItemResponse = yield call(getPackageItemApi, orderId, id, fullResponse);
        const packageItem = packageItemResponse.data;
        yield put(
            packageItemsApiResponseSuccess(
                PackageItemsActionTypes.GET_PACKAGE_ITEM,
                itemRequestToItemObject(packageItem)
            )
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageItemsApiResponseError(PackageItemsActionTypes.GET_PACKAGE_ITEM, error));
    }
}

function* createPackageItemSaga({ payload: { orderId, data, fullResponse } }) {
    try {
        const packageItemResponse = yield call(
            createPackageItemApi,
            orderId,
            itemObjectToItemRequest(data),
            fullResponse
        );
        const packageItem = packageItemResponse.data;
        yield put(packageItemsApiResponseSuccess(PackageItemsActionTypes.CREATE_PACKAGE_ITEM, packageItem));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageItemsApiResponseError(PackageItemsActionTypes.CREATE_PACKAGE_ITEM, error));
    } finally {
        yield put(getPackageItems(orderId));
    }
}

function* updatePackageItemSaga({ payload: { orderId, data, fullResponse } }) {
    try {
        const updatePackageResponse = yield call(
            updatePackageItemApi,
            orderId,
            data.id,
            itemObjectToItemRequest(data),
            fullResponse
        );
        const packageItem = updatePackageResponse.data;
        yield put(packageItemsApiResponseSuccess(PackageItemsActionTypes.UPDATE_PACKAGE_ITEM, packageItem));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageItemsApiResponseError(PackageItemsActionTypes.UPDATE_PACKAGE_ITEM, error));
    } finally {
        yield put(getPackageItems(orderId));
    }
}

function* removePackageItemSaga({ payload: { orderId, id, fullResponse } }) {
    try {
        const packageItemResponse = yield call(deletePackageItemApi, orderId, id, fullResponse);
        const packageItem = packageItemResponse.data;
        yield put(packageItemsApiResponseSuccess(PackageItemsActionTypes.REMOVE_PACKAGE_ITEM, packageItem));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageItemsApiResponseError(PackageItemsActionTypes.REMOVE_PACKAGE_ITEM, error));
    } finally {
        yield put(getPackageItems(orderId));
    }
}

export function* watchCreatePackageItem(): any {
    yield takeLatest(PackageItemsActionTypes.CREATE_PACKAGE_ITEM, createPackageItemSaga);
}

export function* watchUpdatePackageItem(): any {
    yield takeLatest(PackageItemsActionTypes.UPDATE_PACKAGE_ITEM, updatePackageItemSaga);
}

export function* watchRemovePackageItem(): any {
    yield takeLatest(PackageItemsActionTypes.REMOVE_PACKAGE_ITEM, removePackageItemSaga);
}

export function* watchGetPackageItem(): any {
    yield takeLatest(PackageItemsActionTypes.GET_PACKAGE_ITEM, getPackageItemSaga);
}

export function* watchGetPackageItems(): any {
    yield takeLatest(PackageItemsActionTypes.GET_PACKAGE_ITEMS, getPackageItemsSaga);
}

function* packageItemsSaga(): any {
    yield all([
        fork(watchCreatePackageItem),
        fork(watchUpdatePackageItem),
        fork(watchRemovePackageItem),
        fork(watchGetPackageItem),
        fork(watchGetPackageItems),
    ]);
}

export default packageItemsSaga;
