// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { string } from 'yup';

const api = new APICore();

export class PaymentMethodsApi {
  static getNewPaymentMethodSecret(
    request: { paymentMethodTypes: Array<string> },
  ): any {
    const baseUrl = `${getBaseUrl()}/secret`;
    return api.create(`${baseUrl}`, {
        types: request.paymentMethodTypes,
      }, { headers: {} },
    );
  }
  
  static getAll(): any {
    const baseUrl = getBaseUrl();
    return api.get(`${baseUrl}`, {}, { headers: {} });
  }
  
  static create(request: { providerPaymentMethodId: string, provider: string }): any {
    const baseUrl = getBaseUrl();
    return api.create(`${baseUrl}`, request, { headers: {} });
  }
}

///////////////////

function getBaseUrl(): string {
    const userId = api.getLoggedInUser().id;
    return config.PAYMENT_METHODS_URL.replace('{userId}', userId);
}