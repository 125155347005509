import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getAllInvitation() {
    const userId = api.getLoggedInUser().id;
    return api.get(`${config.USERS_URL}/${userId}/invitations`, {});
}

function createInvitation(request) {
    const userId = api.getLoggedInUser().id;
    return api.create(`${config.USERS_URL}/${userId}/invitations`, request);
}

function resendInvitation(invitationId) {
    const userId = api.getLoggedInUser().id;
    return api.get(`${config.USERS_URL}/${userId}/invitations/${invitationId}/resend-invite`);
}

function deleteInvitation(id) {
    const userId = api.getLoggedInUser().id;
    return api.delete(`${config.USERS_URL}/${userId}/invitations/${id}`, {
        headers: {
            'Content-type': 'application/json',
        },
    });
}

function updateInvitation(invitationId, request) {
    const userId = api.getLoggedInUser().id;
    return api.update(`${config.USERS_URL}/${userId}/invitations/${invitationId}`, request);
}

function updateUserRoles(userId, request) {
    return api.update(`${config.USERS_URL}/${userId}/roles`, request);
}

export { getAllInvitation, deleteInvitation, createInvitation, resendInvitation, updateInvitation, updateUserRoles };
