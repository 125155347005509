import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    getNoCustomers,
    getNoOrders,
    getTotalOrderRevenue,
    getDailyRevenue,
    getTopCustomers,
    getTopProducts,
    getTopSources,
} from '../../helpers';
import {
    orderAnalyticsApiResponseSuccess,
    orderAnalyticsApiResponseError,
} from './actions';
import { OrderAnalyticsActionTypes } from './constants';

export function* getNoCustomersSaga({ payload: { query } }) {
    try {
        const response = yield call(getNoCustomers, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST, error));
    }
}

export function* getNoOrdersSaga({ payload: { query } }) {
    try {
        const response = yield call(getNoOrders, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST, error));
    }
}

export function* getTotalOrderRevenueSaga({ payload: { query } }) {
    try {
        const response = yield call(getTotalOrderRevenue, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST, error));
    }
}

export function* getDailyRevenueSaga({ payload: { query } }) {
    try {
        const response = yield call(getDailyRevenue, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST, error));
    }
}

export function* getTopCustomersSaga({ payload: { query } }) {
    try {
        const response = yield call(getTopCustomers, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST, error));
    }
}

export function* getTopProductsSaga({ payload: { query } }) {
    try {
        const response = yield call(getTopProducts, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST, error));
    }
}

export function* getTopSourcesSaga({ payload: { query } }) {
    try {
        const response = yield call(getTopSources, query);
        yield put(orderAnalyticsApiResponseSuccess(OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST, response.data));
    } catch (error) {
        yield put(orderAnalyticsApiResponseError(OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST, error));
    }
}

export function* watchGetNoCustomersSaga(): any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST, getNoCustomersSaga);
}

export function* watchGetNoOrdersSaga(): any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST, getNoOrdersSaga);
}

export function* watchGetTotalOrderRevenueSaga() : any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST, getTotalOrderRevenueSaga);
}

export function* watchGetDailyRevenueSaga() : any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST, getDailyRevenueSaga);
}

export function* watchGetTopCustomersSaga() : any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST, getTopCustomersSaga);
}

export function* watchGetTopProductsSaga() : any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST, getTopProductsSaga);
}

export function* watchGetTopSourcesSaga() : any {
    yield takeLatest(OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST, getTopSourcesSaga);
}

function* orderAnalyticsSaga() {
    yield all([
        fork(watchGetNoCustomersSaga),
        fork(watchGetNoOrdersSaga),
        fork(watchGetTotalOrderRevenueSaga),
        fork(watchGetDailyRevenueSaga),
        fork(watchGetTopCustomersSaga),
        fork(watchGetTopProductsSaga),
        fork(watchGetTopSourcesSaga),
    ]);
}

export default orderAnalyticsSaga;
