const OrderPackageItemStatus = {
    CREATE: 'create',
    UPDATE: 'update',
    DELETE: 'delete',
};

const addressResponseToAddressObject = (response) => {
    return {
        companyName: response.company_name,
        name: response.first_name || response.last_name ? (response.first_name ?? '') + ' ' + (response.last_name ?? ''): null,
        firstName: response.first_name,
        lastName: response.last_name,
        street1: response.street1,
        street2: response.street2,
        street3: response.street3,
        cityLocality: response.city_locality,
        stateProvince: response.state_province,
        postalCode: response.postal_code,
        countryCode: response.country_code,
        carrierRouteCode: response.carrier_route_code,
        residentialIndicator: response.residential_indicator,
        email: response.email,
        phone: response.phone,
    };
};

const shipmentOptionsRequestToShipmentOptionsObject = (response) => {
    return {
        entryFacility: response.entry_facility,
        sortType: response.sort_type,
        priceType: response.price_type,
        machinable: response.machinable,
        sundayHoliday: response.sunday_holiday,
        inductionPostalCode: response.induction_postal_code,
    };
};

const itemRequestToItemObject = (response) => {
    let item = {
        description: response.description,
        quantity: response.quantity,
        itemWeightValue: response.item_weight && response.item_weight.value,
        itemWeightUnit: response.item_weight && response.item_weight.unit,
        itemValueValue: response.item_value && response.item_value.value,
        itemValueUnit: response.item_value && response.item_value.unit,
        itemDimensionsLength: response.item_dimensions && response.item_dimensions.length,
        itemDimensionsWidth: response.item_dimensions && response.item_dimensions.width,
        itemDimensionsHeight: response.item_dimensions && response.item_dimensions.height,
        itemDimensionsUnit: response.item_dimensions && response.item_dimensions.unit,
        hsTariffNumber: response.hs_tariff_number,
        sku: response.sku,
        originCountryCode: response.origin_country_code,
    };

    // Add id if it exists and has a value
    if (response.id) {
        item.id = response.id;
    }

    return item;
};

const itemObjectToItemRequest = (item) => {
    let itemRequest = {
        description: item.description,
        quantity: Number(item.quantity),
        hs_tariff_number: item.hsTariffNumber,
        sku: item.sku,
        origin_country_code: item.originCountryCode,
    };

    if (item.itemWeightValue && item.itemWeightUnit) {
        itemRequest = {
            ...itemRequest,
            item_weight: {
                value: Number(item.itemWeightValue),
                unit: item.itemWeightUnit,
            },
        };
    }

    if (item.itemValueValue && item.itemValueUnit) {
        itemRequest = {
            ...itemRequest,
            item_value: {
                value: Number(item.itemValueValue),
                unit: item.itemValueUnit,
            },
        };
    }

    if (
        item.itemDimensionsLength &&
        item.itemdimensionsLength &&
        item.itemDimensionsHeight &&
        item.itemDimensionsUnit
    ) {
        itemRequest = {
            ...itemRequest,
            item_dimensions: {
                length: item.itemDimensionsLength,
                width: item.itemdimensionsLength,
                height: item.itemDimensionsHeight,
                unit: item.itemDimensionsUnit,
            },
        };
    }

    return itemRequest;
};

const packageRequestToPackageObject = (response) => {
    let packageObject = {
        packageType: response.package_type,
        insuranceType: response.insurance_type,
        insuredValueValue: response.insured_value.value,
        insuredValueUnit: response.insured_value.unit,
        weightValue: response.weight.value,
        weightUnit: response.weight.unit,
        dimensionsLength: response.dimensions.length,
        dimensionsWidth: response.dimensions.width,
        dimensionsHeight: response.dimensions.height,
        dimensionsUnit: response.dimensions.unit,
        cubic: response.cubic,
        insuranceFee: response.insurance_fee,
        ratingWeight: response.rating_weight,
        ratingDimensions: response.rating_dimensions,
    };

    if (response.id) {
        packageObject.id = response.id;
    }

    return packageObject;
};

const packageObjectToPackageRequest = (packageObject) => {
    let packageRequest = {
        package_type: packageObject.packageType,
        insurance_type: packageObject.insuranceType,
        insured_value: {
            value: packageObject.insuredValueValue,
            unit: packageObject.insuredValueUnit,
        },
        weight: {
            value: packageObject.weightValue,
            unit: packageObject.weightUnit,
        },
        dimensions: {
            length: packageObject.dimensionsLength,
            width: packageObject.dimensionsWidth,
            height: packageObject.dimensionsHeight,
            unit: packageObject.dimensionsUnit,
        },
    };

    return packageRequest;
};

const customsDeclarationObjectToCustomsDeclarationRequest = (object) => {
    let request = {
        contents_type: object.contentType,
        restriction_type: object.restrictionType,
        eel_pfc: object.ELLorPFC,
        contents_explanation: object.contentDescription,
        customs_signer: object.customsSigner,
        restriction_comments: object.restrictionComments,
    };

    return request;
};

const customsDeclarationRequestToCustomsObject = (object) => {
    let request = {
        contentType: object.contents_type,
        restrictionType: object.restriction_type,
        ELLorPFC: object.eel_pfc,
        contentDescription: object.contents_explanation,
        customsSigner: object.customs_signer,
        restrictionComments: object.restriction_comments,
    };

    return request;
};

export {
    addressResponseToAddressObject,
    shipmentOptionsRequestToShipmentOptionsObject,
    itemRequestToItemObject,
    itemObjectToItemRequest,
    packageRequestToPackageObject,
    packageObjectToPackageRequest,
    customsDeclarationObjectToCustomsDeclarationRequest,
    customsDeclarationRequestToCustomsObject,
};
