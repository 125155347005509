import {
    addressResponseToAddressObject,
    shipmentOptionsRequestToShipmentOptionsObject,
    itemRequestToItemObject,
    itemObjectToItemRequest,
    customsDeclarationObjectToCustomsDeclarationRequest,
    customsDeclarationRequestToCustomsObject,
} from './misc';
import { manifestResponseToManifestObject } from './manifest';

const updateOrderObjectToUpdateOrderRequest = (updateSpec) => {
    return {
        service: updateSpec.service,
        package_type: updateSpec.packageType,
    };
};

const orderResponseToOrderObject = (response) => {
    const firstPackageSpec = response.packages[0];

    return {
        id: response.id,
        batch: response.batch,
        createdAt: response.created_at,
        purchased: response.purchased,
        manifested: response.manifested,
        deliveryTime: response.delivery_time,
        serviceName: response.service_name,
        serviceDisplayName: response.service_display_name,
        totalCharge: response.total_charge,
        zone: response.zone,
        orderDate: response.order_date,
        sourceType: response.source_type,
        sourceId: response.source_id,
        sourceName: response.source_name,
        trackingNumber: response.tracking_number,
        base64Labels: response.base64_labels,
        fromAddress: response.from_address && addressResponseToAddressObject(response.from_address),
        toAddress: response.to_address && addressResponseToAddressObject(response.to_address),
        returnToAddress: response.return_to_address && addressResponseToAddressObject(response.return_to_address),
        externalOrderId: response.external_order_id,
        shipDate: response.ship_date,
        warehouseId: response.warehouse_id,
        service: response.service,
        carrier: response.carrier,
        carrierAccountId: response.carrier_account_id,
        orderNumber: response.order_number,
        shipmentOptions:
            response.shipment_options && shipmentOptionsRequestToShipmentOptionsObject(response.shipment_options),
        nonDeliveryOption: response.non_delivery_option,
        returnsIndicator: response.returns_indicator,
        items: response.items && response.items.map((item) => itemRequestToItemObject(item)),
        customs: response.customs_declaration && customsDeclarationRequestToCustomsObject(response.customs_declaration),
        package: firstPackageSpec ? {
            id: firstPackageSpec.id,
            packageType: firstPackageSpec.package_type,
            weightValue:  firstPackageSpec.weight && firstPackageSpec.weight.value,
            weightUnit:  firstPackageSpec.weight && firstPackageSpec.weight.unit,
            insuredValueValue: firstPackageSpec.insured_value && firstPackageSpec.insured_value.value,
            insuredValueUnit: firstPackageSpec.insured_value && firstPackageSpec.insured_value.unit,
            dimensionsLength: firstPackageSpec.dimensions && firstPackageSpec.dimensions.length,
            dimensionsWidth: firstPackageSpec.dimensions && firstPackageSpec.dimensions.width,
            dimensionsHeight: firstPackageSpec.dimensions && firstPackageSpec.dimensions.height,
            dimensionsUnit: firstPackageSpec.dimensions && firstPackageSpec.dimensions.unit,
            cubic: firstPackageSpec.cubic,
            ratingWeightValue: firstPackageSpec.rating_weight && firstPackageSpec.rating_weight.value,
            ratingWeightUnit: firstPackageSpec.rating_weight && firstPackageSpec.rating_weight.unit,
            ratingDimensionsLength: firstPackageSpec.rating_dimensions && firstPackageSpec.rating_dimensions.length,
            ratingdimensionsLength: firstPackageSpec.rating_dimensions && firstPackageSpec.rating_dimensions.width,
            ratingDimensionsHeight: firstPackageSpec.rating_dimensions && firstPackageSpec.rating_dimensions.height,
        } : null,
        carrierManifest: response.carrier_manifest && manifestResponseToManifestObject(response.carrier_manifest),
    };
};

const orderObjectToOrderRequest = (order) => {
    let request = {
        warehouse_id: order.warehouseId,
        service: order.service,
        returns_indicator: order.returnsIndicator,
        packages: [],
        purchase: order.purchase,
    };

    if (order.fromAddress) {
        request = {
            ...request,
            from_address: {
                first_name: order.fromAddress.firstName ? order.fromAddress.firstName : order.fromAddress.name.split(' ')[0],
                last_name: order.fromAddress.lastName ? order.fromAddress.lastName : order.fromAddress.name.split(' ')[1],
                street1: order.fromAddress.street1,
                street2: order.fromAddress.street2,
                street3: order.fromAddress.street3,
                postal_code: order.fromAddress.postalCode,
                city_locality: order.fromAddress.cityLocality,
                state_province: order.fromAddress.stateProvince,
                country_code: order.fromAddress.countryCode,
                company_name: order.fromAddress.companyName,
                email: order.fromAddress.email,
                phone: order.fromAddress.phone,
            },
        };
    }

    if (order.toAddress) {
        request = {
            ...request,
            to_address: {
                first_name: order.toAddress.firstName ? order.toAddress.firstName : order.toAddress.name.split(' ')[0],
                last_name: order.toAddress.lastName ? order.toAddress.lastName : order.toAddress.name.split(' ')[1],
                street1: order.toAddress.street1,
                street2: order.toAddress.street2,
                street3: order.toAddress.street3,
                postal_code: order.toAddress.postalCode,
                city_locality: order.toAddress.cityLocality,
                state_province: order.toAddress.stateProvince,
                country_code: order.toAddress.countryCode,
                company_name: order.toAddress.companyName,
                email: order.toAddress.email,
                phone: order.toAddress.phone,
            },
        };
    }

    // untested
    if (order.returnToAddress) {
        request = {
            ...request,
            return_to_address: {
                first_name: order.returnToAddress.firstName ? order.returnToAddress.firstName : order.returnToAddress.name.split(' ')[0],
                last_name: order.returnToAddress.lastName ? order.returnToAddress.lastName : order.returnToAddress.name.split(' ')[1],
                street1: order.returnToAddress.street1,
                street2: order.returnToAddress.street2,
                street3: order.returnToAddress.street3,
                postal_code: order.returnToAddress.postalCode,
                city_locality: order.returnToAddress.cityLocality,
                state_province: order.returnToAddress.stateProvince,
                country_code: order.returnToAddress.countryCode,
                company_name: order.returnToAddress.companyName,
                email: order.returnToAddress.email,
                phone: order.returnToAddress.phone,
            },
        };
    }

    if (order.items && order.items.length > 0) {
        request = {
            ...request,
            items: order.items.map((item) => itemObjectToItemRequest(item)),
        };
    }

    let packageSpec = {};

    if (order.package && order.package.packageType) {
        packageSpec = {
            ...packageSpec,
            package_type: order.package.packageType,
        };
    }

    if (order.package && order.package.weightValue && order.package.weightUnit) {
        packageSpec = {
            ...packageSpec,
            weight: {
                value: order.package.weightValue,
                unit: order.package.weightUnit,
            },
        };
    };

    if (order.package && order.package.insuredValueValue && order.package.insuredValueUnit) {
        packageSpec = {
            ...packageSpec,
            insured_value: {
                value: order.package.insuredValueValue,
                unit: order.package.insuredValueUnit,
            },
        };
    }

    if (order.package &&
        order.package.dimensionsLength &&
        order.package.dimensionsWidth &&
        order.package.dimensionsUnit
        // order.package.dimensionsHeight
    ) {
        packageSpec = {
            ...packageSpec,
            dimensions: {
                length: order.package.dimensionsLength,
                width: order.package.dimensionsWidth,
                height: order.package.dimensionsHeight ?? 0,
                unit: order.package.dimensionsUnit,
            },
        };
    }

    if (order.customs) {
        request.customs_declaration = customsDeclarationObjectToCustomsDeclarationRequest(order.customs);
    }

    if (packageSpec && Object.keys(packageSpec).length) {
        request = {
            ...request,
            packages: [packageSpec],
        };
    }

    return request;
};

export { orderResponseToOrderObject, orderObjectToOrderRequest, updateOrderObjectToUpdateOrderRequest };
