import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function readAllCarriers() {
    const baseUrl = getBaseUrl();
    return api.get(`${baseUrl}`, {});
}

function createCarrier(carrierRequest) {
    const baseUrl = getBaseUrl();
    return api.create(`${baseUrl}`, carrierRequest);
}

function updateCarrier(id, carrierRequest) {
    const baseUrl = getBaseUrl();
    return api.update(`${baseUrl}/${id}`, carrierRequest);
}

function deleteCarrier(id) {
    const baseUrl = getBaseUrl();
    return api.delete(`${baseUrl}/${id}`);
}

export { readAllCarriers, createCarrier, deleteCarrier, updateCarrier };

function getBaseUrl() {
    const userId = api.getLoggedInUser().id;
    return config.CARRIER_URL.replace('{userId}', userId);
}
