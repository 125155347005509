// @flow
import { InvitationActionTypes } from './constants';

type InvitationAction = { type: string, payload: {} | string };

export const invitationApiResponseSuccess = (actionType: string, data: any): InvitationAction => ({
    type: InvitationActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const invitationApiResponseError = (actionType: string, error: string): InvitationAction => ({
    type: InvitationActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const invitationGetAllInvitation = (): InvitationAction => ({
    type: InvitationActionTypes.GET_ALL_INVITATION,
    payload: {},
});

export const invitationCreateInvitation = (data, callback): InvitationAction => ({
    type: InvitationActionTypes.CREATE_INVITATION,
    payload: { data, callback },
});

export const invitationResendInvitation = (invitationId, callback): InvitationAction => ({
    type: InvitationActionTypes.RESEND_INVITATION,
    payload: { invitationId, callback },
});

export const invitationDeleteInvitation = (id, callback): InvitationAction => ({
    type: InvitationActionTypes.DELETE_INVITATION,
    payload: { id, callback },
});

export const invitationUpdateInvitation = (invitationId, data, callback): InvitationAction => ({
    type: InvitationActionTypes.UPDATE_INVITATION,
    payload: { invitationId, data, callback },
});

export const invitationUpdateUserRoles = (userId, data, callback): InvitationAction => ({
    type: InvitationActionTypes.UPDATE_USER_ROLES,
    payload: { userId, data, callback },
});
