// @flow
import { all, fork, put, takeLatest, call, join } from 'redux-saga/effects';

import { getOrder as getOrderApi, getTrackingEventsForOrder as getTrackingEventsForOrderApi } from '../../helpers';
import { orderResponseToOrderObject } from '../../helpers/mappers/order';
import { TrackingEventResponseToTrackingEventObject } from '../../helpers/mappers/trackingEvents';

import { viewOrderApiResponseSuccess, viewOrderApiResponseError } from './actions';
import { ViewOrderActionTypes } from './constants';
import { addToast } from '../actions';

function* viewOrderGetOrder({ payload: { orderId, returnLabel, fullResponse } }) {
    try {
        const getOrderResponse = yield call(getOrderApi, orderId, returnLabel, fullResponse);
        const order = orderResponseToOrderObject(getOrderResponse.data);
        yield put(viewOrderApiResponseSuccess(ViewOrderActionTypes.GET_ORDER, order));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(viewOrderApiResponseError(ViewOrderActionTypes.GET_ORDER, error));
    }
}

function* viewOrderGetOrderTrackingEvents({ payload: { orderId } }) {
    try {
        const response = yield call(getTrackingEventsForOrderApi, orderId);
        const trackingEventObjects = response.data.map(trackingEventResponse => 
            TrackingEventResponseToTrackingEventObject(trackingEventResponse)
          );
        yield put(viewOrderApiResponseSuccess(ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS, trackingEventObjects));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(viewOrderApiResponseError(ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS, error));
    }
}

export function* watchViewOrderGetOrder(): any {
    yield takeLatest(ViewOrderActionTypes.GET_ORDER, viewOrderGetOrder);
}

export function* watchViewOrderGetOrderTrackingEvents(): any {
    yield takeLatest(ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS, viewOrderGetOrderTrackingEvents);
}


function* viewOrderSaga(): any {
    yield all([
        fork(watchViewOrderGetOrder), 
        fork(watchViewOrderGetOrderTrackingEvents),
    ]);
}

export default viewOrderSaga;
