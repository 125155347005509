import { InvitationActionTypes } from './constants';

const INIT_STATE = {
    loading: false,
    invitation: [],
    error: null,
};

type InvitationAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    invitation: [any],
    error: any,
};

const Invitation = (state: State = INIT_STATE, action: InvitationAction): any => {
    switch (action.type) {
        case InvitationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case InvitationActionTypes.GET_ALL_INVITATION: {
                    return { ...state, loading: false, invitation: action.payload.data };
                }

                default:
                    return { ...state };
            }

        case InvitationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case InvitationActionTypes.GET_ALL_INVITATION: {
                    return { ...state, loading: false, error: action.payload.error };
                }

                default:
                    return { ...state };
            }

        case InvitationActionTypes.GET_ALL_INVITATION:
            return { ...state, loading: true, invitation: null };

        default:
            return { ...state };
    }
};

export default Invitation;
