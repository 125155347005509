// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function createIntegrationAndStartOAuth(integrationRequest: any): any {
    const baseUrl = config.INTEGRATIONS_URL + '/shopify/auth?integrationName=' + integrationRequest.integrationName + '&shopName=' + integrationRequest.shopName;
    return api.create(baseUrl, integrationRequest)
        .then(response => {
            if (response.data.authPageUrl) {
                return { redirected: true, url: response.data.authPageUrl };
            }
            return { redirected: false, data: response.data };
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
}

export {
    createIntegrationAndStartOAuth,
};
