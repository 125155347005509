export const BatchesActionTypes = {
    API_RESPONSE_SUCCESS: '@@batches/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@batches/API_RESPONSE_ERROR',
    GET_ALL_BATCHES: '@@batches/GET_ALL_BATCHES',
    GET_SINGLE_BATCHES: '@@batches/GET_SINGLE_BATCHES',
    CREATE_BATCHES: '@@batches/CREATE_BATCHES',
    UPDATE_BATCHES: '@@batches/UPDATE_BATCHES',
    UPDATE_PATCH_BATCH: '@@batches/UPDATE_PATCH_BATCH',
    ADD_ORDERS_TO_BATCHES: '@@batches/ADD_ORDERS_TO_BATCHES',
    MOVE_ORDERS_TO_BATCHES: '@@batches/MOVE_ORDERS_TO_BATCHES',
    REMOVE_ORDERS_FROM_BATCHES: '@@batches/REMOVE_ORDERS_FROM_BATCHES',
    TRIGGER_BATCH: '@@batches/TRIGGER_BATCH',
    DOWNLOAD_LABEL_FROM_BATCH: '@@batches/DOWNLOAD_LABEL_FROM_BATCH',
};
