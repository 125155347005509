// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import { AutomationActionTypes } from './constants';
import {
    applyAutomationRule,
    createAutomationRule,
    getAutomationRules as getAutomationRulesApi,
    updateAutomationRule as updateAutomationRuleApi,
    updatePartialAutomationRule as updatePartialAutomationRuleApi,
    updatePositionAutomationRule,
} from '../../helpers/api/automation';
import { automationApiResponseSuccess, automationGetAllAutomation } from './actions';
import { addToast } from '../actions';

function* getAutomation() {
    try {
        const response = yield call(getAutomationRulesApi, { page: 0, pageSize: 9999 });
        yield put(automationApiResponseSuccess(AutomationActionTypes.GET_AUTOMATION, response.data));
    } catch (error) {
        yield put(automationApiResponseSuccess(AutomationActionTypes.GET_AUTOMATION, error));
    }
}

function* updateAutomation({
    payload: {
        id,
        data: { callback, ...rest },
    },
}) {
    try {
        const response = yield call(updateAutomationRuleApi, id, rest);
        if (response) {
            callback();
        }
    } catch (error) {
        //
    }
}

function* partialUpdateAutomation({ payload: { id, data } }) {
    try {
        const response = yield call(updatePartialAutomationRuleApi, id, data);
        if (data.deleted) {
            yield put(automationGetAllAutomation());
        }
    } catch (error) {
        //
    }
}

function* applyRulesAutomation({ payload: { orderId, action } }) {
    try {
        const response = yield call(applyAutomationRule, { orderId, action });
    } catch (error) {
        //
    }
}

function* createRulesAutomation({
    payload: {
        data: { callback, ...rest },
    },
}) {
    try {
        const response = yield call(createAutomationRule, rest);
        if (response) {
            callback();
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

function* updatePositionAutomation({ payload: { data } }) {
    try {
        const response = yield call(updatePositionAutomationRule, data);
        yield put(addToast({ desc: 'You have successfully reordered the automations.', type: 'common' }));
    } catch (error) {
        //
    }
}

export function* watchUpdatePositionAutomation(): any {
    yield takeLatest(AutomationActionTypes.UPDATE_POSITION_AUTOMATION, updatePositionAutomation);
}

export function* watchCreateAutomation(): any {
    yield takeLatest(AutomationActionTypes.CREATE_AUTOMATION, createRulesAutomation);
}

export function* watchApplyRulesAutomation(): any {
    yield takeLatest(AutomationActionTypes.APPLY_RULES, applyRulesAutomation);
}

export function* watchGetAutomation(): any {
    yield takeLatest(AutomationActionTypes.GET_AUTOMATION, getAutomation);
}

export function* watchUpdateAutomation(): any {
    yield takeLatest(AutomationActionTypes.UPDATE_AUTOMATION, updateAutomation);
}

export function* watchPartialUpdateAutomation(): any {
    yield takeLatest(AutomationActionTypes.PARTIAL_UPDATE_AUTOMATION, partialUpdateAutomation);
}

function* automationSaga(): any {
    yield all([
        fork(watchGetAutomation),
        fork(watchApplyRulesAutomation),
        fork(watchUpdateAutomation),
        fork(watchPartialUpdateAutomation),
        fork(watchCreateAutomation),
        fork(watchUpdatePositionAutomation),
    ]);
}

export default automationSaga;
