export const InvitationActionTypes = {
    API_RESPONSE_SUCCESS: '@@invitation/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@invitation/API_RESPONSE_ERROR',
    GET_ALL_INVITATION: '@@invitation/GET_ALL_INVITATION',
    CREATE_INVITATION: '@@invitation/CREATE_INVITATION',
    RESEND_INVITATION: '@@invitation/RESEND_INVITATION',
    DELETE_INVITATION: '@@invitation/DELETE_INVITATION',
    UPDATE_INVITATION: '@@invitation/UPDATE_INVITATION',
    UPDATE_USER_ROLES: '@@invitation/UPDATE_USER_ROLES',
};
