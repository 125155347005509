// @flow

import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    getPackages as getPackagesApi,
    getPackage as getPackageApi,
    createPackage as createPackageApi,
    updatePackage as updatePackageApi,
    deletePackage as deletePackageApi,
} from '../../helpers';
import { getPackages, packageApiResponseError, packageApiResponseSuccess } from './actions';
import { PackagesActionTypes } from './constants';
import { addToast } from '../actions';
import { packageRequestToPackageObject, packageObjectToPackageRequest } from '../../helpers/mappers/misc';

function* getPackagesSaga({ payload: { orderId } }) {
    try {
        const packagesResponse = yield call(getPackagesApi, orderId);
        const packages = packagesResponse.data;
        const formattedPackages = packages.map((singlePackage) => packageObjectToPackageRequest(singlePackage));
        yield put(packageApiResponseSuccess(PackagesActionTypes.GET_PACKAGES, formattedPackages));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageApiResponseError(PackagesActionTypes.GET_PACKAGES, error));
    }
}

function* getPackageSaga({ payload: { orderId, id } }) {
    try {
        const packageResponse = yield call(getPackageApi, orderId, id);
        const singlePackage = packageResponse.data;
        yield put(
            packageApiResponseSuccess(
                PackagesActionTypes.GET_PACKAGE,
                packageRequestToPackageObject(singlePackage)
            )
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageApiResponseError(PackagesActionTypes.GET_PACKAGE, error));
    }
}

function* createPackageSaga({ payload: { orderId, data, fullResponse } }) {
    try {
        const packageResponse = yield call(
            createPackageApi,
            orderId,
            packageObjectToPackageRequest(data),
            fullResponse
        );
        const singlePackage = packageResponse.data;
        yield put(packageApiResponseSuccess(PackagesActionTypes.CREATE_PACKAGE, singlePackage));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageApiResponseError(PackagesActionTypes.CREATE_PACKAGE, error));
    } finally {
        yield put(getPackages(orderId));
    }
}

function* updatePackageSaga({ payload: { orderId, data } }) {
    try {
        const updatePackageResponse = yield call(
            updatePackageApi,
            orderId,
            data.id,
            packageObjectToPackageRequest(data)
        );
        const singlePackage = updatePackageResponse.data;
        yield put(packageApiResponseSuccess(PackagesActionTypes.UPDATE_PACKAGE, singlePackage));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageApiResponseError(PackagesActionTypes.UPDATE_PACKAGE, error));
    } finally {
        yield put(getPackages(orderId));
    }
}

function* removePackageSaga({ payload: { orderId, id } }) {
    try {
        const packageResponse = yield call(deletePackageApi, orderId, id);
        const singlePackage = packageResponse.data;
        yield put(packageApiResponseSuccess(PackagesActionTypes.REMOVE_PACKAGE, singlePackage));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageApiResponseError(PackagesActionTypes.REMOVE_PACKAGE, error));
    } finally {
        yield put(getPackages(orderId));
    }
}

export function* watchCreatePackage(): any {
    yield takeLatest(PackagesActionTypes.CREATE_PACKAGE, createPackageSaga);
}

export function* watchUpdatePackage(): any {
    yield takeLatest(PackagesActionTypes.UPDATE_PACKAGE, updatePackageSaga);
}

export function* watchRemovePackage(): any {
    yield takeLatest(PackagesActionTypes.REMOVE_PACKAGE, removePackageSaga);
}

export function* watchGetPackage(): any {
    yield takeLatest(PackagesActionTypes.GET_PACKAGE, getPackageSaga);
}

export function* watchGetPackages(): any {
    yield takeLatest(PackagesActionTypes.GET_PACKAGES, getPackagesSaga);
}

function* packageSaga(): any {
    yield all([
        fork(watchCreatePackage),
        fork(watchUpdatePackage),
        fork(watchRemovePackage),
        fork(watchGetPackage),
        fork(watchGetPackages),
    ]);
}

export default packageSaga;
