import { AddressBookActionTypes } from './constants';

type AddressBookAction = { type: string, payload: {} | string };

export const addressBookApiResponseSuccess = (actionType: string, data: any): AddressBookAction => ({
    type: AddressBookActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const addressBookApiResponseError = (actionType: string, error: string): AddressBookAction => ({
    type: AddressBookActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addressBookGetAllAddresses = (query: string): AddressBookAction => ({
    type: AddressBookActionTypes.READ_ALL_ADDRESSES_REQUEST,
    payload: { query },
});

export const addressBookCreateAddress = (address: any, callback): AddressBookAction => ({
    type: AddressBookActionTypes.CREATE_ADDRESS_REQUEST,
    payload: { address, callback },
});

export const addressBookReadAddress = (addressId: any): AddressBookAction => ({
    type: AddressBookActionTypes.READ_ADDRESS_REQUEST,
    payload: { addressId },
});

export const addressBookUpdateAddress = (addressId, address: any): AddressBookAction => ({
    type: AddressBookActionTypes.UPDATE_ADDRESS_REQUEST,
    payload: { addressId, address },
});

export const addressBookDeleteAddress = (addressId: any): AddressBookAction => ({
    type: AddressBookActionTypes.DELETE_ADDRESS_REQUEST,
    payload: { addressId },
});

export const addressValidateAddress = (address: any): AddressBookAction => ({
    type: AddressBookActionTypes.VALIDATE_ADDRESS_REQUEST,
    payload: { address },
});

export const addressResetValidation = (): AddressBookAction => ({
    type: AddressBookActionTypes.RESET_VALIDATION,
    payload: {},
});

export const addressDisableValidation = (): AddressBookAction => ({
    type: AddressBookActionTypes.DISABLE_VALIDATION,
    payload: {},
});

export const addressResetAddress = (): AddressBookAction => ({
    type: AddressBookActionTypes.RESET_STATE,
    payload: {},
});

export const addressBookResetAllErrors = (): AddressBookAction => ({
    type: AddressBookActionTypes.RESET_ALL_ERRORS,
    payload: {},
});

export const addressBookGetDefaultAddress = (): AddressBookAction => ({
    type: AddressBookActionTypes.READ_DEFAULT_ADDRESS_REQUEST,
    payload: {},
});
