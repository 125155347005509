export const ProfileActionTypes = {
    API_RESPONSE_SUCCESS: '@@profile/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@profile/API_RESPONSE_ERROR',
    GET_PROFILE: '@@profile/GET_PROFILE',
    GET_ALL_USERS: '@@profile/GET_ALL_USERS',
    UPDATE_PROFILE: '@@profile/UPDATE_PROFILE',
    RESET_ALL_ERRORS: '@@profile/RESET_ALL_ERRORS',
    UPDATE_EMAIL: '@@profile/UPDATE_EMAIL',
    UPDATE_EMAIL_CONFIRMATION: '@@profile/UPDATE_EMAIL_CONFIRMATION',
};
