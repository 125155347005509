// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getAllRateSetsForUserApi(query: string): any {
    let baseUrl = config.RATE_SETS_URL;

    if (query) {
        baseUrl += `?${query}`;
    }

    return api.get(`${baseUrl}`);
}

export { getAllRateSetsForUserApi };
