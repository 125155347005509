import { InvoiceAnalyticsActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

export const INIT_STATE = {
    errorGetNoShipments: null,
    loadingNoShipments: false,
    noShipments: null,
    errorTopZone: null,
    loadingTopZone: false,
    topZone: null,
    errorTopWeight: null,
    loadingTopWeight: false,
    topWeight: null,
    errorGetCost: null,
    loadingCost: false,
    cost: null,
    errorGetAvgCost: null,
    loadingAvgCost: false,
    avgCost: null,
    loading: false,
    error: null,
    analyticsRecords: null,
    pagination: PAGINATION_DEFAULT_VALUE,
    loadingComparisonRatesTotalChargeAmount: false,
    comparisonRatesTotalChargeAmount: null,
    errorComparisonRatesTotalChargeAmount: null,
    loadingComparisonRatesAvgTotalChargeAmount: false,
    comparisonRatesAvgTotalChargeAmount: null,
    errorComparisonRatesAvgTotalChargeAmount: null,
};

type InvoiceAnalyticsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

//TODO: define data type for orders
type State = {
    errorGetNoShipments?: null,
    loadingNoShipments: Boolean,
    noShipments: any,
    errorTopZone?: null,
    loadingTopZone: Boolean,
    topZone: any,
    errorTopWeight?: null,
    loadingTopWeight: Boolean,
    topWeight: any,
    errorGetCost?: null,
    loadingCost: Boolean,
    cost: any,
    errorGetAvgCost?: null,
    loadingAvgCost: Boolean,
    avgCost: any,
    loading: Boolean,
    error?: null,
    analyticsRecords: any,
    pagination: any,
    loadingComparisonRatesTotalChargeAmount: Boolean,
    comparisonRatesTotalChargeAmount: any,
    errorComparisonRatesTotalChargeAmount: any,
    loadingComparisonRatesAvgTotalChargeAmount: Boolean,
    comparisonRatesAvgTotalChargeAmount: any,
    errorComparisonRatesAvgTotalChargeAmount: any,
};

const InvoiceAnalytics = (state: State = INIT_STATE, action: InvoiceAnalyticsAction): any => {
    switch (action.type) {
        case InvoiceAnalyticsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST: {
                    return { ...state, loadingNoShipments: false, noShipments: action.payload.data };
                }
                case InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST: {
                    return { ...state, loadingTopZone: false, topZone: action.payload.data };
                }
                case InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST: {
                    return { ...state, loadingTopWeight: false, topWeight: action.payload.data };
                }
                case InvoiceAnalyticsActionTypes.GET_COST_REQUEST: {
                    return { ...state, loadingCost: false, cost: action.payload.data };
                }
                case InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST: {
                    return { ...state, loadingAvgCost: false, avgCost: action.payload.data };
                }
                case InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS: {
                    return {
                        ...state,
                        loading: false,
                        analyticsRecords: action.payload.data.analyticsRecords,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                case InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT: {
                    console.log(action.payload.data);
                    return { ...state, loadingComparisonRatesTotalChargeAmount: false, comparisonRatesTotalChargeAmount: action.payload.data, };
                }
                case InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT: {
                    return { ...state, loadingComparisonRatesAvgTotalChargeAmount: false, comparisonRatesAvgTotalChargeAmount: action.payload.data };
                }
                default:
                    return { ...state, loadingNoShipments: false, loadingTopZone: false, loadingTopWeight: false, loadingCost: false, loadingAvgCost: false, loading: false, loadingComparisonRatesTotalChargeAmount: false, loadingComparisonRatesAvgTotalChargeAmount: false,};
            }

        case InvoiceAnalyticsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST: {
                    return { ...state, loadingNoShipments: false, errorGetNoShipments: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST: {
                    return { ...state, loadingTopZone: false, errorTopZone: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST: {
                    return { ...state, loadingTopWeight: false, errorTopWeight: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_COST_REQUEST: {
                    return { ...state, loadingCost: false, errorGetCost: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST: {
                    return { ...state, loadingAvgCost: false, errorGetAvgCost: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_ALL_ANALYTICS_RECORDS: {
                    return { ...state, loading: false, error: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT: {
                    return { ...state, loadingComparisonRatesTotalChargeAmount: false, errorComparisonRatesTotalChargeAmount: action.payload.error };
                }
                case InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT: {
                    return { ...state, loadingComparisonRatesAvgTotalChargeAmount: false, errorComparisonRatesAvgTotalChargeAmount: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST:
            return { ...state, loadingNoShipments: true, noShipments: null };
        case InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST:
            return { ...state, loadingTopZone: true, topZone: null };
        case InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST:
            return { ...state, loadingTopWeight: true, topWeight: null };
        case InvoiceAnalyticsActionTypes.GET_COST_REQUEST:
            return { ...state, loadingCost: true, cost: null };
        case InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST:
            return { ...state, loadingAvgCost: true, avgCost: null };
        case InvoiceAnalyticsActionTypes.GET_ALL_ANALYTICS_RECORDS:
            return { ...state, loading: true, analyticsRecords: null };
        case InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT:
            return { ...state, loadingComparisonRatesTotalChargeAmount: true, comparisonRatesTotalChargeAmount: null };
        case InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT:
            return { ...state, loadingComparisonRatesAvgTotalChargeAmount: true, comparisonRatesAvgTotalChargeAmount: null };
        default:
            return { ...state };
    }
};

export default InvoiceAnalytics;
