const analyticsRateResponseToAnalyticsRateObject = (response) => {
    return {
        carrier: response.carrier,
        service: response.service,
        priceType: response.price_type,
        zoneRated: response.zone_rated,
        zone: response.zone,
        countryGroup: response.country_group,
        cubic: response.cubic,
        softpack: response.softpack,
        dimensional: response.dimensional,
        ratingDate: response.rating_date,
        ratingWeight: response.rating_weight,
        ratingWeightUnit: response.rating_weight_unit,
        ratingLength: response.rating_length,
        ratingWidth: response.rating_width,
        ratingHeight: response.rating_height,
        ratingDimensionsUnit: response.rating_dimensions_unit,
        totalChargeAmount: response.total_charge_amount,
        fuelSurchargeFee: response.fuel_surcharge_fee,
        residentialSurchargeFee: response.residential_surcharge_fee,
        confirmationFee: response.confirmation_fee,
        insuranceFee: response.insurance_fee,
        otherFees: response.other_fees
    };
};

export { analyticsRateResponseToAnalyticsRateObject };
