import { PackageItemsActionTypes } from './constants';

export const getPackageItems = (orderId) => {
    return {
        type: PackageItemsActionTypes.GET_PACKAGE_ITEMS,
        payload: {
            orderId
        }
     };
};

export const getPackageItem = (orderId, id) => {
    return {
        type: PackageItemsActionTypes.GET_PACKAGE_ITEM,
        payload: {
            orderId,
            id,
        },
    };
};

export const createPackageItem = (orderId, data) => {
    return {
        type: PackageItemsActionTypes.CREATE_PACKAGE_ITEM,
        payload: {
            orderId,
            data,
        },
    };
};

export const updatePackageItem = (orderId, data, fullResponse) => {
    return {
        type: PackageItemsActionTypes.UPDATE_PACKAGE_ITEM,
        payload: {
            orderId,
            data,
            fullResponse,
        },
    };
};

export const removePackageItem = (orderId, id) => {
    return {
        type: PackageItemsActionTypes.REMOVE_PACKAGE_ITEM,
        payload: {
            orderId,
            id,
        },
    };
};

export const packageItemsApiResponseSuccess = (actionType, data) => {
    return {
        type: PackageItemsActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    };
};

export const packageItemsApiResponseError = (actionType, error) => {
    return {
        type: PackageItemsActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    };
};

export const packageItemsResetAllErrors = () => {
    return {
        type: PackageItemsActionTypes.RESET_ALL_ERRORS,
        payload: {},
    };
};