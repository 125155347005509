import { IntegrationErrorsActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loading: false,
    error: null,
    integrationErrors: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type IntegrationErrorsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    error?: null,
    integrationErrors: any
};

const IntegrationErrors = (state: State = INIT_STATE, action: IntegrationErrorsAction): any => {
    switch (action.type) {
        case IntegrationErrorsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS: {
                    return {
                        ...state,
                        loading: false,
                        integrationErrors: action.payload.data,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                default:
                    return { ...state, loading: false };
            }

        case IntegrationErrorsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS: {
                    return { ...state, loading: false, error: action.payload.error };
                }
                default:
                    return { ...state, loading: false };
            }

        case IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS:
            return { ...state, loading: true, integrationErrors: null };
        case IntegrationErrorsActionTypes.RESET_STATE:
            return INIT_STATE;
        case IntegrationErrorsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default IntegrationErrors;
