import { ShopifyActionTypes } from './constants';

export const INIT_STATE = {
    error: null,
    creatingIntegrationAndStartingOAuth: false,
    loading: false
};

type ShopifyAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    error?: null,
    creatingIntegrationAndStartingOAuth?: boolean,
    loading?: boolean,
};

const Shopify = (state: State = INIT_STATE, action: ShopifyAction): any => {
    switch (action.type) {
        case ShopifyActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST: {
                    return { ...state, creatingIntegrationAndStartingOAuth: false, loading: false};
                }
                default:
                    return { ...state, creatingIntegrationAndStartingOAuth: false, loading: false};
            }

        case ShopifyActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST: {
                    return { ...state, creatingIntegrationAndStartingOAuth: false, error: action.payload.error, loading: false};
                }
                default:
                    return { ...state };
            }

        case ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST:
            return { ...state, creatingIntegrationAndStartingOAuth: true, loading: true };
        default:
            return { ...state };
    }
};

export default Shopify;
