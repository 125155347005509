// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllAnalyticsRatingErrors as getAllAnalyticsRatingErrorsApi,
} from '../../helpers/';

import { analyticsRatingErrorsApiResponseSuccess, analyticsRatingErrorsApiResponseError } from './actions';
import { AnalyticsRatingErrorsActionTypes } from './constants';
import { analyticsRatingErrorResponseToAnalyticsRatingErrorObject } from '../../helpers/mappers/analyticsRatingError';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function* getAllAnalyticsRatingErrors({ payload: { query } }) {
    try {
        const getAllAnalyticsRatingErrorsResponse = yield call(getAllAnalyticsRatingErrorsApi, query);
        const analyticsRatingErrors = getAllAnalyticsRatingErrorsResponse.data.map((analyticsRatingErrorResponse) =>
            analyticsRatingErrorResponseToAnalyticsRatingErrorObject(analyticsRatingErrorResponse)
        );
        const pagination = { ...paginationHeadersResponse(getAllAnalyticsRatingErrorsResponse.headers), ...query };
        yield put(analyticsRatingErrorsApiResponseSuccess(AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS, { analyticsRatingErrors, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(analyticsRatingErrorsApiResponseError(AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS, error));
    }
}

export function* watchGetAllAnalyticsRatingErrors(): any {
    yield takeLatest(AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS, getAllAnalyticsRatingErrors);
}

function* analyticsRatingErrorsSaga(): any {
    yield all([fork(watchGetAllAnalyticsRatingErrors)]);
}

export default analyticsRatingErrorsSaga;
