// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getAutomationRules(params): any {
    const baseUrl = config.AUTOMATION_URL;
    return api.get(`${baseUrl}`, params);
}

function updateAutomationRule(automationRuleId: string, ruleRequest: any): any {
    const baseUrl = config.AUTOMATION_URL + '/' + automationRuleId;
    return api.update(`${baseUrl}`, ruleRequest);
}

function updatePartialAutomationRule(automationRuleId: string, ruleRequest: any): any {
    const baseUrl = config.AUTOMATION_URL + '/' + automationRuleId;
    return api.updatePatch(`${baseUrl}`, ruleRequest);
}

function applyAutomationRule(params: any): any {
    const baseUrl = `${config.AUTOMATION_URL}/applyRules`;
    return api.get(`${baseUrl}`, params);
}

function createAutomationRule(data: any): any {
    const baseUrl = `${config.AUTOMATION_URL}`;
    return api.create(`${baseUrl}`, data);
}

function updatePositionAutomationRule(data: any): any {
    const baseUrl = config.AUTOMATION_URL;
    return api.updatePatch(`${baseUrl}/positions`, data);
}

export {
    getAutomationRules,
    updateAutomationRule,
    updatePartialAutomationRule,
    applyAutomationRule,
    createAutomationRule,
    updatePositionAutomationRule,
};
