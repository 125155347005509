// @flow
import { BillingModalActionTypes } from './constants';

type BillingModalAction = { type: string, payload: {} | string };

export class BillingModalActions {
    // common success
    static apiResponseSuccess(actionType: string, data: any): BillingModalAction {
        return {
            type: BillingModalActionTypes.API_RESPONSE_SUCCESS,
            payload: { actionType, data },
        };
    }

    // common error
    static apiResponseError(actionType: string, error: string): BillingModalAction {
        return {
            type: BillingModalActionTypes.API_RESPONSE_ERROR,
            payload: { actionType, error },
        };
    };

    static resetState(): BillingModalAction {
        return {
            type: BillingModalActionTypes.RESET_STATE,
            payload: {}
        };
    }

    static loadNewPaymentMethodSecret(paymentMethodType: string): BillingModalAction {
        return {
            type: BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET,
            payload: { paymentMethodTypes: [paymentMethodType] },
        };
    };

    static createPaymentMethod({ providerPaymentMethodId, provider }: { providerPaymentMethodId: string, provider: string }): BillingModalAction {
        return {
            type: BillingModalActionTypes.CREATE_PAYMENT_METHOD,
            payload: { providerPaymentMethodId, provider },
        };
    }
}
