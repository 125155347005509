import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { NavigateErrorActionTypes } from './constants';
import {
    addressBookResetAllErrors,
    editOrderResetAllErrors,
    manifestResetAllErrors,
    manifestsResetAllErrors,
    newOrderResetAllErrors,
    ordersResetAllErrors,
    packageItemsResetAllErrors,
    profileResetAllErrors,
    resetAuthAllErrors,
    shipmentsResetAllErrors,
} from '../actions';

function* resetAllErrors() {
    yield all([
        put(resetAuthAllErrors()),
        put(ordersResetAllErrors()),
        put(shipmentsResetAllErrors()),
        put(newOrderResetAllErrors()),
        put(editOrderResetAllErrors()),
        put(manifestResetAllErrors()),
        put(addressBookResetAllErrors()),
        put(manifestsResetAllErrors()),
        put(packageItemsResetAllErrors()),
        put(profileResetAllErrors()),
    ]);
}

function* watchResetAllErrors() {
    yield takeLatest(NavigateErrorActionTypes.RESET_ALL_ERRORS, resetAllErrors);
}

export function* navigateErrorSaga() {
    yield all([fork(watchResetAllErrors)]);
}
