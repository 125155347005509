import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    getShipmentsNoCustomers,
    getShipmentsCost,
    getShipmentsAverageCost,
    getShipments,
    getShipmentsTopCarriers,
    getShipmentsTopDestinations,
    getShipmentsTopSources,
    getShipmentsServiceLevels,
    getShipmentsDailyServiceLevels,
    getShipmentAggregatedCost,
} from '../../helpers';
import {
    shipmentAnalyticsApiResponseSuccess,
    shipmentAnalyticsApiResponseError,
} from './actions';
import { ShipmentAnalyticsActionTypes } from './constants';

export function* getShipmentsNoCustomersSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsNoCustomers, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST, error));
    }
}

export function* getShipmentsCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsCost, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST, error));
    }
}

export function* getShipmentsAverageCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsAverageCost, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST, error));
    }
}

export function* getShipmentsSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipments, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST, error));
    }
}

export function* getShipmentsTopCarriersSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsTopCarriers, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST, error));
    }
}

export function* getShipmentsTopDestinationsSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsTopDestinations, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST, error));
    }
}

export function* getShipmentsTopSourcesSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsTopSources, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST, error));
    }
}

export function* getShipmentsServiceLevelsSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsServiceLevels, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST, error));
    }
}

export function* getShipmentsDailyServiceLevelsSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentsDailyServiceLevels, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST, error));
    }
}

export function* getShipmentsAggregatedCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getShipmentAggregatedCost, query);
        yield put(shipmentAnalyticsApiResponseSuccess(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST, response.data));
    } catch (error) {
        yield put(shipmentAnalyticsApiResponseError(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST, error));
    }
}

export function* watchGetShipmentsNoCustomersSaga(): any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST, getShipmentsNoCustomersSaga);
}

export function* watchGetShipmentsCostSaga(): any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST, getShipmentsCostSaga);
}

export function* watchGetShipmentsAverageCostSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST, getShipmentsAverageCostSaga);
}

export function* watchGetShipmentsSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST, getShipmentsSaga);
}

export function* watchGetShipmentsTopCarriersSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST, getShipmentsTopCarriersSaga);
}

export function* watchGetShipmentsTopDestinationsSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST, getShipmentsTopDestinationsSaga);
}

export function* watchGetShipmentsTopSourcesSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST, getShipmentsTopSourcesSaga);
}

export function* watchGetShipmentsServiceLevelsSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST, getShipmentsServiceLevelsSaga);
}

export function* watchGetShipmentsDailyServiceLevelsSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST, getShipmentsDailyServiceLevelsSaga);
}

export function* watchGetShipmentsAggregatedCostSaga() : any {
    yield takeLatest(ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST, getShipmentsAggregatedCostSaga);
}

function* shipmentAnalyticsSaga() {
    yield all([
        fork(watchGetShipmentsNoCustomersSaga),
        fork(watchGetShipmentsCostSaga),
        fork(watchGetShipmentsAverageCostSaga),
        fork(watchGetShipmentsSaga),
        fork(watchGetShipmentsTopCarriersSaga),
        fork(watchGetShipmentsTopDestinationsSaga),
        fork(watchGetShipmentsTopSourcesSaga),
        fork(watchGetShipmentsServiceLevelsSaga),
        fork(watchGetShipmentsDailyServiceLevelsSaga),
        fork(watchGetShipmentsAggregatedCostSaga),
    ]);
}

export default shipmentAnalyticsSaga;
