// @flow
import { BatchesActionTypes } from './constants';

type BatchesAction = { type: string, payload: {} | string };

export const batchesApiResponseSuccess = (actionType: string, data: any): BatchesAction => ({
    type: BatchesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const batchesApiResponseError = (actionType: string, error: string): BatchesAction => ({
    type: BatchesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
export const batchesGetAllBatches = (query): BatchesAction => ({
    type: BatchesActionTypes.GET_ALL_BATCHES,
    payload: { query },
});

export const batchesGetSingleBatches = (): BatchesAction => ({
    type: BatchesActionTypes.GET_SINGLE_BATCHES,
    payload: {},
});

export const batchesCreateBatches = (request, callback): BatchesAction => ({
    type: BatchesActionTypes.CREATE_BATCHES,
    payload: { request, callback },
});

export const batchesUpdateBatches = (request, callback): BatchesAction => ({
    type: BatchesActionTypes.UPDATE_BATCHES,
    payload: { request, callback },
});

export const batchesUpdatePatchBatch = (request, callback): BatchesAction => ({
    type: BatchesActionTypes.UPDATE_PATCH_BATCH,
    payload: { request, callback },
});

export const batchesAddOrderToBatches = (request, callback): BatchesAction => ({
    type: BatchesActionTypes.ADD_ORDERS_TO_BATCHES,
    payload: { request, callback },
});

export const batchesMoveOrderToBatches = (request, callback): BatchesAction => ({
    type: BatchesActionTypes.MOVE_ORDERS_TO_BATCHES,
    payload: { request, callback },
});

export const batchesRemoveOrderFromBatches = (request, callback): BatchesAction => ({
    type: BatchesActionTypes.REMOVE_ORDERS_FROM_BATCHES,
    payload: { request, callback },
});

export const batchesTriggerBatch = (ids, callback): BatchesAction => ({
    type: BatchesActionTypes.TRIGGER_BATCH,
    payload: { ids, callback },
});

export const batchesDownloadLabelFromBatch = (id, callback): BatchesAction => ({
    type: BatchesActionTypes.DOWNLOAD_LABEL_FROM_BATCH,
    payload: { id, callback },
});
