import { IntegrationsActionTypes } from './constants';

type IntegrationsAction = { type: string, payload: {} | string };

export const integrationsApiResponseSuccess = (actionType: string, data: any): IntegrationsAction => ({
    type: IntegrationsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const integrationsApiResponseError = (actionType: string, error: string): IntegrationsAction => ({
    type: IntegrationsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const createCsvIntegrationWithContent = (
    integration: any,
    fileFormData: any,
    callback,
    savedTemplate
): IntegrationsActionTypes => ({
    type: IntegrationsActionTypes.CREATE_CSV_INTEGRATION,
    payload: { integration, fileFormData, callback, savedTemplate },
});

export const getCsvMapping = (file, destinationFields): IntegrationsAction => ({
    type: IntegrationsActionTypes.GET_CSV_MAPPING,
    payload: { file, destinationFields },
});

export const getAllIntegrations = (): IntegrationsAction => ({
    type: IntegrationsActionTypes.GET_ALL_INTEGRATIONS,
    payload: {},
});

export const getAllTemplates = (type): IntegrationsAction => ({
    type: IntegrationsActionTypes.GET_ALL_TEMPLATES,
    payload: { type },
});

export const createTemplate = (template, type): IntegrationsAction => ({
    type: IntegrationsActionTypes.CREATE_TEMPLATE,
    payload: { template, type },
});

export const exportTemplate = (templateId, type, callback): IntegrationsAction => ({
    type: IntegrationsActionTypes.EXPORT_TEMPLATE,
    payload: { templateId, type, callback },
});

export const getIntegrationApi = (): IntegrationsAction => ({
    type: IntegrationsActionTypes.GET_INTEGRATION_APIS,
    payload: {},
});

export const createIntegrationApi = (): IntegrationsAction => ({
    type: IntegrationsActionTypes.CREATE_INTEGRATION_API,
    payload: {},
});

export const updateIntegrationApi = (id, callback): IntegrationsAction => ({
    type: IntegrationsActionTypes.UPDATE_INTEGRATION_API,
    payload: { id, callback },
});

export const resetAllErrors = (): IntegrationsAction => ({
    type: IntegrationsActionTypes.RESET_ALL_ERRORS,
    payload: {},
});
