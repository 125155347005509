// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllOrderBatchErrors as getAllOrderBatchErrorsApi,
} from '../../helpers/';

import { orderBatchErrorsApiResponseSuccess, orderBatchErrorsApiResponseError } from './actions';
import { OrderBatchErrorsActionTypes } from './constants';
import { orderBatchErrorResponseToOrderBatchErrorObject } from '../../helpers/mappers/orderBatchError';
import { addToast } from '../actions';

function* getAllOrderBatchErrors({ payload: { orderBatchId } }) {
    try {
        const getAllOrderBatchErrorsResponse = yield call(getAllOrderBatchErrorsApi, orderBatchId);
        console.log(getAllOrderBatchErrorsResponse);
        const orderBatchErrors = getAllOrderBatchErrorsResponse.data.map((orderBatchErrorResponse) =>
            orderBatchErrorResponseToOrderBatchErrorObject(orderBatchErrorResponse)
        );
        console.log(orderBatchErrors);
        yield put(orderBatchErrorsApiResponseSuccess(OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS, orderBatchErrors));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(orderBatchErrorsApiResponseError(OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS, error));
    }
}

export function* watchGetAllOrderBatchErrors(): any {
    yield takeLatest(OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS, getAllOrderBatchErrors);
}

function* orderBatchErrorsSaga(): any {
    yield all([fork(watchGetAllOrderBatchErrors)]);
}

export default orderBatchErrorsSaga;
