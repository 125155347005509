export const PackageSettingsActionTypes = {
    API_RESPONSE_SUCCESS: '@@packageSettings/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@packageSettings/API_RESPONSE_ERROR',

    GET_PACKAGES: '@@packageSettings/GET_PACKAGES',

    CREATE_PACKAGE: '@@packageSettings/CREATE_PACKAGE',

    UPDATE_PACKAGE: '@@packageSettings/UPDATE_PACKAGE',

    REMOVE_PACKAGE: '@@packageSettings/REMOVE_PACKAGE',

    RESET_STATE: '@@packageSettings/RESET_STATE',

    RESET_ALL_ERRORS: '@@packageSettings/RESET_ALL_ERRORS',
};
