// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { PAGINATION_DEFAULT_VALUE } from '../defaultValue';
import moment from 'moment';

const api = new APICore();

type Query = {
    currentPage: number,
    pageSize: number,
    search: string,
};

//TODO: move to rating.js
function getRates(ratesRequest: any, fullResponse: Boolean): any {
    const baseUrl = config.PRICING_URL;
    return api.create(`${baseUrl}`, ratesRequest, { headers: { 'full-response': fullResponse } });
}

function createOrder(orderRequest: any, returnLabel: Boolean, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL;
    return api.create(`${baseUrl}`, orderRequest, {
        headers: { 'return-label': returnLabel, 'full-response': fullResponse },
    });
}

function updateOrder(orderId: string, orderRequest: any, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL + '/' + orderId;
    return api.update(`${baseUrl}`, orderRequest);
}

function updatePatchOrder(orderId: string, patchOrderRequest: any, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL + '/' + orderId;
    return api.updatePatch(`${baseUrl}`, patchOrderRequest, { headers: { 'full-response': fullResponse } });
}

function getOrder(orderId: string, returnLabel: Boolean, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL + '/' + orderId;
    return api.get(`${baseUrl}`, {}, { headers: { 'return-label': returnLabel, 'full-response': fullResponse } });
}

function getRatesForOrder(orderId: string, fullResponse: Boolean): any {
    const baseUrl = config.ORDERS_URL + '/' + orderId + '/' + 'rates';
    return api.get(`${baseUrl}`, {}, { headers: { 'full-response': fullResponse } });
}

function createLabelForOrder(orderId: string, fullResponse: Boolean, returnLabel = true): any {
    const baseUrl = config.ORDERS_URL + '/' + orderId + '/' + 'label';
    return api.get(`${baseUrl}`, {}, { headers: { 'full-response': fullResponse, 'return-label': returnLabel } });
}

function getTrackingEventsForOrder(orderId: string): any {
    const baseUrl = config.ORDERS_URL + '/' + orderId + '/' + 'tracking';
    return api.get(`${baseUrl}`, {});
}

function getAllOrders(returnLabel: Boolean, fullResponse: Boolean, query: Query): any {
    const page = query.currentPage || PAGINATION_DEFAULT_VALUE.currentPage;
    const page_size = query.pageSize || PAGINATION_DEFAULT_VALUE.pageSize;

    let baseUrl = config.ORDERS_URL;

    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }

    const queryParam = {
        page: page.toString(),
        page_size: page_size.toString(),
    };

    return api.get(`${baseUrl}`, typeof query === 'object' ? queryParam : undefined, {
        headers: {
            'return-label': returnLabel,
            'full-response': fullResponse,
        },
    });
}

export {
    getRates,
    createOrder,
    updateOrder,
    updatePatchOrder,
    getOrder,
    getAllOrders,
    getRatesForOrder,
    createLabelForOrder,
    getTrackingEventsForOrder
};
