import { PackageSettingsActionTypes } from './constants';

const INITIAL_STATE = {
    packagesSettings: [],
    loading: false,
    error: null,
    creating: false,
    created: false,
    createdpackageSettingsId: null,
};

const PackageSettings = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PackageSettingsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PackageSettingsActionTypes.GET_PACKAGES: {
                    return { ...state, packagesSettings: action.payload.data, loading: false };
                }
                case PackageSettingsActionTypes.CREATE_PACKAGE: {
                    return { ...state, creating: false, created: true, createdpackageSettingsId: action.payload.data };
                }
                case PackageSettingsActionTypes.UPDATE_PACKAGE: {
                    return { ...state, creating: false, created: true, createdpackageSettingsId: action.payload.data };
                }
                case PackageSettingsActionTypes.REMOVE_PACKAGE: {
                    return { ...state, creating: false, created: true, createdpackageSettingsId: action.payload.data };
                }
                default:
                    return { ...state };
            }

        case PackageSettingsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PackageSettingsActionTypes.GET_PACKAGES: {
                    return { ...state, error: action.payload.error, loading: false };
                }
                case PackageSettingsActionTypes.CREATE_PACKAGE: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                case PackageSettingsActionTypes.UPDATE_PACKAGE: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                case PackageSettingsActionTypes.REMOVE_PACKAGE: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case PackageSettingsActionTypes.GET_PACKAGES:
            return { ...state, loading: true, packagesSettings: [] };
        case PackageSettingsActionTypes.CREATE_PACKAGE:
            return { ...state, creating: true, created: false };
        case PackageSettingsActionTypes.UPDATE_PACKAGE:
            return { ...state, creating: true, created: false };
        case PackageSettingsActionTypes.REMOVE_PACKAGE:
            return { ...state, creating: true, created: false };
        case PackageSettingsActionTypes.RESET_STATE:
            const x = INITIAL_STATE;
            x.packagesSettings = [];
            return x;
        case PackageSettingsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default PackageSettings;
