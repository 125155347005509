// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { PAGINATION_DEFAULT_VALUE } from '../defaultValue';

const api = new APICore();

function getCsvMapping(file, destinationFields) {
    const baseUrl = `${config.INTEGRATIONS_URL}/templates/get_mapping`;
    const formData = { file: file, destinationFields: destinationFields };
    return api.createWithFile(baseUrl, formData);
}

function getAllIntegrations(): any {
    const baseUrl = config.INTEGRATIONS_URL;
    return api.get(`${baseUrl}`, {});
}

function createIntegration(integration: any): any {
    const baseUrl = config.INTEGRATIONS_URL;

    const integrationRequest = {
        integration_name: integration.integrationName,
        integration_type: integration.integrationType,
        import_type: integration.importType,
        auto_import: false,
        active: integration.active,
        params: integration.params,
    };

    return api.create(`${baseUrl}`, integrationRequest);
}

function integrationFileUpload(integrationId: string, fileFormData: string): any {
    const baseUrl = config.INTEGRATIONS_URL + '/' + integrationId + '/' + 'file';
    return api.createWithFile(`${baseUrl}`, fileFormData);
}

function getAllIntegrationErrors(integrationId): any {
    const baseUrl = `${config.INTEGRATIONS_URL}/${integrationId}/integration_errors`;
    return api.get(`${baseUrl}`, {});
}

function getAllImportTemplates(type): any {
    const baseUrl = `${config.INTEGRATIONS_URL}/templates`;
    return api.get(`${baseUrl}`, {
        type,
    });
}

function createImportTemplate(template, type): any {
    const baseUrl = `${config.INTEGRATIONS_URL}/templates`;
    return api.create(`${baseUrl}`, template, { params: { type } });
}

function exportImportTemplate(templateId, type): any {
    const baseUrl = `${config.INTEGRATIONS_URL}/templates/${templateId}/export`;
    return api.get(`${baseUrl}`, {
        type,
    });
}

export {
    getCsvMapping,
    getAllIntegrations,
    createIntegration,
    integrationFileUpload ,
    getAllImportTemplates,
    createImportTemplate,
    exportImportTemplate,
    getAllIntegrationErrors,
};
