const analyticsRecordResponseToAnalyticsRecordObject = (response) => {
    return {
      id: response.record_id,
      userId: response.record_user_id,
      integrationId: response.integration_id,
      lineNumber: response.record_line_number,
      trackingNumber: response.record_tracking_number,
      externalOrderId: response.record_external_order_id,
      warehouseId: response.record_warehouse_id,
      invoiceNumber: response.record_invoice_number,
      ratingStatus: response.record_rating_status,
      fromAddressCompanyName: response.record_from_address_company_name,
      fromAddressFirstName: response.record_from_address_first_name,
      fromAddressMiddleName: response.record_from_address_middle_name,
      fromAddressLastName: response.record_from_address_last_name,
      fromAddressEmail: response.record_from_address_email,
      fromAddressPhone: response.record_from_address_phone,
      fromAddressStreet1: response.record_from_address_street1,
      fromAddressStreet2: response.record_from_address_street2,
      fromAddressStreet3: response.record_from_address_street3,
      fromAddressCityLocality: response.record_from_address_city_locality,
      fromAddressStateProvince: response.record_from_address_state_province,
      fromAddressPostalCode: response.record_from_address_postal_code,
      fromAddressZip5: response.record_from_address_zip5,
      fromAddressZip4: response.record_from_address_zip4,
      fromAddressCountryCode: response.record_from_address_country_code,
      fromAddressCarrierRouteCode: response.record_from_address_carrier_route_code,
      fromAddressResidentialIndicator: response.record_from_address_residential_indicator,
      fromAddressPoBoxIndicator: response.record_from_address_po_box_indicator,
      toAddressCompanyName: response.record_to_address_company_name,
      toAddressFirstName: response.record_to_address_first_name,
      toAddressMiddleName: response.record_to_address_middle_name,
      toAddressLastName: response.record_to_address_last_name,
      toAddressEmail: response.record_to_address_email,
      toAddressPhone: response.record_to_address_phone,
      toAddressStreet1: response.record_to_address_street1,
      toAddressStreet2: response.record_to_address_street2,
      toAddressStreet3: response.record_to_address_street3,
      toAddressCityLocality: response.record_to_address_city_locality,
      toAddressStateProvince: response.record_to_address_state_province,
      toAddressPostalCode: response.record_to_address_postal_code,
      toAddressZip5: response.record_to_address_zip5,
      toAddressZip4: response.record_to_address_zip4,
      toAddressCountryCode: response.record_to_address_country_code,
      toAddressCarrierRouteCode: response.record_to_address_carrier_route_code,
      toAddressResidentialIndicator: response.record_to_address_residential_indicator,
      toAddressPoBoxIndicator: response.record_to_address_po_box_indicator,
      shipmentDate: response.record_shipment_date,
      orderDate: response.record_order_date,
      totalOrderValue: response.record_total_order_value,
      packageType: response.record_package_type,
      confirmationType: response.record_confirmation_type,
      insuranceType: response.record_insurance_type,
      insuredValue: response.record_insured_value,
      insuredValueUnit: response.record_insured_value_unit,
      weight: response.record_weight,
      weightUnit: response.record_weight_unit,
      length: response.record_length,
      width: response.record_width,
      height: response.record_height,
      dimensionsUnit: response.record_dimensions_unit,
      recordCarrier: response.record_carrier,
      recordService: response.record_service,
      recordZoneRated: response.record_zone_rated,
      recordZone: response.record_zone,
      recordCubic: response.record_cubic,
      recordDimensional: response.record_dimensional,
      recordRatingDate: response.record_rating_date,
      recordRatingWeight: response.record_rating_weight,
      recordRatingWeightUnit: response.record_rating_weight_unit,
      recordRatingLength: response.record_rating_length,
      recordRatingWidth: response.record_rating_width,
      recordRatingHeight: response.record_rating_height,
      recordRatingDimensionsUnit: response.record_rating_dimensions_unit,
      recordBaseChargeAmount: response.record_base_charge_amount,
      recordTotalChargeAmount: response.record_total_charge_amount,
      rateCarrier: response.rate_carrier,
      rateService: response.rate_service,
      ratePriceType: response.rate_price_type,
      rateZoneRated: response.rate_zone_rated,
      rateZone: response.rate_zone,
      rateCubic: response.rate_cubic,
      rateDimensional: response.rate_dimensional,
      rateRatingDate: response.rate_rating_date,
      rateRatingWeight: response.rate_rating_weight,
      rateRatingWeightUnit: response.rate_rating_weight_unit,
      rateRatingLength: response.rate_rating_length,
      rateRatingWidth: response.rate_rating_width,
      rateRatingHeight: response.rate_rating_height,
      rateRatingDimensionsUnit: response.rate_rating_dimensions_unit,
      rateBaseChargeAmount: response.rate_base_charge_amount,
      rateTotalChargeAmount: response.rate_total_charge_amount,
    };
  };
  
  export { analyticsRecordResponseToAnalyticsRecordObject };