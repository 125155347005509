const TrackingEventResponseToTrackingEventObject = (trackingEventResponse) => {
    return {
        timestamp: trackingEventResponse.timestamp,
        status: trackingEventResponse.status,
        description: trackingEventResponse.description,
        carrierStatusCode: trackingEventResponse.carrier_status_code,
        carrierStatusDescription: trackingEventResponse.carrier_status_description,
        cityLocality: trackingEventResponse.city_locality,
        stateProvince: trackingEventResponse.state_province,
        postalCode: trackingEventResponse.postal_code,
        countryCode: trackingEventResponse.country_code,
    };
}

export {
    TrackingEventResponseToTrackingEventObject,
};