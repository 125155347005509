// @flow
import { BillingModalActionTypes } from './constants';

import { APICore } from '../../helpers/api/apiCore';

const INIT_STATE = {
    loading: false,
    creating: false,
    triggerModalClosing: false,
    paymentMethods: [],
    clientSecret: '',
};

type BillingModalAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { loading?: boolean, creating?: boolean, paymentMethods: [], clientSecret?: string };

const BillingModal = (state: State = INIT_STATE, action: BillingModalAction): any => {
    switch (action.type) {
        case BillingModalActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET:
                    return {
                        ...state,
                        loading: false,
                        clientSecret: action.payload.data,
                    };
                case BillingModalActionTypes.CREATE_PAYMENT_METHOD:
                    return {
                        ...state,
                        creating: false,
                        triggerModalClosing: true,
                    };
                default:
                    return { ...state };
            }

        case BillingModalActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload.error,
                    };
                case BillingModalActionTypes.CREATE_PAYMENT_METHOD:
                    return {
                        ...state,
                        creating: false,
                        error: action.payload.error,
                    };
                default:
                    return { ...state };
            }

        case BillingModalActionTypes.RESET_STATE:
            return { ...state, loading: false, creating: false, triggerModalClosing: false, clientSecret: '' };
        case BillingModalActionTypes.LOAD_NEW_PAYMENT_METHOD_SECRET:
            return { ...state, loading: true, clientSecret: '' };
        case BillingModalActionTypes.CREATE_PAYMENT_METHOD:
            return { ...state, creating: true };
        default:
            return { ...state };
    }
};

export default BillingModal;
