// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { PAGINATION_DEFAULT_VALUE } from '../defaultValue';

const api = new APICore();

type Query = {
    currentPage: number,
    pageSize: number,
    search: string,
};

function getAllManifests(returnImage: Boolean, fullResponse: Boolean, query: Query): any {
    const page = query.currentPage || PAGINATION_DEFAULT_VALUE.currentPage;
    const page_size = query.pageSize || PAGINATION_DEFAULT_VALUE.pageSize;
    const search = query.search || '';

    const baseUrl = config.MANIFESTS_URL;

    const queryParam = {
        page: page.toString(),
        page_size: page_size.toString(),
        search,
        carrier: 'usps',
    };

    //  Not send search query if it equal empty string
    if (search === '') {
        delete queryParam.search;
    }

    return api.get(`${baseUrl}`, queryParam, {
        headers: { 'return-image': returnImage, 'full-response': fullResponse },
    });
}

function createManifest(manifestRequest: any, returnImage: Boolean, fullResponse: Boolean): any {
    const baseUrl = config.MANIFESTS_URL;
    return api.create(
        `${baseUrl}`,
        { ...manifestRequest, carrier: 'usps' },
        {
            headers: { 'return-image': returnImage, 'full-response': fullResponse },
        }
    );
}

function getManifest(manifestId: string, returnImage: Boolean, fullResponse: Boolean): any {
    const baseUrl = config.MANIFESTS_URL;
    return api.get(
        `${baseUrl}/${manifestId}`,
        {
            carrier: 'usps',
        },
        { headers: { 'return-image': returnImage, 'full-response': fullResponse } }
    );
}

export { getAllManifests, createManifest, getManifest };
