// @flow
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, Form, Modal, Row } from 'react-bootstrap';
import Select from 'react-select';
import { Controller } from 'react-hook-form';

import PageTitle from '../../components/PageTitle';
import labelAndDocsData from './data.json';
import { FormInput } from '../../components';
import { useDispatch, useSelector } from 'react-redux';
import {
    labelsDocsSettingsGetLabelsDocsSettings,
    labelsDocsSettingsUpdateLabelsDocsSettings,
} from '../../redux/labels-docs-settings/actions';
import Spinner from '../../components/Spinner';

export type LabelAndDocsType = {
    type: string,
    size: {
        width: number,
        height: number,
        unit: string,
    },
    fileFormat: {
        label: string,
        value: string,
    },
    printOption: string,
    localstorageKey: string,
};

export type LabelsValue = {
    printOptions: {
        label: string,
        value: string,
    },
    sizes: string,
    fileFormats: string,
    labelsMessages1: string,
    labelsMessages2: string,
    labelsMessages3: string,
};

export type LabelsOptionsType = {
    printOptions: Array<{
        label: string,
        value: string,
    }>,
    sizes: Array<{
        label: string,
        value: {
            width: number,
            height: number,
            unit: string,
        },
    }>,
    fileFormats: Array<{
        label: string,
        value: string,
    }>,
    labelsMessages: Array<{
        label: string,
        value: string,
    }>,
};

type LabelAndDocModalType = {
    show: boolean,
    labelsOptions: LabelsOptionsType,
    defaultValue?: LabelsValue,
    showLabelMessage?: boolean,
    title: string,
    handleClose: (show: boolean) => void,
    onSubmit(data: LabelsValue, e: Event): void,
};

export const LabelAndDocModal = (props: LabelAndDocModalType): React$Element<any> => {
    const { show, handleClose, title, showLabelMessage = true, labelsOptions, onSubmit, defaultValue = {} } = props;

    const { handleSubmit, control, reset } = useForm({
        defaultValue,
        mode: 'onChange',
    });

    useEffect(() => {
        reset(defaultValue);
    }, [defaultValue, reset]);
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Modal.Header closeButton className="py-1">
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row className="gap-3 d-flex flex-column">
                            <div>
                                <Controller
                                    control={control}
                                    name="settingsAction"
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <div className="relative">
                                            <Select
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                options={labelsOptions.printOptions}
                                                value={labelsOptions.printOptions.find((e) => e.value === value)}
                                                onChange={(e) => onChange(e.value)}
                                            />
                                        </div>
                                    )}
                                />
                            </div>

                            <div>
                                <Form.Label>Size</Form.Label>
                                {labelsOptions.sizes.map((item, index) => (
                                    <Controller
                                        key={index}
                                        control={control}
                                        name="imageSize"
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="relative">
                                                <Form.Check
                                                    key={index}
                                                    className="mb-1"
                                                    type="radio"
                                                    name="imageSize"
                                                    id={`imageSize-${index}`}
                                                    label={item.label}
                                                    checked={value === item.value}
                                                    onChange={() => onChange(item.value)}
                                                />
                                            </div>
                                        )}
                                    />
                                ))}
                            </div>

                            <div>
                                <Form.Label>File Format</Form.Label>
                                {labelsOptions.fileFormats.map((item, index) => (
                                    <Controller
                                        key={index}
                                        control={control}
                                        name="imageType"
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="relative">
                                                <Form.Check
                                                    key={index}
                                                    className="mb-1"
                                                    type="radio"
                                                    name="imageType"
                                                    id={`imageType-${index}`}
                                                    label={item.label}
                                                    checked={value === item.value}
                                                    onChange={() => onChange(item.value)}
                                                />
                                            </div>
                                        )}
                                    />
                                ))}
                            </div>

                            {showLabelMessage && (
                                <div>
                                    {labelsOptions.labelsMessages.map((item, index) => (
                                        <Controller
                                            key={index}
                                            control={control}
                                            name={`message${index + 1}`}
                                            rules={{ required: true }}
                                            render={({ field: { onChange, value } }) => (
                                                <div className="relative">
                                                    <FormInput
                                                        className="mb-2"
                                                        key={index}
                                                        type="text"
                                                        name={`message${index + 1}`}
                                                        id={`message${index + 1}`}
                                                        label={item.label}
                                                        value={value || ""}
                                                        onChange={(e) => onChange(e.target.value)}
                                                    />
                                                </div>
                                            )}
                                        />
                                    ))}
                                </div>
                            )}
                        </Row>
                    </Modal.Body>

                    <Modal.Footer style={{ borderTop: 'none' }}>
                        <Button variant="secondary" className="btn btn-light" onClick={handleClose}>
                            Close
                        </Button>

                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
};

export const labelSettingsType = {
    labels: 'Labels',
    manifests: 'Manifests',
    return_labels: 'Return Labels',
    packing_slips: 'Packing Slips',
    pick_lists: 'Pick Lists',
};

export const labelSettingsActionType = {
    open_browser_print_review: 'Open browser print preview',
    download_file: 'Download file',
    send_to_printer: 'Send to printer',
};

export const imageSizeType = {
    FourBySix: '4 x 6 in',
    EightFiftyByEleven: '8.5 x 11 in',
};

function customLabelSettingsSort(a, b) {
    const order = ['labels', 'manifests', 'return_labels', 'packing_slips', 'pick_lists'];
    return order.indexOf(a.settingsType) - order.indexOf(b.settingsType);
}

const CardLabel = ({ item }) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);

    const onSubmit = (data, event) => {
        event?.preventDefault();
        dispatch(labelsDocsSettingsUpdateLabelsDocsSettings(item.id, data));
        setShowModal(false);
    };

    return (
        <>
            <div className="col-12 col-md-6 col-lg-4">
                <Card>
                    <Card.Body className="pt-1">
                        <Card.Title>{labelSettingsType[item.settingsType]}</Card.Title>

                        <div className="d-flex align-items-end justify-content-between">
                            <div className="mb-0">
                                <p className="mb-1">{imageSizeType[item?.imageSize]}</p>
                                <p className="mb-1">{item?.imageType.toUpperCase()}</p>
                                <p className="m-0" style={{ minHeight: '22px' }}>
                                    {labelSettingsActionType[item?.settingsAction]}
                                </p>
                            </div>

                            <p
                                className="m-0 cursor-pointer text-primary"
                                onClick={() => {
                                    setShowModal(true);
                                }}>
                                Edit
                            </p>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <LabelAndDocModal
                title={`Edit ${labelSettingsType[item.settingsType]} Settings`}
                defaultValue={item}
                onSubmit={onSubmit}
                show={showModal}
                handleClose={() => setShowModal(false)}
                labelsOptions={labelAndDocsData.editItem}
                showLabelMessage={!['manifests', 'packing_slips', 'pick_lists'].includes(item.settingsType)}
            />
        </>
    );
};

const LabelAndDocs = (): React$Element<any> => {
    const { labelSettings, labelSettingsLoading } = useSelector((state) => ({
        labelSettings: state.LabelAndDocsSettings.labelAndDocsData,
        labelSettingsLoading: state.LabelAndDocsSettings.loadingLabelAndDocsData,
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(labelsDocsSettingsGetLabelsDocsSettings());
    }, [dispatch]);

    const sortedLabelSettings = useMemo(() => {
        return (labelSettings || [])?.sort(customLabelSettingsSort);
    }, [labelSettings]);

    return (
        <>
            <PageTitle breadCrumbItems={[]} title={'Labels and Document Settings'} />

            <Row>
                {labelSettingsLoading ? (
                    <Spinner />
                ) : (
                    (sortedLabelSettings || []).map((item, index) => <CardLabel key={index} item={item} />)
                )}
            </Row>
        </>
    );
};

export default LabelAndDocs;
