import jwtDecode from 'jwt-decode';
import axios from 'axios';

import config from '../../config';
import { isEmpty } from '../utils';
import secureLocalStorage from 'react-secure-storage';

// content type
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';
// axios.defaults.headers.delete['Content-Type'] = 'application/json';

axios.defaults.baseURL = config.API_URL;

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        let errorArray = [];

        if (error && error.response) {
            const { status, data } = error.response;

            switch (status) {
                case 400: // Bad Request
                case 422: // Unprocessable Entity
                case 500: // Internal Server Error
                case 503: // Service Unavailable
                    if (data.errors && data.errors.length > 0) {
                        data.errors.forEach((err) => {
                            errorArray.push({ type: err.type, message: err.message });
                        });
                    }
                    break;
                case 404: // Not Found
                    errorArray.push({ type: 'system', message: 'The requested endpoint is not valid' });
                    break;
                default:
                    errorArray.push({
                        type: 'unknown',
                        message: data.message || error.message || 'An unexpected error occurred',
                    });
            }
        } else if (error && error.message) {
            // If the server didn't respond or wasn't reachable, etc
            errorArray.push({ type: 'system', message: error.message });
        } else {
            // Something else happened while setting up the request
            errorArray.push({ type: 'system', message: 'An unexpected error occurred' });
        }

        return Promise.reject({ errors: errorArray });
    }
);
const AUTH_DATA_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

export const getUserFromLocalStorage = () => {
    const user = secureLocalStorage.getItem(AUTH_DATA_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
class APICore {
    // Singleton instance
    static instance = null;
    constructor() {
        if (APICore.instance) {
            return APICore.instance;
        }
        APICore.instance = this;
    }

    /**
     * Fetches data from given url
     */
    get = (url, params, config) => {
        let response;
        if (params && !isEmpty(params)) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, config);
        } else {
            response = axios.get(`${url}`, config);
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            var queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
            response = axios.get(`${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                      .map((key) => key + '=' + params[key])
                      .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = (url, data, config) => {
        return axios.post(url, data, config);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data, config) => {
        return axios.patch(url, data, config);
    };

    /**
     * Updates data
     */
    update = (url, data, config) => {
        return axios.put(url, data, config);
    };

    /**
     * Deletes data
     */
    delete = (url, body, config) => {
        return axios.delete(url, { data: body, ...config });
    };

    /**
     * post given data to url with file
     */
    createWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.post(url, formData, config);
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(url, formData, config);
    };

    isUserAuthenticated = () => {
        const user = this.getLoggedInUser();
        if (!user || (user && !user.token)) {
            return false;
        }
        const decoded = jwtDecode(user.token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        } else {
            return true;
        }
    };

    setLoggedInUser = (data) => {
        if (data) secureLocalStorage.setItem(AUTH_DATA_KEY, data);
        else {
            secureLocalStorage.removeItem(AUTH_DATA_KEY);
        }
    };

    /**
     * Returns the logged in user
     */
    getLoggedInUser = () => {
        return getUserFromLocalStorage();
    };

    setUserInData = (modifiedUser) => {
        let userInfo = secureLocalStorage.getItem(AUTH_DATA_KEY);
        if (userInfo) {
            const { token, user } = JSON.parse(userInfo);
            this.setLoggedInUser({ token, ...user, ...modifiedUser });
        }
    };
}

/*
Check if token available in Data
*/
let user = getUserFromLocalStorage();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export { APICore, setAuthorization };
