import { AnalyticsRatesActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loading: false,
    error: null,
    analyticsRates: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type AnalyticsRatesAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    error?: null,
    analyticsRates: any
};

const AnalyticsRates = (state: State = INIT_STATE, action: AnalyticsRatesAction): any => {
    //console.log(action);
    switch (action.type) {
        case AnalyticsRatesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES: {
                    return {
                        ...state,
                        loading: false,
                        analyticsRates: action.payload.data.analyticsRates,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                default:
                    return { ...state, loading: false };
            }

        case AnalyticsRatesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES: {
                    return { ...state, loading: false, error: action.payload.error };
                }
                default:
                    return { ...state, loading: false };
            }

        case AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES:
            return { ...state, loading: true, analyticsRates: null };
        case AnalyticsRatesActionTypes.RESET_STATE:
            return INIT_STATE;
        case AnalyticsRatesActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default AnalyticsRates;
