import { BatchesActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loading: false,
    batches: [],
    orderBatches: [],
    error: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type BatchesAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    orderBatches: [any],
    batches: [any],
    error: any,
    pagination: any,
};

const Batches = (state: State = INIT_STATE, action: BatchesAction): any => {
    switch (action.type) {
        case BatchesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BatchesActionTypes.GET_ALL_BATCHES: {
                    return {
                        ...state,
                        loading: false,
                        batches: action.payload.data.batches,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                case BatchesActionTypes.GET_ALL_ORDERS_BATCHES: {
                    return { ...state, loading: false, orderBatches: action.payload.data };
                }

                default:
                    return { ...state };
            }

        case BatchesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return { ...state, loading: false, error: action.payload.error };
            }

        case BatchesActionTypes.GET_ALL_INVITATION:
            return { ...state, loading: true, batches: null };

        case BatchesActionTypes.GET_ALL_ORDERS_BATCHES: {
            return { ...state, loading: true, orderBatches: null };
        }

        case BatchesActionTypes.GET_ALL_BATCHES: {
            return {
                ...state,
                loading: true,
                batches: [],
            };
        }

        default:
            return { ...state };
    }
};

export default Batches;
