// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllIntegrationErrors as getAllIntegrationErrorsApi,
} from '../../helpers/';

import { integrationErrorsApiResponseSuccess, integrationErrorsApiResponseError } from './actions';
import { IntegrationErrorsActionTypes } from './constants';
import { integrationErrorResponseToIntegrationErrorObject } from '../../helpers/mappers/integrationError';
import { addToast } from '../actions';

function* getAllIntegrationErrors({ payload: { integrationId } }) {
    try {
        const getAllIntegrationErrorsResponse = yield call(getAllIntegrationErrorsApi, integrationId);
        const integrationErrors = getAllIntegrationErrorsResponse.data.map((integrationErrorResponse) =>
            integrationErrorResponseToIntegrationErrorObject(integrationErrorResponse)
        );
        yield put(integrationErrorsApiResponseSuccess(IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS, integrationErrors));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(integrationErrorsApiResponseError(IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS, error));
    }
}

export function* watchGetAllIntegrationErrors(): any {
    yield takeLatest(IntegrationErrorsActionTypes.GET_ALL_INTEGRATION_ERRORS, getAllIntegrationErrors);
}

function* getAllIntegrationErrorsSaga(): any {
    yield all([fork(watchGetAllIntegrationErrors)]);
}

export default getAllIntegrationErrorsSaga;
