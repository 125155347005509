// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    integrationFileUpload as integrationFileUploadApi,
    getCsvMapping as getCsvMappingApi,
    getAllIntegrations as getAllIntegrationsApi,
    createIntegration as createIntegrationApi,
    getAllImportTemplates as getAllImportTemplatesApi,
    createImportTemplate as createImportTemplateApi,
    exportImportTemplate,
    getAllIntegrationsApiKey,
    createIntegrationsApiKey,
    updateIntegrationsApiKey
} from '../../helpers/';

import { integrationsApiResponseSuccess, integrationsApiResponseError, getIntegrationApi } from './actions';
import { IntegrationsActionTypes } from './constants';
import { integrationResponseToIntegrationObject } from '../../helpers/mappers/integration';
import { addToast } from '../actions';
import { integrationApiObjectToIntegrationApiRequest } from '../../helpers/mappers/integrationApi';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function* createCsvIntegrationWithContent({payload: { integration, fileFormData, callback, savedTemplate }}) {
    try {
        if (savedTemplate) {
            yield call(createImportTemplateApi, savedTemplate, 'csv');
        }
        const createIntegrationResponse = yield call(createIntegrationApi, integration);
        const integrationId = createIntegrationResponse.data.id;
        const integrationFileUploadResponse = yield call(integrationFileUploadApi, integrationId, fileFormData);
        yield put(integrationsApiResponseSuccess(IntegrationsActionTypes.CREATE_CSV_INTEGRATION, integrationFileUploadResponse.data));
        yield put(addToast({ desc: 'Import successful', type: 'success' }));
        callback?.();
    } catch (error) {
        callback?.();
        yield put(addToast({ desc: 'Something went wrong', type: 'error' }));
        yield put(integrationsApiResponseError(IntegrationsActionTypes.CREATE_CSV_INTEGRATION, error)
        );
    }
}

function* getCsvMapping({ payload: { file, destinationFields } }) {
    try {
        const response = yield call(getCsvMappingApi, file, destinationFields);
        yield put(integrationsApiResponseSuccess(IntegrationsActionTypes.GET_CSV_MAPPING, response.data));
    } catch (error) {
        yield put(integrationsApiResponseError(IntegrationsActionTypes.GET_CSV_MAPPING, error));
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
    }
}

function* getAllIntegrations({ payload: { query } }) {
    try {
        const getAllIntegrationsResponse = yield call(getAllIntegrationsApi, {});
        const integrations = getAllIntegrationsResponse.data.map((integrationResponse) => integrationResponseToIntegrationObject(integrationResponse));
        const pagination = { ...paginationHeadersResponse(getAllIntegrationsResponse.headers), ...query };
        yield put(integrationsApiResponseSuccess(IntegrationsActionTypes.GET_ALL_INTEGRATIONS, { integrations, pagination })
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(integrationsApiResponseError(IntegrationsActionTypes.GET_ALL_INTEGRATIONS, error));
    }
}

function* getAllTemplates({ payload: { type } }) {
    try {
        const response = yield call(getAllImportTemplatesApi, type);
        yield put(integrationsApiResponseSuccess(IntegrationsActionTypes.GET_ALL_TEMPLATES, response.data));
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
        yield put(integrationsApiResponseError(IntegrationsActionTypes.GET_ALL_TEMPLATES, error));
    }
}

function* createTemplate({ payload: { type, template } }) {
    try {
        const response = yield call(createImportTemplateApi, template, type);
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
    }
}

function* exportTemplate({ payload: { type, templateId, callback } }) {
    try {
        const response = yield call(exportImportTemplate, templateId, type);
        callback(response.data);
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
    }
}

function* getIntegrationAPIKey() {
    try {
        const response = yield call(getAllIntegrationsApiKey);
        yield put(
            integrationsApiResponseSuccess(
                IntegrationsActionTypes.GET_INTEGRATION_APIS,
                response.data?.map(integrationApiObjectToIntegrationApiRequest)
            )
        );
    } catch (error) {
        yield put(integrationsApiResponseError(IntegrationsActionTypes.GET_INTEGRATION_APIS, error));
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
    }
}

function* createIntegrationAPIKey() {
    try {
        const response = yield call(createIntegrationsApiKey);
        yield put(getIntegrationApi());
        yield put(integrationsApiResponseSuccess(IntegrationsActionTypes.CREATE_INTEGRATION_API));
        yield addToast({
            desc: 'New API token has been successfully created.',
            type: 'success',
        });
    } catch (error) {
        yield put(integrationsApiResponseError(IntegrationsActionTypes.CREATE_INTEGRATION_API));
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
    }
}

function* updateIntegrationAPIKey({ payload: { id, callback } }) {
    try {
        const response = yield call(updateIntegrationsApiKey, id);
        yield put(getIntegrationApi());
        yield put(integrationsApiResponseSuccess(IntegrationsActionTypes.UPDATE_INTEGRATION_API));
    } catch (error) {
        yield put(integrationsApiResponseError(IntegrationsActionTypes.UPDATE_INTEGRATION_API));
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
    } finally {
        callback();
    }
}

export function* watchCreateCsvIntegrationWithContent(): any {
    yield takeLatest(IntegrationsActionTypes.CREATE_CSV_INTEGRATION, createCsvIntegrationWithContent);
}

export function* watchGetCsvMapping(): any {
    yield takeLatest(IntegrationsActionTypes.GET_CSV_MAPPING, getCsvMapping);
}

export function* watchGetAllIntegrations(): any {
    yield takeLatest(IntegrationsActionTypes.GET_ALL_INTEGRATIONS, getAllIntegrations);
}

export function* watchGetAllTemplates(): any {
    yield takeLatest(IntegrationsActionTypes.GET_ALL_TEMPLATES, getAllTemplates);
}

export function* watchCreateTemplate(): any {
    yield takeLatest(IntegrationsActionTypes.CREATE_TEMPLATE, createTemplate);
}

export function* watchExportTemplate(): any {
    yield takeLatest(IntegrationsActionTypes.EXPORT_TEMPLATE, exportTemplate);
}

export function* watchGetIntegrationApi(): any {
    yield takeLatest(IntegrationsActionTypes.GET_INTEGRATION_APIS, getIntegrationAPIKey);
}

export function* watchCreateIntegrationApi(): any {
    yield takeLatest(IntegrationsActionTypes.CREATE_INTEGRATION_API, createIntegrationAPIKey);
}

export function* watchUpdateIntegrationApi(): any {
    yield takeLatest(IntegrationsActionTypes.UPDATE_INTEGRATION_API, updateIntegrationAPIKey);
}

function* integrationsSaga(): any {
    yield all([
        fork(watchCreateCsvIntegrationWithContent),
        fork(watchGetCsvMapping),
        fork(watchGetAllIntegrations),
        fork(watchGetAllTemplates),
        fork(watchCreateTemplate),
        fork(watchExportTemplate),
        fork(watchGetIntegrationApi),
        fork(watchCreateIntegrationApi),
        fork(watchUpdateIntegrationApi)
    ]);
}

export default integrationsSaga;
