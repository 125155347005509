// @flow
import { all, fork, put, takeLatest, call, select } from 'redux-saga/effects';

import {
    addOrdersToBatches,
    createBatches,
    downloadLabelFromBatch,
    getAllBatches,
    getSingleBatches,
    moveOrdersToBatches,
    removeOrdersToBatches,
    triggerBatch,
    updateBatches,
    updatePatchBatch
} from '../../helpers/';
import { batchesApiResponseError, batchesApiResponseSuccess } from './actions';
import { BatchesActionTypes } from './constants';
import { addToast, batchesGetAllBatches as batchesGetAllBatchesAction, ordersGetAllOrders } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function groupOrdersByBatch(orders) {
    return orders.reduce((groupedOrders, order) => {
        const batchId = order.batch?.id ?? 'NO_BATCH';
        groupedOrders[batchId] = (groupedOrders[batchId] || []).concat(order);
        return groupedOrders;
    }, {});
}

function* batchesGetAllBatches({ payload: { query } }) {
    try {
        const response = yield call(getAllBatches, query);
        const batches = response.data; //TODO: mapping
        const pagination = { ...paginationHeadersResponse(response.headers), ...query };
        yield put(batchesApiResponseSuccess(BatchesActionTypes.GET_ALL_BATCHES, { batches, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0]?.message, type: 'error' }));
        yield put(batchesApiResponseError(BatchesActionTypes.GET_ALL_BATCHES, error));
    }
}

function* batchesGetSingleBatches() {
    try {
        const response = yield call(getSingleBatches);
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

function* batchesCreateBatches({ payload: { request, callback } }) {
    try {
        const { isProcessBatch, ...data } = request;

        const { orderPagination } = yield select((state) => ({
            orderPagination: state.Orders.pagination,
        }));

        const response = yield call(createBatches, data);

        if (isProcessBatch) {
            yield call(triggerBatch, response?.data?.id);
            yield put(addToast({ desc: 'Batch has been created and labels have been purchased successfully.' }));
        } else {
            yield put(addToast({ desc: 'Batch has been successfully saved.' }));
        }

        yield put(batchesGetAllBatchesAction());
        yield put(
            ordersGetAllOrders(false, true, {
                page: orderPagination.currentPage,
                pageSize: orderPagination.pageSize,
            })
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesUpdatePatchBatch({ payload: { request, callback } }) {
    try {
        console.log('request', request);
        console.log('callback', callback);
        const { id, ...data } = request;
        const response = yield call(updatePatchBatch, id, data);
        yield put(addToast({ desc: 'Batch has been successfully saved.' }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesUpdateBatches({ payload: { request, callback } }) {
    try {
        const { id, ...data } = request;
        const response = yield call(updateBatches, id, data);
        yield put(addToast({ desc: 'Batch has been successfully saved.' }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesAddOrdersToBatches({ payload: { request, callback } }) {
    try {
        const { id, ...data } = request;

        const { orderPagination } = yield select((state) => ({
            orderPagination: state.Orders.pagination,
        }));
        const response = yield call(addOrdersToBatches, id, data);

        yield put(
            addToast({ desc: `${data?.orders.length ?? 0} orders successfully added to a batch`, type: 'success' })
        );

        yield put(batchesGetAllBatchesAction());
        yield put(
            ordersGetAllOrders(false, true, {
                page: orderPagination.currentPage,
                pageSize: orderPagination.pageSize,
            })
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesMoveOrdersToBatches({ payload: { request, callback } }) {
    try {
        const { id, orders } = request;

        const { orderPagination } = yield select((state) => ({
            orderPagination: state.Orders.pagination,
        }));

        for (const [key, value] of Object.entries(groupOrdersByBatch(orders))) {
            const orderIds = value.map((e) => e.id);

            if (key === 'NO_BATCH') {
                // If the orders is not belonging to a specific batch then add them to the batch.
                yield call(addOrdersToBatches, id, { orders: orderIds });
            } else {
                yield call(moveOrdersToBatches, id, {
                    current_batch_id: key,
                    orders: orderIds,
                });
            }
        }

        yield put(
            addToast({ desc: `${orders.length ?? 0} orders successfully moved to another batch.`, type: 'success' })
        );
        yield put(batchesGetAllBatchesAction());
        yield put(
            ordersGetAllOrders(false, true, {
                page: orderPagination.currentPage,
                pageSize: orderPagination.pageSize,
            })
        );
    } catch (error) {
        yield put(addToast({ desc: error?.errors?.[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesRemoveOrdersFromBatches({ payload: { request, callback } }) {
    try {
        const { orders } = request;

        const { orderPagination } = yield select((state) => ({
            orderPagination: state.Orders.pagination,
        }));

        for (const [key, value] of Object.entries(groupOrdersByBatch(orders))) {
            const orderIds = value.map((e) => e.id);

            if (key !== 'NO_BATCH') {
                yield call(removeOrdersToBatches, key, { orders: orderIds });
            }
        }

        yield put(
            addToast({ desc: `${orders.length ?? 0} orders successfully removed from a batch.`, type: 'success' })
        );

        yield put(batchesGetAllBatchesAction());
        yield put(
            ordersGetAllOrders(false, true, {
                page: orderPagination.currentPage,
                pageSize: orderPagination.pageSize,
            })
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesTriggerBatch({ payload: { ids, callback } }) {
    try {
        const { orderPagination } = yield select((state) => ({
            orderPagination: state.Orders.pagination,
        }));

        if (typeof ids === 'string') {
            const response = yield call(triggerBatch, ids);
        } else {
            yield ids.map((e) => call(triggerBatch, e));
        }

        yield put(batchesGetAllBatchesAction());
        yield put(
            ordersGetAllOrders(false, true, {
                page: orderPagination.currentPage,
                pageSize: orderPagination.pageSize,
            })
        );
    } catch (error) {
        console.log('error', error);
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback?.();
    }
}

function* batchesDownloadLabelFromBatch({ payload: { id, callback } }) {
    try {
        const response = yield call(downloadLabelFromBatch, id);

        callback?.(response.data);
    } catch (error) {
        console.log('error', error);
        yield put(addToast({ desc: error.errors?.[0].message, type: 'error' }));
    }
}

export function* watchGetAllBatches(): any {
    yield takeLatest(BatchesActionTypes.GET_ALL_BATCHES, batchesGetAllBatches);
}

export function* watchGetSingleBatches(): any {
    yield takeLatest(BatchesActionTypes.GET_SINGLE_BATCHES, batchesGetSingleBatches);
}

export function* watchCreateBatches(): any {
    yield takeLatest(BatchesActionTypes.CREATE_BATCHES, batchesCreateBatches);
}

export function* watchUpdateBatches(): any {
    yield takeLatest(BatchesActionTypes.UPDATE_BATCHES, batchesUpdateBatches);
}

export function* watchUpdatePatchBatch(): any {
    yield takeLatest(BatchesActionTypes.UPDATE_PATCH_BATCH, batchesUpdatePatchBatch);
}

export function* watchAddOrdersToBatches(): any {
    yield takeLatest(BatchesActionTypes.ADD_ORDERS_TO_BATCHES, batchesAddOrdersToBatches);
}

export function* watchMoveOrdersToBatches(): any {
    yield takeLatest(BatchesActionTypes.MOVE_ORDERS_TO_BATCHES, batchesMoveOrdersToBatches);
}

export function* watchRemoveOrdersFromBatches(): any {
    yield takeLatest(BatchesActionTypes.REMOVE_ORDERS_FROM_BATCHES, batchesRemoveOrdersFromBatches);
}

export function* watchTriggerBatch(): any {
    yield takeLatest(BatchesActionTypes.TRIGGER_BATCH, batchesTriggerBatch);
}

export function* watchDownloadLabelFromBatch(): any {
    yield takeLatest(BatchesActionTypes.DOWNLOAD_LABEL_FROM_BATCH, batchesDownloadLabelFromBatch);
}

function* batchesSaga(): any {
    yield all([
        fork(watchGetAllBatches),
        fork(watchGetSingleBatches),
        fork(watchCreateBatches),
        fork(watchUpdateBatches),
        fork(watchUpdatePatchBatch),
        fork(watchAddOrdersToBatches),
        fork(watchMoveOrdersToBatches),
        fork(watchRemoveOrdersFromBatches),
        fork(watchTriggerBatch),
        fork(watchDownloadLabelFromBatch),
    ]);
}

export default batchesSaga;
