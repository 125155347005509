const integrationApiResponseToIntegrationApiObject = (response) => {
    return {
        api_key: response.apiKey,
        created_at: response.createdAt,
        id: response.id,
        user_id: response.userId,
        status: response.status,
    };
};

const integrationApiObjectToIntegrationApiRequest = (response) => {
    return {
        apiKey: response.api_key,
        createdAt: response.created_at,
        id: response.id,
        userId: response.user_id,
        status: response.status,
    };
};

export { integrationApiObjectToIntegrationApiRequest, integrationApiResponseToIntegrationApiObject };
