export const AutomationActionTypes = {
    API_RESPONSE_SUCCESS: '@@automation/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@automation/API_RESPONSE_ERROR',
    GET_AUTOMATION: '@@automation/GET_AUTOMATION',
    UPDATE_AUTOMATION: '@@automation/UPDATE_AUTOMATION',
    PARTIAL_UPDATE_AUTOMATION: '@@automation/PARTIAL_UPDATE_AUTOMATION',
    APPLY_RULES: '@@automation/APPLY_RULES',
    CREATE_AUTOMATION: '@@automation/CREATE_AUTOMATION',
    UPDATE_POSITION_AUTOMATION: '@@automation/UPDATE_POSITION_AUTOMATION',
};
