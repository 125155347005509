import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import * as layoutConstants from '../constants/layout';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import DetachedLayout from '../layouts/Detached';
import HorizontalLayout from '../layouts/Horizontal';
import FullLayout from '../layouts/Full';
import LabelAndDocs from '../pages/LabelAndDocs';

// lazy load all the views

const Addresses = React.lazy(() => import('../pages/addresses'));

// auth
const Login = React.lazy(() => import('../pages/account/Login'));
const Logout = React.lazy(() => import('../pages/account/Logout'));
const Register = React.lazy(() => import('../pages/account/Register'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const ResetPassword = React.lazy(() => import('../pages/reset-password/index'));
const LockScreen = React.lazy(() => import('../pages/account/LockScreen'));

const Login2 = React.lazy(() => import('../pages/account2/Login2'));
const Logout2 = React.lazy(() => import('../pages/account2/Logout2'));
const Register2 = React.lazy(() => import('../pages/account2/Register2'));
const Confirm2 = React.lazy(() => import('../pages/account2/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('../pages/account2/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('../pages/account2/LockScreen2'));

// pages
const Profile = React.lazy(() => import('../pages/profile'));
const Profile2 = React.lazy(() => import('../pages/profile2'));
const ProfileAccount = React.lazy(() => import('../pages/profile-account'));
const Billing = React.lazy(() => import('../pages/billing'));
const Plan = React.lazy(() => import('../pages/plan'));
const ViewPlans = React.lazy(() => import('../pages/plan/view-plans'));
const ViewInvoice = React.lazy(() => import('../pages/invoice'));
const Users = React.lazy(() => import('../pages/account2/users'));
const Batches = React.lazy(() => import('../pages/batches'));
const Returns = React.lazy(() => import('../pages/returns'));
const Reports = React.lazy(() => import('../pages/reports'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('../pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('../pages/error/ServerError'));
const CarrierAccounts = React.lazy(() => import('../pages/carrier'));

const Products = React.lazy(() => import('../pages/products'));
const NewProduct = React.lazy(() => import('../pages/products/new-product'));

// Analytics
const Analytics = React.lazy(() => import('../pages/analytics'));
const ShipmentServiceLevels = React.lazy(() => import('../pages/analytics/ShipmentServiceLevels'));
const ShipmentZone = React.lazy(() => import('../pages/analytics/ShipmentZone'));
const DeliveryTrackingStatus = React.lazy(() => import('../pages/analytics/DeliveryTrackingStatus'));
const DeliveryTransitTimes = React.lazy(() => import('../pages/analytics/DeliveryTransitTimes'));
const DeliveryPerformance = React.lazy(() => import('../pages/analytics/DeliveryPerformance'));
const RateSavings = React.lazy(() => import('../pages/analytics/RateSavings'));
const BillingWeight = React.lazy(() => import('../pages/analytics/BillingWeight'));
const BillingSurcharges = React.lazy(() => import('../pages/analytics/BillingSurcharges'));
const BillingAudit = React.lazy(() => import('../pages/analytics/BillingAudit'));
const BillingOptimization = React.lazy(() => import('../pages/analytics/BillingOptimization'));

// - other
const Invoice = React.lazy(() => import('../pages/other/Invoice'));
const FAQ = React.lazy(() => import('../pages/other/FAQ'));
const Pricing = React.lazy(() => import('../pages/other/Pricing'));
const Maintenance = React.lazy(() => import('../pages/other/Maintenance'));
const Starter = React.lazy(() => import('../pages/other/Starter'));
const PreLoader = React.lazy(() => import('../pages/other/PreLoader'));
const Timeline = React.lazy(() => import('../pages/other/Timeline'));

const Landing = React.lazy(() => import('../pages/landing'));

// shipments
const Shipments = React.lazy(() => import('../pages/shipments'));

// pickups
const PickupSchedule = React.lazy(() => import('../pages/pickup/PickupSchedule'));
// pickups
const ViewPickup = React.lazy(() => import('../pages/pickup/ViewPickup'));

// manifests
const Manifests = React.lazy(() => import('../pages/manifests'));

// Pickup
const Pickup = React.lazy(() => import('../pages/pickup'));

// manifest
const Manifest = React.lazy(() => import('../pages/manifest'));

// Pickups

// pickups-schedule.
// const pickupsSchedule = React.lazy(() => import('../pages/pickups-schedule'));

// orders
const Orders = React.lazy(() => import('../pages/orders'));
const EditOrder = React.lazy(() => import('../pages/orders/edit-order'));
const AddOrder = React.lazy(() => import('../pages/orders/new-order'));
const ViewOrder = React.lazy(() => import('../pages/orders/view-order'));

// location
const Location = React.lazy(() => import('../pages/location'));
const FindLocation = React.lazy(() => import('../pages/location/find-location'));
const ViewLocation = React.lazy(() => import('../pages/location/view-location'));

const StoreIntegrations = React.lazy(() => import('../pages/integrations/stores'));
const IntegrationApi = React.lazy(() => import('../pages/integrations/api'));
const TechnologyPartners = React.lazy(() => import('../pages/integrations/TechnologyPartners'));
const Webhooks = React.lazy(() => import('../pages/integrations/webhooks'));
const Imports = React.lazy(() => import('../pages/imports'));
const ImportManage = React.lazy(() => import('../pages/imports/import-manage'));
const ImportComparisonManage = React.lazy(() => import('../pages/imports/import-comparison-manage'));

// Settings
const Peripherals = React.lazy(() => import('../pages/settings/Peripherals/index'));
const Onboarding = React.lazy(() => import('../pages/onboarding'));
const ServiceLevels = React.lazy(() => import('../pages/settings/ServiceLevels'));
const Notifications = React.lazy(() => import('../pages/settings/Notifications'));
const Automation = React.lazy(() => import('../pages/automation'));
const AddAutomation = React.lazy(() => import('../pages/automation/AddAutomation'));
const Packages = React.lazy(() => import('../pages/packages'));
const RateSettings = React.lazy(() => import('../pages/rate-settings'));

// uikit
const Accordions = React.lazy(() => import('../pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('../pages/uikit/Alerts'));
const Avatars = React.lazy(() => import('../pages/uikit/Avatars'));
const Badges = React.lazy(() => import('../pages/uikit/Badges'));
const Breadcrumbs = React.lazy(() => import('../pages/uikit/Breadcrumb'));
const Buttons = React.lazy(() => import('../pages/uikit/Buttons'));
const Cards = React.lazy(() => import('../pages/uikit/Cards'));
const Carousels = React.lazy(() => import('../pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('../pages/uikit/Dropdowns'));
const EmbedVideo = React.lazy(() => import('../pages/uikit/EmbedVideo'));
const Grid = React.lazy(() => import('../pages/uikit/Grid'));
const ListGroups = React.lazy(() => import('../pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('../pages/uikit/Modals'));
const Offcanvases = React.lazy(() => import('../pages/uikit/Offcanvas'));
const Paginations = React.lazy(() => import('../pages/uikit/Paginations'));
const Popovers = React.lazy(() => import('../pages/uikit/Popovers'));
const Progress = React.lazy(() => import('../pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('../pages/uikit/Ribbons'));
const Spinners = React.lazy(() => import('../pages/uikit/Spinners'));
const Tabs = React.lazy(() => import('../pages/uikit/Tabs'));
const Tooltips = React.lazy(() => import('../pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('../pages/uikit/Typography'));
const DragDrop = React.lazy(() => import('../pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('../pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('../pages/uikit/Ratings'));

// icons
const Dripicons = React.lazy(() => import('../pages/icons/Dripicons'));
const MDIIcons = React.lazy(() => import('../pages/icons/MDIIcons'));
const Unicons = React.lazy(() => import('../pages/icons/Unicons'));

// forms
const BasicForms = React.lazy(() => import('../pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('../pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('../pages/forms/Validation'));
const FormWizard = React.lazy(() => import('../pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('../pages/forms/FileUpload'));
const Editors = React.lazy(() => import('../pages/forms/Editors'));

// charts
const ApexChart = React.lazy(() => import('../pages/charts/Apex'));
const BriteChart = React.lazy(() => import('../pages/charts/Brite'));
const ChartJs = React.lazy(() => import('../pages/charts/ChartJs'));

// tables
const BasicTables = React.lazy(() => import('../pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('../pages/tables/Advanced'));

// widgets
const Widgets = React.lazy(() => import('../pages/uikit/Widgets'));

// maps
const GoogleMaps = React.lazy(() => import('../pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('../pages/maps/VectorMaps'));

//

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>,
};

const LoadComponent = ({ component: Component, ...rest }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component {...rest} />
    </Suspense>
);

const AllRoutes = () => {
    const { layout } = useSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls = VerticalLayout;

        switch (layout.layoutType) {
            case layoutConstants.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case layoutConstants.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case layoutConstants.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'login2', element: <LoadComponent component={Login2} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                { path: 'reset-password', element: <LoadComponent component={ResetPassword} /> },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'orders',
                            element: <LoadComponent component={Orders} />,
                        },
                        {
                            path: 'analytics',
                            element: <LoadComponent component={Analytics} />,
                        },
                        {
                            path: 'edit-order',
                            element: <LoadComponent component={EditOrder} />,
                        },
                        {
                            path: 'new-order',
                            element: <LoadComponent component={AddOrder} />,
                        },
                        {
                            path: 'view-order',
                            element: <LoadComponent component={ViewOrder} />,
                        },
                        {
                            path: 'shipments',
                            element: <LoadComponent component={Shipments} />,
                        },
                        {
                            path: 'pickups-schedule',
                            element: <LoadComponent component={PickupSchedule} />,
                        },
                        {
                            path: 'view-pickup',
                            element: <LoadComponent component={ViewPickup} />,
                        },
                        {
                            path: 'manifests',
                            element: <LoadComponent component={Manifests} />,
                        },
                        {
                            path: 'pickups',
                            element: <LoadComponent component={Pickup} />,
                        },
                        {
                            path: 'addresses',
                            element: <LoadComponent component={Addresses} />,
                        },
                        {
                            path: 'location',
                            element: <LoadComponent component={Location} />,
                        },
                        {
                            path: 'find-location',
                            element: <LoadComponent component={FindLocation} />,
                        },
                        {
                            path: 'view-location',
                            element: <LoadComponent component={ViewLocation} />,
                        },
                        {
                            path: 'manifest',
                            element: <LoadComponent component={Manifest} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'account-profile',
                            element: <LoadComponent component={ProfileAccount} />,
                        },
                        {
                            path: 'billing',
                            element: <LoadComponent component={Billing} />,
                        },
                        {
                            path: 'plan',
                            element: <LoadComponent component={Plan} />,
                        },
                        {
                            path: 'view-plans',
                            element: <LoadComponent component={ViewPlans} />,
                        },
                        {
                            path: 'invoice/:invoiceId/',
                            element: <LoadComponent component={ViewInvoice} />,
                        },
                        {
                            path: 'users',
                            element: <LoadComponent component={Users} />,
                        },
                        {
                            path: 'batches',
                            element: <LoadComponent component={Batches} />,
                        },
                        {
                            path: 'returns',
                            element: <LoadComponent component={Returns} />,
                        },
                        {
                            path: 'reports',
                            element: <LoadComponent component={Reports} />,
                        },
                        {
                            path: 'products',
                            element: <LoadComponent component={Products} />,
                        },
                        {
                            path: 'new-product',
                            element: <LoadComponent component={NewProduct} />,
                        },
                    ],
                },
                {
                    path: 'imports',
                    children: [
                        {
                            path: '',
                            element: <LoadComponent component={Imports} />,
                        },
                        {
                            path: 'manage',
                            element: <LoadComponent component={ImportManage} />,
                        },
                        {
                            path: 'shipment-import-comparison',
                            element: <LoadComponent component={ImportComparisonManage} />,
                        },
                    ],
                },
                {
                    path: 'integrations',
                    children: [
                        {
                            path: 'ecommerce-technology',
                            element: <LoadComponent component={TechnologyPartners} />,
                        },
                        {
                            path: 'stores',
                            element: <LoadComponent component={StoreIntegrations} />,
                        },
                        // {
                        //     path: 'api',
                        //     element: <LoadComponent component={IntegrationApi} />,
                        // },
                        {
                            path: '/integrations/carrier-accounts',
                            element: <LoadComponent component={CarrierAccounts} />,
                        },
                        {
                            path: 'webhooks',
                            element: <LoadComponent component={Webhooks} />,
                        },
                    ],
                },
                {
                    path: 'settings',
                    children: [
                        {
                            path: 'peripherals',
                            element: <LoadComponent component={Peripherals} />,
                        },
                        {
                            path: 'label-and-docs',
                            element: <LoadComponent component={LabelAndDocs} />,
                        },
                        {
                            path: 'automation',
                            element: <LoadComponent component={Automation} />,
                        },
                        {
                            path: 'add-automation',
                            element: <LoadComponent component={AddAutomation} />,
                        },
                        {
                            path: 'onboarding',
                            element: <LoadComponent component={Onboarding} />,
                        },
                        {
                            path: 'service-levels',
                            element: <LoadComponent component={ServiceLevels} />,
                        },
                        {
                            path: 'notifications',
                            element: <LoadComponent component={Notifications} />,
                        },
                        {
                            path: 'packages',
                            element: <LoadComponent component={Packages} />,
                        },
                        {
                            path: 'rates',
                            element: <LoadComponent component={RateSettings} />,
                        },
                    ],
                },
                {
                    path: 'analytics',
                    children: [
                        {
                            path: 'shipment-service-levels',
                            element: <LoadComponent component={ShipmentServiceLevels} />,
                        },
                        {
                            path: 'shipment-zones',
                            element: <LoadComponent component={ShipmentZone} />,
                        },
                        {
                            path: 'delivery-tracking-status',
                            element: <LoadComponent component={DeliveryTrackingStatus} />,
                        },
                        {
                            path: 'delivery-transit-times',
                            element: <LoadComponent component={DeliveryTransitTimes} />,
                        },
                        {
                            path: 'delivery-performance',
                            element: <LoadComponent component={DeliveryPerformance} />,
                        },
                        {
                            path: 'rate-savings',
                            element: <LoadComponent component={RateSavings} />,
                        },
                        {
                            path: 'billing-weight',
                            element: <LoadComponent component={BillingWeight} />,
                        },
                        {
                            path: 'billing-surcharges',
                            element: <LoadComponent component={BillingSurcharges} />,
                        },
                        {
                            path: 'billing-audit',
                            element: <LoadComponent component={BillingAudit} />,
                        },
                        {
                            path: 'billing-optimization',
                            element: <LoadComponent component={BillingOptimization} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
