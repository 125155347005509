export const InvoiceAnalyticsActionTypes = {
    API_RESPONSE_SUCCESS: '@@invoice-analytics/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@invoice-analytics/API_RESPONSE_ERROR',
    GET_NO_SHIPMENTS_REQUEST: '@@invoice-analytics/GET_NO_SHIPMENTS_REQUEST',
    GET_TOP_ZONE_REQUEST: '@@ordeinvoicer-analytics/GET_TOP_ZONE_REQUEST',
    GET_TOP_WEIGHT_REQUEST: '@@invoice-analytics/GET_TOP_WEIGHT_REQUEST',
    GET_COST_REQUEST: '@@invoice-analytics/GET_COST_REQUEST',
    GET_AVG_COST_REQUEST: '@@invoice-analytics/GET_AVG_COST_REQUEST',
    GET_COMPARISON_ANALYTICS_RECORDS: '@@invoice-records/GET_COMPARISON_ANALYTICS_RECORDS',
    GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT: '@@invoice-records/GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT',
    GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT: '@@invoice-records/GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT',
};
