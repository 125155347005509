import { ProductActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

export const INIT_STATE = {
    loadingProducts: false,
    error: null,
    products: null,
    creatingProduct: false,
    createdProduct: null,
    deletingProduct: false,
    deletedProduct: false,
    updatingProduct: false,
    updatedProduct: null,
    initialProduct: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type ProductsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

//TODO: define data type for orders
type State = {
    loadingProducts: Boolean,
    error?: null,
    products: any,
    creatingProduct: Boolean,
    createdProduct: any,
    deletingProduct: Boolean,
    deletedProduct: Boolean,
    updatingProduct: Boolean,
    updatedProduct: any,
    initialProduct: any,
};

const Products = (state: State = INIT_STATE, action: ProductsAction): any => {
    switch (action.type) {
        case ProductActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProductActionTypes.READ_ALL_PRODUCTS_REQUEST: {
                    return { 
                        ...state, 
                        loadingProducts: false, 
                        products: action.payload.data.products,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                case ProductActionTypes.CREATE_PRODUCT_REQUEST: {
                    return { ...state, creatingProduct: false, createdProduct: action.payload.data };
                }
                case ProductActionTypes.DELETE_PRODUCT_REQUEST: {
                    return { ...state, deletingProduct: false, deletedProduct: true };
                }
                case ProductActionTypes.UPDATE_PRODUCT_REQUEST: {
                    return { ...state, updatingProduct: false, updatedProduct: action.payload.data };
                }
                default:
                    return { ...state, loadingProducts: false, loadingDefaultProduct: false };
            }

        case ProductActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProductActionTypes.READ_ALL_PRODUCTS_REQUEST: {
                    return { ...state, loadingProducts: false, error: action.payload.error };
                }
                case ProductActionTypes.CREATE_PRODUCT_REQUEST: {
                    return { ...state, creatingProduct: false, error: action.payload.error };
                }
                case ProductActionTypes.DELETE_PRODUCT_REQUEST: {
                    return { ...state, deletingProduct: false, error: action.payload.error };
                }
                case ProductActionTypes.UPDATE_PRODUCT_REQUEST: {
                    return { ...state, updatingProduct: false, error: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case ProductActionTypes.READ_ALL_PRODUCTS_REQUEST:
            return { ...state, loadingProducts: true, products: null };
        case ProductActionTypes.CREATE_PRODUCT_REQUEST:
            return { ...state, creatingProduct: true, createdProduct: null };
        case ProductActionTypes.DELETE_PRODUCT_REQUEST:
            return { ...state, deletingProduct: true, deletedProduct: false };
        case ProductActionTypes.UPDATE_PRODUCT_REQUEST:
            return { ...state, updatingProduct: true, updatedProduct: null };
        default:
            return { ...state };
    }
};

export default Products;
