// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { PAGINATION_DEFAULT_VALUE } from '../defaultValue';

const api = new APICore();

const BASE_URL = config.INVOICE_ANALYTICS_URL;

const buildQueryParams = (query: Query): Object => ({
    page: (query.currentPage - 1 || PAGINATION_DEFAULT_VALUE.currentPage - 1).toString(),
    page_size: (query.pageSize || PAGINATION_DEFAULT_VALUE.pageSize).toString(),
    ...(query.integrationId && { integrationId: query.integrationId }),
    ...(query.rateSetId && { rateSetId: query.rateSetId }),
    ...(query.comparisonRateType && { comparisonRateType: query.comparisonRateType }),
});

const makeApiCall = (endpoint: string, query: Query | string): Promise<any> => {
    if (typeof query === 'string') {
        return api.get(`${BASE_URL}${endpoint}?${query}`, {}, {});
    }
    const queryParams = buildQueryParams(query);
    return api.get(`${BASE_URL}${endpoint}`, queryParams, {});
};

function getInvoiceAnalyticsNoShipments(query): any {
    const endpoint = '/no_shipments';
    return makeApiCall(endpoint, query);
}

function getInvoiceAnalyticsTopZone(query): any {
    const endpoint = '/top_zone';
    return makeApiCall(endpoint, query);
}

function getInvoiceAnalyticsTopWeight(query): any {
    const endpoint = '/top_weight';
    return makeApiCall(endpoint, query);
}

function getInvoiceAnalyticsCost(query): any {
    const endpoint = '/cost';
    return makeApiCall(endpoint, query);
}

function getInvoiceAnalyticsAverageCost(query): any {
    const endpoint = '/avg_cost';
    return makeApiCall(endpoint, query);
}

const getComparisonCost = (query: Query | string): Promise<any> => {
    const endpoint = '/comparison_cost';
    return makeApiCall(endpoint, query);
};

const getComparisonAvgCost = (query: Query | string): Promise<any> => {
    const endpoint = '/comparison_avg_cost';
    return makeApiCall(endpoint, query);
};

const getComparisonAnalyticsRecords = (query: Query | string): Promise<any> => {
    const endpoint = '/comparison';
    return makeApiCall(endpoint, query);
};

export {
    getInvoiceAnalyticsNoShipments,
    getInvoiceAnalyticsTopZone,
    getInvoiceAnalyticsTopWeight,
    getInvoiceAnalyticsCost,
    getInvoiceAnalyticsAverageCost,
    getComparisonCost,
    getComparisonAvgCost,
    getComparisonAnalyticsRecords,
};
