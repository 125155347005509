import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { setLocalStorageItem, setLocalStorageItemError } from './actions';
import secureLocalStorage from 'react-secure-storage';
import { LocalStorageActionTypes } from './constants';

function* setItemToLocalStorage(action) {
    try {
        const { key, value } = action.payload;
        console.log('-----key', key);
        console.log('-----value', value);
        yield secureLocalStorage.setItem(key, value);
    } catch (error) {
        // Log error or dispatch a Redux action to update the error state in your application
        console.error('Could not set item in localStorage:', error);
        yield put(setLocalStorageItemError(error));
    }
}

function* getItemFromLocalStorage(action) {
    try {
        const { key } = action.payload;
        console.log('-------key', key);
        const value = yield secureLocalStorage.getItem(key);
        // Dispatch action to set the retrieved value in Redux state
        yield put(setLocalStorageItem(key, value));
    } catch (error) {
        // Log error or dispatch a Redux action to update the error state in your application
        console.error('Could not get item from localStorage:', error);
        yield put(setLocalStorageItemError(error));
    }
}

function* removeItemFromLocalStorage(action) {
    try {
        const { key } = action.payload;
        yield secureLocalStorage.removeItem(key);
    } catch (error) {
        // Log error or dispatch a Redux action to update the error state in your application
        console.error('Could not remove item from localStorage:', error);
        yield put(setLocalStorageItemError(error));
    }
}

function* watchGetLocalStorageItem() {
    yield takeLatest(LocalStorageActionTypes.GET_LOCAL_STORAGE_ITEM, getItemFromLocalStorage);
}

function* watchSetLocalStorageItem() {
    yield takeLatest(LocalStorageActionTypes.SET_LOCAL_STORAGE_ITEM, setItemToLocalStorage);
}

function* watchRemoveLocalStorageItem() {
    yield takeLatest(LocalStorageActionTypes.REMOVE_LOCAL_STORAGE_ITEM, removeItemFromLocalStorage);
}

export default function* localStorageSaga() {
    yield all([fork(watchGetLocalStorageItem), fork(watchSetLocalStorageItem), fork(watchRemoveLocalStorageItem)]);
}
