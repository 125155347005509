export const EditOrderActionTypes = {
    API_RESPONSE_SUCCESS: '@@edit-order/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@edit-order/API_RESPONSE_ERROR',
    GET_ORDER: '@@edit-order/GET_ERROR',
    UPDATE_ORDER_SPEC: '@@edit-order/UPDATE_ORDER_SPEC',
    RESET_ORDER_SPEC: '@@edit-order/RESET_ORDER_SPEC',
    UPDATE_ORDER: '@@edit-order/UPDATE_ORDER',
    RESET_STATE: '@@edit-order/RESET_STATE',
    RESET_ALL_ERRORS: '@@edit-order/RESET_ALL_ERRORS',
    GET_RATES: '@@edit-order/GET_RATES',
    RESET_RATES: '@@edit-order/RESET_RATES',
    PURCHASE_ORDER: '@@edit-order/PURCHASE_ORDER',
};
