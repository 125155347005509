// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

export class AuthApi {
    static async getUser(): Promise<UserResponse> {
        const userId = api.getLoggedInUser().id;
        const response = await getUser({ userId });
        return response.data;
    }
}

function getUser(params: any): Promise<any> {
    const baseUrl = config.USERS_URL;
    return api.get(`${baseUrl}/${params.userId}`, {});
}

// account
function login(params: any): any {
    const baseUrl = `${config.USERS_URL}/login`;
    return api.create(`${baseUrl}`, params);
}

function login2FA(params: any): any {
    const baseUrl = `${config.USERS_URL}/login?2faVerify=true`;
    return api.create(`${baseUrl}`, params);
}

function logout(): any {
    const baseUrl = config.USERS_URL;
    return api.create(`${baseUrl}/logout`, {});
}

function signup(params: any, token: string): any {
    const baseUrl = config.USERS_URL;
    return api.create(`${baseUrl}/signup`, params, { params: { token } });
}

function forgotPassword(params: any): any {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

function resetPassword(email: any): any {
    const baseUrl = `${config.USERS_URL}/request-reset-password?email=${email}`;
    return api.get(`${baseUrl}`, {});
}

function resetPasswordComfirmation(token: string, payload: any): any {
    const baseUrl = `${config.USERS_URL}/reset-password`;
    return api.update(`${baseUrl}`, payload, { params: { token } });
}

// 2FA
function create2FA(params: { channel: string, target: string }): any {
    const userId = api.getLoggedInUser().id;
    return api.create(`${config.USERS_URL}/${userId}/mfa?2faVerify=false`, params);
}

function verify2FA(params: { channel: string, target: string, otp: string, sid: string }): any {
    const userId = api.getLoggedInUser().id;
    return api.create(`${config.USERS_URL}/${userId}/mfa?2faVerify=true`, params);
}

function delete2FA(params: { channel: string, target: string }): any {
    const userId = api.getLoggedInUser().id;
    return api.delete(`${config.USERS_URL}/${userId}/mfa?2faVerify=false&action=delete`, params);
}

function verify2FADeletion(params: { channel: string, target: string, otp: string, sid: string }): any {
    const userId = api.getLoggedInUser().id;
    return api.delete(`${config.USERS_URL}/${userId}/mfa?2faVerify=true&action=delete`, params);
}

function edit2FA(params: { channel: string, target: string }): any {
    const userId = api.getLoggedInUser().id;
    return api.delete(`${config.USERS_URL}/${userId}/mfa?2faVerify=false&action=edit`, params);
}

function verify2FAEdit(params: { channel: string, target: string, otp: string, sid: string }): any {
    const userId = api.getLoggedInUser().id;
    return api.delete(`${config.USERS_URL}/${userId}/mfa?2faVerify=true&action=edit`, params);
}

export {
    getUser,
    login,
    login2FA,
    logout,
    signup,
    forgotPassword,
    forgotPasswordConfirm,
    create2FA,
    verify2FA,
    delete2FA,
    verify2FADeletion,
    edit2FA,
    verify2FAEdit,
    resetPassword,
    resetPasswordComfirmation,
};

export type UserResponse = {
    id: string,
    email: string,
    phone: string | null,
    company_name: string | null,
    first_name: string | null,
    middle_name: string | null,
    last_name: string | null,
    street1: string | null,
    street2: string | null,
    street3: string | null,
    city_locality: string | null,
    state_province: string | null,
    postal_code: string | null,
    country_code: string | null,
    email_verification_status: string,
    sms_verification_status: string,
    billing_plan: { id: string, name: string } | null,
};
