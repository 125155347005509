export const AddressBookActionTypes = {
    API_RESPONSE_SUCCESS: '@@addresses/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@addresses/API_RESPONSE_ERROR',
    READ_ALL_ADDRESSES_REQUEST: '@@addresses/READ_ALL_ADDRESSES_REQUEST',
    CREATE_ADDRESS_REQUEST: '@@addresses/CREATE_ADDRESS_REQUEST',
    READ_ADDRESS_REQUEST: '@@addresses/READ_ADDRESS_REQUEST',
    UPDATE_ADDRESS_REQUEST: '@@addresses/UPDATE_ADDRESS_REQUEST',
    DELETE_ADDRESS_REQUEST: '@@addresses/DELETE_ADDRESS_REQUEST',
    VALIDATE_ADDRESS_REQUEST: '@@addresses/VALIDATE_ADDRESS_REQUEST',
    RESET_VALIDATION: '@@addresses/RESET_VALIDATION',
    DISABLE_VALIDATION: '@@addresses/DISABLE_VALIDATION',
    RESET_STATE: '@@addresses/RESET_STATE',
    RESET_ALL_ERRORS: '@@addresses/RESET_ALL_ERRORS',
    READ_DEFAULT_ADDRESS_REQUEST: '@@addresses/READ_DEFAULT_ADDRESS_REQUEST',
};
