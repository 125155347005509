const integrationResponseToIntegrationObject = (response) => {
    return {
        integrationId: response.id,
        createdAt: response.created_at,
        integrationName: response.integration_name,
        integrationType: response.integration_type,
        importType: response.import_type,
        active: response.active,
        integrationProcessingStatus: response.integration_processing_status,
        totalRecords: response.total_records,
        greenRecords: response.green_records,
        yellowRecords: response.yellow_records,
        redRecords: response.red_records,
        totalImportedRecords: response.total_imported,
        hasErrors: response.has_errors
    };
};

export { integrationResponseToIntegrationObject };