import { ShopifyActionTypes } from './constants';

type ShopifyAction = { type: string, payload: {} | string };

export const shopifyApiResponseSuccess = (actionType: string, data: any): ShopifyAction => ({
    type: ShopifyActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const shopifyApiResponseError = (actionType: string, error: string): ShopifyAction => ({
    type: ShopifyActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const shopifyCreateIntegrationAndStartOAuth = (shopifyIntegration: any): ShopifyAction => ({
    type: ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST,
    payload: { shopifyIntegration },
});