import { AnalyticsRatesActionTypes } from './constants';

type AnalyticsRatesAction = { type: string, payload: {} | string };

export const analyticsRatesApiResponseSuccess = (actionType: string, data: any): AnalyticsRatesAction => ({
    type: AnalyticsRatesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const analyticsRatesApiResponseError = (actionType: string, error: string): AnalyticsRatesAction => ({
    type: AnalyticsRatesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllAnalyticsRates = (query): AnalyticsRatesAction => ({
    type: AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES,
    payload: { query },
});

export const analyticsRatesResetAllErrors = (): AnalyticsRatesAction => ({
    type: AnalyticsRatesActionTypes.RESET_ALL_ERRORS,
    payload: {},
});