import { RateSetsActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loading: false,
    error: null,
    rateSets: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type RateSetsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

//TODO: define data type for rate sets
type State = { loading: Boolean, error?: null, rateSets: any };

const RateSets = (state: State = INIT_STATE, action: RateSetsAction): any => {
    //console.log(action);
    switch (action.type) {
        case RateSetsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case RateSetsActionTypes.GET_ALL_RATE_SETS_FOR_USER: {
                    return { 
                        ...state, 
                        loading: false, 
                        rateSets: action.payload.data.rateSets,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                default:
                    return { ...state, loading: false };
            }

        case RateSetsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                default:
                    return { ...state, loading: false };
            }
        case RateSetsActionTypes.GET_ALL_RATE_SETS_FOR_USER:
            return { ...state, loading: true, rateSets: null };
        case RateSetsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default RateSets;
