import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import { readAllCarriers } from '../../helpers';
import { CarrierActionTypes } from './constants';
import { carrierApiResponseError, carrierApiResponseSuccess, getAllCarriers } from './actions';
import {
    carrierEntryObjectToCarrierEntryRequest,
    carrierEntryResponseToCarrierEntryObject,
} from '../../helpers/mappers/carrier';
import { createCarrier, deleteCarrier, updateCarrier } from '../../helpers/api/carrier';
import { addToast } from '../actions';

export function* readAllCarrierSaga() {
    try {
        const response = yield call(readAllCarriers);
        const convertedData = response.data.map(carrierEntryResponseToCarrierEntryObject);
        yield put(carrierApiResponseSuccess(CarrierActionTypes.READ_ALL_CARRIER_REQUEST, convertedData));
    } catch (error) {
        yield put(carrierApiResponseError(CarrierActionTypes.READ_ALL_CARRIER_REQUEST, error));
    }
}

export function* createCarrierSaga({
    payload: {
        carrier: { callback, ...carrierInfo },
    },
}) {
    try {
        const response = yield call(createCarrier, carrierEntryObjectToCarrierEntryRequest(carrierInfo));
        if (response) {
            callback(carrierEntryResponseToCarrierEntryObject(response.data));
            // yield put(getAllCarriers());
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        callback();
    }
}

export function* updateCarrierSaga({ payload }) {
    try {
        const { id, isReloading = true, ...rest } = payload;
        const response = yield call(updateCarrier, id, carrierEntryObjectToCarrierEntryRequest(rest));
        if (response) {
            if (isReloading) {
                yield put(addToast({ desc: 'Your carrier account setting was successfully saved.', type: 'success' }));
                yield put(getAllCarriers());
            }
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

export function* deleteCarrierSaga({ payload: { id } }) {
    try {
        const response = yield call(deleteCarrier, id);
        if (response) {
            yield put(getAllCarriers());
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

export function* watchReadAllCarrierSaga(): any {
    yield takeLatest(CarrierActionTypes.READ_ALL_CARRIER_REQUEST, readAllCarrierSaga);
}

export function* watchCreateCarrierSaga(): any {
    yield takeLatest(CarrierActionTypes.CREATE_CARRIER_REQUEST, createCarrierSaga);
}

export function* watchUpdateCarrierSaga(): any {
    yield takeLatest(CarrierActionTypes.UPDATE_CARRIER_REQUEST, updateCarrierSaga);
}

export function* watchDeleteCarrierSaga(): any {
    yield takeLatest(CarrierActionTypes.DELETE_CARRIER_REQUEST, deleteCarrierSaga);
}

function* carrierSaga() {
    yield all([
        fork(watchReadAllCarrierSaga),
        fork(watchCreateCarrierSaga),
        fork(watchUpdateCarrierSaga),
        fork(watchDeleteCarrierSaga),
    ]);
}

export default carrierSaga;
