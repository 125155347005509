import { ShipmentAnalyticsActionTypes } from './constants';

type ShipmentAnalyticsAction = { type: string, payload: {} | string };

export const shipmentAnalyticsApiResponseSuccess = (actionType: string, data: any): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const shipmentAnalyticsApiResponseError = (actionType: string, error: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const shipmentAnalyticsGetNoCustomers = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetCost = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetAverageCost = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetShipments = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetTopSources = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetTopCarriers = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetTopDestinations = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetServiceLevels = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetDailyServiceLevels = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST,
    payload: { query },
});

export const shipmentAnalyticsGetAggregatedCost = (query: string): ShipmentAnalyticsAction => ({
    type: ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST,
    payload: { query },
});