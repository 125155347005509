export const ShipmentAnalyticsActionTypes = {
    API_RESPONSE_SUCCESS: '@@shipment-analytics/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@shipment-analytics/API_RESPONSE_ERROR',
    GET_SHIPMENTS_NO_CUSTOMERS_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_NO_CUSTOMERS_REQUEST',
    GET_SHIPMENTS_COST_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_COST_REQUEST',
    GET_SHIPMENTS_AVG_COST_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_AVG_COST_REQUEST',
    GET_SHIPMENTS_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_REQUEST',
    GET_SHIPMENTS_TOP_SOURCES_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_TOP_SOURCES_REQUEST',
    GET_SHIPMENTS_TOP_PRODUCTS_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_TOP_PRODUCTS_REQUEST',
    GET_SHIPMENTS_TOP_CARRIERS_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_TOP_CARRIERS_REQUEST',
    GET_SHIPMENTS_TOP_DEST_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_TOP_DEST_REQUEST',
    GET_SHIPMENTS_SERVICE_LEVELS_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_SERVICE_LEVELS_REQUEST',
    GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST',
    GET_SHIPMENTS_AGGREGATED_COST_REQUEST: '@@shipment-analytics/GET_SHIPMENTS_AGGREGATED_COST_REQUEST',
};
