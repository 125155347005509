import { ShipmentAnalyticsActionTypes } from './constants';

export const INIT_STATE = {
    loadingShipmentsNoCustomers: false,
    errorGetShipmentsNoCustomers: null,
    shipmentsNoCustomers: null,
    loadingShipmentsCost: false,
    errorGetShipmentsCost: null,
    shipmentsCost: null,
    loadingShipmentsAvgCost: false,
    errorGetShipmentsAvgCost: null,
    shipmentsAvgCost: null,
    loadingShipments: false,
    errorGetShipments: null,
    shipments: null,
    loadingShipmentsTopSources: false,
    errorGetShipmentsTopSources: null,
    shipmentsTopSources: null,
    loadingShipmentsTopCarriers: false,
    errorGetShipmentsTopCarriers: null,
    shipmentsTopCarriers: null,
    loadingShipmentsTopDest: false,
    errorGetShipmentsTopDest: null,
    shipmentsTopDest: null,
    loadingShipmentsServiceLevels: false,
    errorGetShipmentsServiceLevels: null,
    shipmentsServiceLevels: null,
    loadingShipmentsDailyServiceLevels: false,
    errorGetShipmentsDailyServiceLevels: null,
    shipmentsDailyServiceLevels: null,
    loadingShipmentsAggregatedCost: false,
    errorGetShipmentsAggregatedCost: null,
    shipmentsAggregatedCost: null,
};

type ShipmentAnalyticsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

//TODO: define data type for shipments
type State = {
    loadingShipmentsNoCustomers: Boolean,
    errorGetShipmentsNoCustomers: null,
    shipmentsNoCustomers: any,
    loadingShipmentsCost: Boolean,
    errorGetShipmentsCost: null,
    shipmentsCost: any,
    loadingShipmentsAvgCost: Boolean,
    errorGetShipmentsAvgCost: null,
    shipmentsAvgCost: any,
    loadingShipments: Boolean,
    errorGetShipments: null,
    shipments: any,
    loadingShipmentsTopSources: Boolean,
    errorGetShipmentsTopSources: null,
    shipmentsTopSources: any,
    loadingShipmentsTopCarriers: Boolean,
    errorGetShipmentsTopCarriers: null,
    shipmentsTopCarriers: any,
    loadingShipmentsTopDest: Boolean,
    errorGetshipmentsTopDest: null,
    shipmentsTopDest: any,
    loadingShipmentsServiceLevels: Boolean,
    errorGetShipmentsServiceLevels: null,
    shipmentsServiceLevels: any,
    loadingShipmentsDailyServiceLevels: Boolean,
    errorGetShipmentsDailyServiceLevels: null,
    shipmentsDailyServiceLevels: any,
    loadingShipmentsAggregatedCost: Boolean,
    errorGetShipmentsAggregatedCost: null,
    shipmentsAggregatedCost: any,
};

const ShipmentAnalytics = (state: State = INIT_STATE, action: ShipmentAnalyticsAction): any => {
    switch (action.type) {
        case ShipmentAnalyticsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST: {
                    return { ...state, loadingShipmentsNoCustomers: false, shipmentsNoCustomers: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST: {
                    return { ...state, loadingShipmentsCost: false, shipmentsCost: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST: {
                    return { ...state, loadingShipmentsAvgCost: false, shipmentsAvgCost: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST: {
                    return { ...state, loadingShipments: false, shipments: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST: {
                    return { ...state, loadingShipmentsTopSources: false, shipmentsTopSources: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST: {
                    return { ...state, loadingShipmentsTopCarriers: false, shipmentsTopCarriers: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST: {
                    return { ...state, loadingShipmentsTopDest: false, shipmentsTopDest: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST: {
                    return { ...state, loadingShipmentsServiceLevels: false, shipmentsServiceLevels: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST: {
                    return { ...state, loadingShipmentsDailyServiceLevels: false, shipmentsDailyServiceLevels: action.payload.data };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST: {
                    return { ...state, loadingShipmentsAggregatedCost: false, shipmentsAggregatedCost: action.payload.data };
                }
                default:
                    return { ...state, loadingShipmentsNoCustomers: false, loadingShipmentsCost: false, loadingShipmentsAvgCost: false, loadingShipments: false, loadingShipmentsTopSources: false, loadingShipmentsTopCarriers: false, loadingShipmentsTopDest: false, loadingShipmentsServiceLevels: false, loadingShipmentsDailyServiceLevels: false };
            }

        case ShipmentAnalyticsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST: {
                    return { ...state, loadingShipmentsNoCustomers: false, errorGetShipmentsNoCustomers: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST: {
                    return { ...state, loadingShipmentsCost: false, errorGetShipmentsCost: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST: {
                    return { ...state, loadingShipmentsAvgCost: false, errorGetShipmentsAvgCost: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST: {
                    return { ...state, loadingShipments: false, errorGetShipments: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST: {
                    return { ...state, loadingShipmentsTopSources: false, errorGetShipmentsTopSources: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST: {
                    return { ...state, loadingShipmentsTopCarriers: false, errorGetShipmentsTopCarriers: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST: {
                    return { ...state, loadingShipmentsTopDest: false, errorGetshipmentsTopDest: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST: {
                    return { ...state, loadingShipmentsServiceLevels: false, errorGetShipmentsServiceLevels: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST: {
                    return { ...state, loadingShipmentsDailyServiceLevels: false, errorGetShipmentsDailyServiceLevels: action.payload.error };
                }
                case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST: {
                    return { ...state, loadingShipmentsAggregatedCost: false, errorGetShipmentsAggregatedCost: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_NO_CUSTOMERS_REQUEST:
            return { ...state, loadingShipmentsNoCustomers: true, shipmentsNoCustomers: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_COST_REQUEST:
            return { ...state, loadingShipmentsCost: true, shipmentsCost: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AVG_COST_REQUEST:
            return { ...state, loadingShipmentsAvgCost: true, shipmentsAvgCost: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_REQUEST:
            return { ...state, loadingShipments: true, shipments: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_SOURCES_REQUEST:
            return { ...state, loadingShipmentsTopSources: true, shipmentsTopSources: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_CARRIERS_REQUEST:
            return { ...state, loadingShipmentsTopCarriers: true, shipmentsTopCarriers: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_TOP_DEST_REQUEST:
            return { ...state, loadingShipmentsTopDest: true, shipmentsTopDest: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_SERVICE_LEVELS_REQUEST:
            return { ...state, loadingShipmentsServiceLevels: true, shipmentsServiceLevels: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_DAILY_SERVICE_LEVELS_REQUEST:
            return { ...state, loadingShipmentsDailyServiceLevels: true, shipmentsDailyServiceLevels: null };
        case ShipmentAnalyticsActionTypes.GET_SHIPMENTS_AGGREGATED_COST_REQUEST:
            return { ...state, loadingShipmentsAggregatedCost: true, shipmentsAggregatedCost: null };
        default:
            return { ...state };
    }
};

export default ShipmentAnalytics;
