const paginationHeadersResponse = (headers) => {
    const pagination = {
        pageSize: parseInt(headers['page_size']),
        currentPage: parseInt(headers['page']),
        totalPages: parseInt(headers['total_pages']),
        totalItems: parseInt(headers['total_items']),
    };

    return pagination;
};

export { paginationHeadersResponse };
