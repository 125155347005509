import { PackageItemsActionTypes } from './constants';

const INITIAL_STATE = {
    packageItems: [],
    loading: false,
    error: null,
    packageItem: {},
    creating: false,
    created: false,
    createdPackageItemId: null,
};

const PackageItems = (state = INITIAL_STATE, action) => {
    //console.log(action);
    switch (action.type) {
        case PackageItemsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PackageItemsActionTypes.GET_PACKAGE_ITEMS: {
                    console.log('packageItems', action.payload.data);
                    return { ...state, packageItems: action.payload.data, loading: false };
                }
                case PackageItemsActionTypes.GET_PACKAGE_ITEM: {
                    console.log('packageItem', action.payload.data);
                    return { ...state, packageItem: action.payload.data, loading: false };
                }
                case PackageItemsActionTypes.CREATE_PACKAGE_ITEM: {
                    return { ...state, creating: false, created: true, createdPackageItemId: action.payload.data };
                }
                case PackageItemsActionTypes.UPDATE_PACKAGE_ITEM: {
                    return { ...state, creating: false, created: true, createdPackageItemId: action.payload.data };
                }
                case PackageItemsActionTypes.REMOVE_PACKAGE_ITEM: {
                    return { ...state, creating: false, created: true, createdPackageItemId: action.payload.data };
                }
                default:
                    return { ...state };
            }

        case PackageItemsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PackageItemsActionTypes.GET_PACKAGE_ITEMS: {
                    return { ...state, error: action.payload.error, loading: false };
                }
                case PackageItemsActionTypes.GET_PACKAGE_ITEM: {
                    return { ...state, error: action.payload.error, loading: false };
                }
                case PackageItemsActionTypes.CREATE_PACKAGE_ITEM: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                case PackageItemsActionTypes.UPDATE_PACKAGE_ITEM: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                case PackageItemsActionTypes.REMOVE_PACKAGE_ITEM: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case PackageItemsActionTypes.GET_PACKAGE_ITEMS:
            return { ...state, loading: true, packageItems: [] };
        case PackageItemsActionTypes.GET_PACKAGE_ITEM:
            return { ...state, loading: true, packageItem: {} };
        case PackageItemsActionTypes.CREATE_PACKAGE_ITEM:
            return { ...state, creating: true, created: false };
        case PackageItemsActionTypes.UPDATE_PACKAGE_ITEM:
            return { ...state, creating: true, created: false };
        case PackageItemsActionTypes.REMOVE_PACKAGE_ITEM:
            return { ...state, creating: true, created: false };
        case PackageItemsActionTypes.RESET_STATE:
            const x = INITIAL_STATE;
            x.packageItems = [];
            return x;
        case PackageItemsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default PackageItems;
