// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import { getAllRateSetsForUserApi as getAllRateSetsForUserApi } from '../../helpers';

import { rateSetsApiResponseSuccess, rateSetsApiResponseError } from './actions';
import { RateSetsActionTypes } from './constants';
import { rateSetResponseToRateSetObject } from '../../helpers/mappers/rate-sets';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function* getAllRateSetsForUser({ payload: { query } }) {
    try {
        const getAllRateSetsResponse = yield call(getAllRateSetsForUserApi, query);
        const rateSets = getAllRateSetsResponse.data.map((rateSetResponse) => rateSetResponseToRateSetObject(rateSetResponse));
        const pagination = { ...paginationHeadersResponse(getAllRateSetsResponse.headers) };
        yield put(rateSetsApiResponseSuccess(RateSetsActionTypes.GET_ALL_RATE_SETS_FOR_USER, { rateSets, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(rateSetsApiResponseError(RateSetsActionTypes.GET_ALL_RATE_SETS_FOR_USER, error));
    }
}

export function* watchGetAllRateSetsForUser(): any {
    yield takeLatest(RateSetsActionTypes.GET_ALL_RATE_SETS_FOR_USER, getAllRateSetsForUser);
}

function* rateSetsSaga(): any {
    yield all([fork(watchGetAllRateSetsForUser)]);
}

export default rateSetsSaga;
