import { ZoneAnalyticsActionTypes } from './constants';

export const INIT_STATE = {
    errorGetAvgDomesticZone: null,
    loadingAvgDomesticZone: false,
    avgDomesticZone: null,
    errorGetZonesCount: null,
    loadingZonesCount: false,
    zonesCount: null,
    errorsGetZonesShipmentsCost: null,
    loadingZonesShipmentsCost: false,
    zonesShipmentsCost: null
};

type ZoneAnalyticsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

//TODO: define data type for zones
type State = {
    errorGetAvgDomesticZone?: null,
    loadingAvgDomesticZone: Boolean,
    avgDomesticZone: any,
    errorGetZonesCount?: null,
    loadingZonesCount: Boolean,
    zonesCount: any,
    errorsGetZonesShipmentsCost: null,
    loadingZonesShipmentsCost: Boolean,
    zonesShipmentsCost: any
};

const ZoneAnalytics = (state: State = INIT_STATE, action: ZoneAnalyticsAction): any => {
    switch (action.type) {
        case ZoneAnalyticsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE: {
                    return { ...state, loadingAvgDomesticZone: false, avgDomesticZone: action.payload.data };
                }
                case ZoneAnalyticsActionTypes.GET_ZONES_COUNT: {
                    return { ...state, loadingZonesCount: false, zonesCount: action.payload.data };
                }
                case ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST: {
                    return { ...state, loadingZonesShipmentsCost: false, zonesShipmentsCost: action.payload.data };
                }
                default:
                    return { ...state, loadingAvgDomesticZone: false, loadingZonesCount: false };
            }

        case ZoneAnalyticsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE: {
                    return { ...state, loadingAvgDomesticZone: false, errorGetAvgDomesticZone: action.payload.error };
                }
                case ZoneAnalyticsActionTypes.GET_ZONES_COUNT: {
                    return { ...state, loadingZonesCount: false, errorGetZonesCount: action.payload.error };
                }
                case ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST: {
                    return { ...state, loadingZonesShipmentsCost: false, errorsGetZonesShipmentsCost: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE:
            return { ...state, loadingAvgDomesticZone: true, avgDomesticZone: null };
        case ZoneAnalyticsActionTypes.GET_ZONES_COUNT:
            return { ...state, loadingZonesCount: true, zonesCount: null };
        case ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST:
            return { ...state, loadingZonesShipmentsCost: true, zonesShipmentsCost: null };
        default:
            return { ...state };
    }
};

export default ZoneAnalytics;
