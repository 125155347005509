import { RateSetsActionTypes } from './constants';

type RateSetsAction = { type: string, payload: {} | string };

export const rateSetsApiResponseSuccess = (actionType: string, data: any): RateSetsAction => ({
    type: RateSetsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const rateSetsApiResponseError = (actionType: string, error: string): RateSetsAction => ({
    type: RateSetsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const rateSetsGetAllRateSetsForUser = (query): RateSetsAction => ({
    type: RateSetsActionTypes.GET_ALL_RATE_SETS_FOR_USER,
    payload: { query },
});

export const rateSetsResetAllErrors = (): RateSetsAction => ({
    type: RateSetsActionTypes.RESET_ALL_ERRORS,
    payload: {},
});
