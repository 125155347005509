// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function getShipmentsNoCustomers(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/customers';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsCost(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/cost';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsAverageCost(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/average_cost';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipments(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/daily_shipments_data';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsTopCarriers(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/top_carriers';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsTopDestinations(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/top_destinations';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsTopSources(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/top_sources';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsServiceLevels(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/service_levels';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentsDailyServiceLevels(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/daily_service_levels';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

function getShipmentAggregatedCost(query): any {
    let baseUrl = config.SHIPMENTS_ANALYTICS_URL;
    baseUrl += '/aggregated_cost';
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }
    return api.get(`${baseUrl}`, {});
}

export {
    getShipmentsNoCustomers,
    getShipmentsCost,
    getShipmentsAverageCost,
    getShipments,
    getShipmentsTopCarriers,
    getShipmentsTopDestinations,
    getShipmentsTopSources,
    getShipmentsServiceLevels,
    getShipmentsDailyServiceLevels,
    getShipmentAggregatedCost,
};
