import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Toast as ReactBootstrapToast } from 'react-bootstrap';
import logo from '../../assets/images/logo-commerceship-sm.png';
import { removeToast } from '../../redux/actions';
import { formatDistanceToNow } from 'date-fns';
import { useLocation } from 'react-router-dom';
import { navigateErrorClearAllErrors } from '../../redux/navigate-error/actions';

function Toast() {
    const dispatch = useDispatch();
    const toasts = useSelector((state) => state.Toast.toasts);

    const [isFullRender, setIsFullRender] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            if (toasts && toasts.length > 0) {
                dispatch(removeToast(0));
            }
        }, 3000);
        return () => clearInterval(timer);
    }, [toasts, dispatch]);

    const location = useLocation();

    useEffect(() => {
        if (toasts && toasts.length > 0) {
            dispatch(removeToast(0));
        }
        setIsFullRender(true);
    }, [location]);

    useEffect(() => {
        if (isFullRender) {
            dispatch(navigateErrorClearAllErrors());
        }
    }, [location, isFullRender]);

    const successStyle = { backgroundColor: '#28a745', color: 'white', width: '400px' };
    const errorStyle = { backgroundColor: '#dc3545', color: 'white', width: '400px' };
    const commonStyle = { backgroundColor: 'rgb(255, 255, 255)', color: '#343a40', width: '400px' };

    const toastStyle = (type) => {
        switch (type) {
            case 'success':
                return successStyle;
            case 'error':
                return errorStyle;
            default:
                return commonStyle;
        }
    };

    const toastList = toasts?.map((item, index) => (
        <ReactBootstrapToast key={index} style={toastStyle(item.type)} onClose={() => dispatch(removeToast(index))}>
            <ReactBootstrapToast.Header
                className="toast-header"
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    backgroundColor: toastStyle(item.type).backgroundColor,
                }}
                closeButton={true}>
                <img src={logo} alt="brand-logo" height="14" className="me-2" />
                <strong className="me-auto">CommerceShip</strong>
                <small className="ms-5">
                    {formatDistanceToNow(new Date(), { addSuffix: true, includeSeconds: true })}
                </small>
            </ReactBootstrapToast.Header>
            <ReactBootstrapToast.Body>{item.desc}</ReactBootstrapToast.Body>
        </ReactBootstrapToast>
    ));

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            style={{
                position: 'fixed',
                minHeight: '200px',
                top: 20,
                right: 20,
                zIndex: 9999,
            }}>
            {toastList}
        </div>
    );
}

export default Toast;
