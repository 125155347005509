const labelSettingsEntryResponseTolabelSettingsEntryObject = (response) => {
    return {
        id: response.id,
        settingsType: response.settings_type,
        settingsAction: response.settings_action,
        imageType: response.image_type,
        imageSize: response.image_size,
        message1: response.message1,
        message2: response.message2,
        message3: response.message3,
        modifiedAt: response.modified_at,
    };
};

const labelSettingsEntryObjectTolabelSettingsEntryRequest = (object) => {
    return {
        id: object.id,
        settings_type: object.settingsType,
        settings_action: object.settingsAction,
        image_type: object.imageType,
        image_size: object.imageSize,
        message1: object.message1,
        message2: object.message2,
        message3: object.message3,
    };
};

export { labelSettingsEntryResponseTolabelSettingsEntryObject, labelSettingsEntryObjectTolabelSettingsEntryRequest };
