// @flow
import { all, fork, put, takeEvery, call, takeLatest } from 'redux-saga/effects';

import { PaymentMethodsApi } from '../../helpers/';

import { BillingActions } from './actions';
import { BillingActionTypes } from './constants';
import { addToast } from '../actions';

function* loadPaymentMethods() {
    try {
        const response = (yield call(PaymentMethodsApi.getAll));
        const paymentMethods = response.data;
        yield put(BillingActions.apiResponseSuccess(BillingActionTypes.LOAD_PAYMENT_METHODS, paymentMethods));
    } catch (error) {
        yield put(addToast({ desc: error.message || error.errors[0].message, type: 'error' }));
        yield put(BillingActions.apiResponseError(BillingActionTypes.LOAD_PAYMENT_METHODS, error));
    }
}

export function* watchLoadPaymentMethods(): any {
    yield takeEvery(BillingActionTypes.LOAD_PAYMENT_METHODS, loadPaymentMethods);
}

function* saga(): any {
    yield all([
        fork(watchLoadPaymentMethods),
    ]);
}

export default saga;
