import { OverlayTrigger, Popover } from 'react-bootstrap';

const PopoverTooltip = (props) => {
    const { children, message, placement = 'top', ...rest } = props;
    const isLongMessage = message.length > 30; // adjust the value as per your requirements

    return (
        <OverlayTrigger
            placement={placement}
            overlay={
                <Popover style={{ zIndex: 10000 }} className={`popover-tooltip ${isLongMessage ? 'long-content' : ''}`}>
                    <Popover.Body className="popover-body-tooltip">{message}</Popover.Body>
                </Popover>
            }
            {...rest}>
            <div className="d-inline-block">
            {children}
            </div>
        </OverlayTrigger>
    );
};

export default PopoverTooltip;
