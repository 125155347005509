import { AnalyticsRatingErrorsActionTypes } from './constants';
import { PAGINATION_DEFAULT_VALUE } from '../../helpers/defaultValue';

const INIT_STATE = {
    loading: false,
    error: null,
    analyticsRatingErrors: null,
    pagination: PAGINATION_DEFAULT_VALUE,
};

type AnalyticsRatingErrorsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = {
    loading: Boolean,
    error?: null,
    analyticsRatingErrors: any,
    pagination: any,
};

const AnalyticsRatingErrors = (state: State = INIT_STATE, action: AnalyticsRatingErrorsAction): any => {
    switch (action.type) {
        case AnalyticsRatingErrorsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS: {
                    return {
                        ...state,
                        loading: false,
                        analyticsRatingErrors: action.payload.data.analyticsRatingErrors,
                        pagination: { ...PAGINATION_DEFAULT_VALUE, ...action.payload.data.pagination },
                    };
                }
                default:
                    return { ...state, loading: false };
            }

        case AnalyticsRatingErrorsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS: {
                    return { ...state, loading: false, error: action.payload.error };
                }
                default:
                    return { ...state, loading: false };
            }

        case AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS:
            return { ...state, loading: true, analyticsRatingErrors: null };
        case AnalyticsRatingErrorsActionTypes.RESET_STATE:
            return INIT_STATE;
        case AnalyticsRatingErrorsActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default AnalyticsRatingErrors;
