import { OrderAnalyticsActionTypes } from './constants';

export const INIT_STATE = {
    errorGetNoCustomers: null,
    loadingNoCustomers: false,
    noCustomers: null,
    errorGetNoOrders: null,
    loadingNoOrders: false,
    noOrders: null,
    errorGetTotalOrderRevenue: null,
    loadingTotalOrderRevenue: false,
    totalOrderRevenue: null,
    errorGetDailyRevenue: null,
    loadingDailyRevenue: false,
    dailyRevenue: null,
    errorGetTopCustomers: null,
    loadingTopCustomers: false,
    topCustomers: null,
    errorGetTopProducts: null,
    loadingTopProducts: false,
    topProducts: null,
    errorGetTopSources: null,
    loadingTopSources: false,
    topSources: null,
};

type OrderAnalyticsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

//TODO: define data type for orders
type State = {
    errorGetNoCustomers?: null,
    loadingNoCustomers: Boolean,
    noCustomers: any,
    errorGetNoOrders?: null,
    loadingNoOrders: Boolean,
    noOrders: any,
    errorGetTotalOrderRevenue?: null,
    loadingTotalOrderRevenue: Boolean,
    totalOrderRevenue: any,
    errorGetDailyRevenue?: null,
    loadingDailyRevenue: Boolean,
    dailyRevenue: any,
    errorGetTopCustomers?: null,
    loadingTopCustomers: Boolean,
    topCustomers: any,
    errorGetTopProducts?: null,
    topProducts: any,
    loadingTopProducts: Boolean,
    errorGetTopSources?: null,
    loadingTopSources: Boolean,
    topSources: any,
};

const OrderAnalytics = (state: State = INIT_STATE, action: OrderAnalyticsAction): any => {
    switch (action.type) {
        case OrderAnalyticsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST: {
                    return { ...state, loadingNoCustomers: false, noCustomers: action.payload.data };
                }
                case OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST: {
                    return { ...state, loadingNoOrders: false, noOrders: action.payload.data };
                }
                case OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST: {
                    return { ...state, loadingTotalOrderRevenue: false, totalOrderRevenue: action.payload.data };
                }
                case OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST: {
                    return { ...state, loadingDailyRevenue: false, dailyRevenue: action.payload.data };
                }
                case OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST: {
                    return { ...state, loadingTopCustomers: false, topCustomers: action.payload.data };
                }
                case OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST: {
                    return { ...state, loadingTopProducts: false, topProducts: action.payload.data };
                }
                case OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST: {
                    return { ...state, loadingTopSources: false, topSources: action.payload.data };
                }
                default:
                    return { ...state, loadingNoCustomers: false, loadingNoOrders: false, loadingTotalOrderRevenue: false, loadingDailyRevenue: false, loadingTopCustomers: false, loadingTopProducts: false, loadingTopSources: false};
            }

        case OrderAnalyticsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST: {
                    return { ...state, loadingNoCustomers: false, errorGetNoCustomers: action.payload.error };
                }
                case OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST: {
                    return { ...state, loadingNoOrders: false, errorGetNoOrders: action.payload.error };
                }
                case OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST: {
                    return { ...state, loadingTotalOrderRevenue: false, errorGetTotalOrderRevenue: action.payload.error };
                }
                case OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST: {
                    return { ...state, loadingDailyRevenue: false, errorGetDailyRevenue: action.payload.error };
                }
                case OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST: {
                    return { ...state, loadingTopCustomers: false, errorGetTopCustomers: action.payload.error };
                }
                case OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST: {
                    return { ...state, loadingTopProducts: false, errorGetTopProducts: action.payload.error };
                }
                case OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST: {
                    return { ...state, loadingTopSources: false, errorGetTopSources: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST:
            return { ...state, loadingNoCustomers: true, noCustomers: null };
        case OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST:
            return { ...state, loadingNoOrders: true, noOrders: null };
        case OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST:
            return { ...state, loadingTotalOrderRevenue: true, totalOrderRevenue: null };
        case OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST:
            return { ...state, loadingDailyRevenue: true, dailyRevenue: null };
        case OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST:
            return { ...state, loadingTopCustomers: true, topCustomers: null };
        case OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST:
            return { ...state, loadingTopProducts: true, topProducts: null };
        case OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST:
            return { ...state, loadingTopSources: true, topSources: null };
        default:
            return { ...state };
    }
};

export default OrderAnalytics;
