// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { PAGINATION_DEFAULT_VALUE } from '../defaultValue';

const api = new APICore();

function getAllBatches(query: Query): any {
    const page = query?.currentPage || PAGINATION_DEFAULT_VALUE.currentPage;
    const page_size = query?.pageSize || PAGINATION_DEFAULT_VALUE.pageSize;

    let baseUrl = config.BATCHES_URL;
    
    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }

    const queryParam = {
        page: page.toString(),
        page_size: page_size.toString(),
    };
    
    return api.get(`${baseUrl}`, typeof query === 'object' ? queryParam : undefined, {});
}

function getSingleBatches(orderBatchId): any {
    const baseUrl = config.BATCHES_URL;
    return api.get(`${baseUrl}/${orderBatchId}`);
}

function createBatches(request): any {
    const baseUrl = config.BATCHES_URL;
    return api.create(`${baseUrl}`, request);
}

function updateBatches(orderBatchId, request): any {
    const baseUrl = config.BATCHES_URL;
    return api.update(`${baseUrl}/${orderBatchId}`, request);
}

function updatePatchBatch(orderBatchId: string, request: any): any {
    console.log('request', request);
    console.log('orderBatchId', orderBatchId);
    const baseUrl = config.BATCHES_URL + '/' + orderBatchId;
    return api.updatePatch(`${baseUrl}`, request);
}

function addOrdersToBatches(orderBatchId, request): any {
    const baseUrl = config.BATCHES_URL;
    return api.create(`${baseUrl}/${orderBatchId}/orders`, request);
}

function moveOrdersToBatches(orderBatchId, request): any {
    const baseUrl = config.BATCHES_URL;
    return api.update(`${baseUrl}/${orderBatchId}/orders/move`, request);
}

function removeOrdersToBatches(orderBatchId, request): any {
    const baseUrl = config.BATCHES_URL;
    return api.delete(`${baseUrl}/${orderBatchId}/orders`, request);
}

function triggerBatch(orderBatchId, request): any {
    const baseUrl = config.BATCHES_URL;
    return api.create(`${baseUrl}/${orderBatchId}/trigger`, request);
}

function downloadLabelFromBatch(orderBatchId, request): any {
    const baseUrl = config.BATCHES_URL;
    return api.create(`${baseUrl}/${orderBatchId}/download_labels`, request);
}

function getAllOrderBatchErrors(orderBatchId): any {
    const baseUrl = `${config.BATCHES_URL}/${orderBatchId}/batch_errors`;
    return api.get(`${baseUrl}`, {});
}

export {
    getAllBatches,
    getSingleBatches,
    createBatches,
    updateBatches,
    updatePatchBatch,
    addOrdersToBatches,
    moveOrdersToBatches,
    removeOrdersToBatches,
    triggerBatch,
    downloadLabelFromBatch,
    getAllOrderBatchErrors
};
