// @flow

type Address = {
    firstName: string,
    middleName: string,
    lastName: string,
    street1: string,
    street2: string,
    street3: string,
    postalCode: string,
    cityLocality: string,
    stateProvince: string,
    countryCode: string,
    companyName: string,
};

const defaultAddress = {
    firstName: '',
    middleName: '',
    lastName: '',
    street1: '',
    street2: '',
    street3: '',
    postalCode: '',
    cityLocality: '',
    stateProvince: '',
    countryCode: '',
    companyName: '',
};

const verifyAddressObjectToRequest = (address: Address = defaultAddress): any => {
    return {
        first_name: address.firstName,
        middle_name: address.middleName,
        last_name: address.lastName,
        street1: address.street1,
        street2: address.street2,
        street3: address.street3,
        postal_code: address.postalCode,
        city_locality: address.cityLocality,
        state_province: address.stateProvince,
        country_code: address.countryCode,
        company_name: address.companyName,
    };
};

export { verifyAddressObjectToRequest };
