import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    getAvgDomesticZone,
    getZonesCount,
    getZonesShipmentsCost
} from '../../helpers';
import {
    zoneAnalyticsApiResponseSuccess,
    zoneAnalyticsApiResponseError,
} from './actions';
import { ZoneAnalyticsActionTypes } from './constants';

export function* getAvgDomesticZoneSaga({ payload: { query } }) {
    try {
        const response = yield call(getAvgDomesticZone, query);
        yield put(zoneAnalyticsApiResponseSuccess(ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE, response.data));
    } catch (error) {
        yield put(zoneAnalyticsApiResponseError(ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE, error));
    }
}

export function* getZonesCountSaga({ payload: { query } }) {
    try {
        const response = yield call(getZonesCount, query);
        yield put(zoneAnalyticsApiResponseSuccess(ZoneAnalyticsActionTypes.GET_ZONES_COUNT, response.data));
    } catch (error) {
        yield put(zoneAnalyticsApiResponseError(ZoneAnalyticsActionTypes.GET_ZONES_COUNT, error));
    }
}

export function* getZonesShipmentsCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getZonesShipmentsCost, query);
        yield put(zoneAnalyticsApiResponseSuccess(ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST, response.data));
    } catch (error) {
        yield put(zoneAnalyticsApiResponseError(ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST, error));
    }
}

export function* watchGetAvgDomesticZoneSaga(): any {
    yield takeLatest(ZoneAnalyticsActionTypes.GET_AVERAGE_DOMESTIC_ZONE, getAvgDomesticZoneSaga);
}

export function* watchGetZonesCountSaga(): any {
    yield takeLatest(ZoneAnalyticsActionTypes.GET_ZONES_COUNT, getZonesCountSaga);
}

export function* watchGetZonesShipmentsCostSaga(): any {
    yield takeLatest(ZoneAnalyticsActionTypes.GET_ZONES_SHIPMENTS_COST, getZonesShipmentsCostSaga);
}

function* zoneAnalyticsSaga() {
    yield all([
        fork(watchGetAvgDomesticZoneSaga),
        fork(watchGetZonesCountSaga),
        fork(watchGetZonesShipmentsCostSaga)
    ]);
}

export default zoneAnalyticsSaga;
