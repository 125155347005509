// @flow
import { ViewOrderActionTypes } from './constants';

type ViewOrderAction = { type: string, payload: {} | string };

export const viewOrderApiResponseSuccess = (actionType: string, data: any): ViewOrderAction => ({
    type: ViewOrderActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const viewOrderApiResponseError = (actionType: string, error: string): ViewOrderAction => ({
    type: ViewOrderActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const viewOrderGetOrder = (orderId: string, returnLabel: Boolean, fullResponse: Boolean): LabelAction => ({
    type: ViewOrderActionTypes.GET_ORDER,
    payload: { orderId, returnLabel, fullResponse },
});

export const viewOrderGetOrderTrackingEvents = (orderId: string): LabelAction => ({
    type: ViewOrderActionTypes.GET_ORDER_TRACKING_EVENTS,
    payload: { orderId },
});

export const viewOrderResetState = (): ViewOrderAction => ({
    type: ViewOrderActionTypes.RESET_STATE,
    payload: {},
});