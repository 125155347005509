import { OrderBatchErrorsActionTypes } from './constants';

type OrderBatchErrorsAction = { type: string, payload: {} | string };

export const orderBatchErrorsApiResponseSuccess = (actionType: string, data: any): OrderBatchErrorsAction => ({
    type: OrderBatchErrorsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const orderBatchErrorsApiResponseError = (actionType: string, error: string): OrderBatchErrorsAction => ({
    type: OrderBatchErrorsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllOrderBatchErrors = (orderBatchId): OrderBatchErrorsAction => ({
    type: OrderBatchErrorsActionTypes.GET_ALL_ORDER_BATCH_ERRORS,
    payload: { orderBatchId },
});

export const orderBatchErrorsResetState = (): OrderBatchErrorsAction => ({
    type: OrderBatchErrorsActionTypes.RESET_STATE,
    payload: {},
});

export const orderBatchErrorsResetAllErrors = (): OrderBatchErrorsAction => ({
    type: OrderBatchErrorsActionTypes.RESET_ALL_ERRORS,
    payload: {},
});