export const AuthActionTypes = {
    API_RESPONSE_SUCCESS: '@@auth/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@auth/API_RESPONSE_ERROR',

    LOGIN_USER: '@@auth/LOGIN_USER',
    LOGIN_2FA: '@@auth/LOGIN_2FA',
    LOGOUT_USER: '@@auth/LOGOUT_USER',
    SIGNUP_USER: '@@auth/SIGNUP_USER',
    FORGOT_PASSWORD: '@@auth/FORGOT_PASSWORD',
    FORGOT_PASSWORD_CHANGE: '@@auth/FORGOT_PASSWORD_CHANGE',
    VERIFY_LOGIN: '@@auth/VERIFY_LOGIN',
    CREATE_2FA: '@@auth/CREATE_2FA',
    VERIFY_2FA: '@@auth/VERIFY_2FA',
    EDIT_2FA: '@@auth/EDIT_2FA',
    VERIFY_2FA_EDIT: '@@auth/VERIFY_2FA_EDIT',
    DELETE_2FA: '@@auth/DELETE_2FA',
    VERIFY_2FA_DELETION: '@@auth/VERIFY_2FA_DELETION',

    RESET_ALL_ERRORS: '@@auth/RESET_ALL_ERRORS',
    RESET: '@@auth/RESET',
};
