// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllAnalyticsRecords as getAllAnalyticsRecordsApi,
    getComparisonRatesTotalChargeAmount as getComparisonRatesTotalChargeAmountApi,
    getComparisonRatesAvgTotalChargeAmount as getComparisonRatesAvgTotalChargeAmountApi,
    launchRatingJob as launchRatingJobApi,
} from '../../helpers/';

import { analyticsRecordsApiResponseSuccess, analyticsRecordsApiResponseError } from './actions';
import { AnalyticsRecordsActionTypes } from './constants';
import { analyticsRecordResponseToAnalyticsRecordObject } from '../../helpers/mappers/analyticsRecord';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function* getAllAnalyticsRecords({ payload: { query } }) {
    try {
        const getAllAnalyticsRecordsResponse = yield call(getAllAnalyticsRecordsApi, query);
        const analyticsRecords = getAllAnalyticsRecordsResponse.data.map((analyticsRecordResponse) =>
            analyticsRecordResponseToAnalyticsRecordObject(analyticsRecordResponse)
        );
        const pagination = { ...paginationHeadersResponse(getAllAnalyticsRecordsResponse.headers), ...query };
        yield put(analyticsRecordsApiResponseSuccess(AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS, { analyticsRecords, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(analyticsRecordsApiResponseError(AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS, error));
    }
}

function* launchRatingJob({ payload: { integrationId } }) {
    try {
        yield put(addToast({ desc: 'Starting rating job', type: 'success' }));
        const launchRatingJobResponse = yield call(launchRatingJobApi, integrationId);
        yield put(addToast({ desc: 'Rating job completed', type: 'success' }));
        yield put(analyticsRecordsApiResponseSuccess(AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB, launchRatingJobResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(analyticsRecordsApiResponseError(AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB, error));
    }
}

export function* watchGetAllAnalyticsRecords(): any {
    yield takeLatest(AnalyticsRecordsActionTypes.GET_ALL_ANALYTICS_RECORDS, getAllAnalyticsRecords);
}

export function* watchLaunchRatingJob(): any {
    yield takeLatest(AnalyticsRecordsActionTypes.LAUNCH_RATING_JOB, launchRatingJob);
}

function* analyticsRecordsSaga(): any {
    yield all([
        fork(watchGetAllAnalyticsRecords),
        fork(watchLaunchRatingJob),
    ]);
}

export default analyticsRecordsSaga;
