import config from '../../config';
import { APICore } from './apiCore';

const api = new APICore();

function getProfile(userId) {
    const baseUrl = config.USERS_URL;
    return api.get(`${baseUrl}/${userId}`, {});
}

function updateProfile(userId, profile) {
    const baseUrl = config.USERS_URL;
    return api.update(`${baseUrl}/${userId}`, profile);
}

function getAllUsers() {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;
    return api.get(`${baseUrl}/${userId}/my-account`, {});
}

function updateUserEmail(email) {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;
    return api.updatePatch(`${baseUrl}/${userId}/email`, { email });
}

function updateUserEmailConfirmation(email, sid, code) {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;
    return api.updatePatch(`${baseUrl}/${userId}/email`, { email, code, sid });
}

export { getProfile, updateProfile, getAllUsers, updateUserEmail, updateUserEmailConfirmation };
