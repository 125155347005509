// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllAnalyticsRates as getAllAnalyticsRatesApi,
} from '../../helpers/';

import { analyticsRatesApiResponseSuccess, analyticsRatesApiResponseError } from './actions';
import { AnalyticsRatesActionTypes } from './constants';
import { analyticsRateResponseToAnalyticsRateObject } from '../../helpers/mappers/analyticsRate';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

function* getAllAnalyticsRates({ payload: { query } }) {
    try {
        const getAllAnalyticsRatesResponse = yield call(getAllAnalyticsRatesApi, query);
        const analyticsRates = getAllAnalyticsRatesResponse.data.map((analyticsRateResponse) =>
            analyticsRateResponseToAnalyticsRateObject(analyticsRateResponse)
        );
        const pagination = { ...paginationHeadersResponse(getAllAnalyticsRatesResponse.headers), ...query };
        yield put(analyticsRatesApiResponseSuccess(AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES, { analyticsRates, pagination }));
    } catch (error) {
        console.log('error', error);
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(analyticsRatesApiResponseError(AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES, error));
    }
}

export function* watchGetAllAnalyticsRates(): any {
    yield takeLatest(AnalyticsRatesActionTypes.GET_ALL_ANALYTICS_RATES, getAllAnalyticsRates);
}

function* analyticsRatesSaga(): any {
    yield all([fork(watchGetAllAnalyticsRates)]);
}

export default analyticsRatesSaga;
