import { ProfileActionTypes } from './constants';

const INIT_STATE = {
    profile: null,
    users: [],
    loading: false,
    error: null,
};

const Profile = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ProfileActionTypes.GET_PROFILE:
            return { ...state, loading: true };
        case ProfileActionTypes.UPDATE_PROFILE:
            return { ...state, loading: true };
        case ProfileActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case ProfileActionTypes.GET_PROFILE:
                    return { ...state, profile: action.payload.data, loading: false };
                case ProfileActionTypes.UPDATE_PROFILE:
                    return { ...state, profile: action.payload.data, loading: false };
                case ProfileActionTypes.GET_ALL_USERS:
                    return { ...state, users: action.payload.data, loading: false };
                default:
                    return { ...state };
            }
        case ProfileActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProfileActionTypes.GET_PROFILE:
                    return { ...state, error: action.payload.error, loading: false };
                case ProfileActionTypes.UPDATE_PROFILE:
                    return { ...state, error: action.payload.error, loading: false };
                case ProfileActionTypes.GET_ALL_USERS:
                    return { ...state, users: [], loading: false };
                default:
                    return { ...state };
            }
        case ProfileActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        case ProfileActionTypes.GET_ALL_USERS:
            return { ...state, users: [], loading: true };
        default:
            return { ...state };
    }
};

export default Profile;
