// @flow
import { AuthActionTypes } from './constants';

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const authApiResponseError = (actionType: string, error: string): AuthAction => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (name: string, email: string, password: string, login2FACallback: () => void): AuthAction => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password, login2FACallback },
});

export const login2FA = (payload: any): AuthAction => ({
    type: AuthActionTypes.LOGIN_2FA,
    payload: { ...payload },
});

export const logoutUser = (): AuthAction => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupUser = (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
    token: string,
    invitationId: string
): AuthAction => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: { email, password, firstName, lastName, token, invitationId },
});

export const forgotPassword = (username: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { username },
});

export const forgotPasswordChange = (username: string): AuthAction => ({
    type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
    payload: { username },
});

export const resetAuth = (): AuthAction => ({
    type: AuthActionTypes.RESET,
    payload: {},
});

export const resetAuthAllErrors = (): AuthAction => ({
    type: AuthActionTypes.RESET_ALL_ERRORS,
    payload: {},
});

export const verifyLogin = (
    email: string,
    password: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.VERIFY_LOGIN,
    payload: { email, password, callback, loadingCallback },
});

export const create2FA = (
    channel: string,
    target: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.CREATE_2FA,
    payload: { channel, target, callback, loadingCallback },
});

export const verify2FA = (
    channel: string,
    target: string,
    otp: string,
    sid: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.VERIFY_2FA,
    payload: { channel, target, otp, sid, callback, loadingCallback },
});

export const edit2FA = (
    channel: string,
    target: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.EDIT_2FA,
    payload: { channel, target, callback, loadingCallback },
});

export const verify2FAEdit = (
    channel: string,
    target: string,
    otp: string,
    sid: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.VERIFY_2FA_EDIT,
    payload: { channel, target, otp, sid, callback, loadingCallback },
});

export const delete2FA = (
    channel: string,
    target: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.DELETE_2FA,
    payload: { channel, target, callback, loadingCallback },
});

export const verify2FADeletion = (
    channel: string,
    target: string,
    otp: string,
    sid: string,
    callback: () => void,
    loadingCallback?: () => void
): AuthAction => ({
    type: AuthActionTypes.VERIFY_2FA_DELETION,
    payload: { channel, target, otp, sid, callback, loadingCallback },
});
