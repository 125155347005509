import { PackagesActionTypes } from './constants';

export const getPackages = (orderId) => {
    return {
        type: PackagesActionTypes.GET_PACKAGES,
        payload: {
            orderId
        }
     };
};

export const getPackage = (orderId, id) => {
    return {
        type: PackagesActionTypes.GET_PACKAGE,
        payload: {
            orderId,
            id,
        },
    };
};

export const createPackage = (orderId, data) => {
    return {
        type: PackagesActionTypes.CREATE_PACKAGE,
        payload: {
            orderId,
            data,
        },
    };
};

export const updatePackage = (orderId, data, fullResponse) => {
    return {
        type: PackagesActionTypes.UPDATE_PACKAGE,
        payload: {
            orderId,
            data,
            fullResponse,
        },
    };
};

export const removePackage = (orderId, id) => {
    return {
        type: PackagesActionTypes.REMOVE_PACKAGE,
        payload: {
            orderId,
            id,
        },
    };
};

export const packageApiResponseSuccess = (actionType, data) => {
    return {
        type: PackagesActionTypes.API_RESPONSE_SUCCESS,
        payload: { actionType, data },
    };
};

export const packageApiResponseError = (actionType, error) => {
    return {
        type: PackagesActionTypes.API_RESPONSE_ERROR,
        payload: { actionType, error },
    };
};

export const packageResetState = () => {
    return {
        type: PackagesActionTypes.RESET_STATE,
        payload: {},
    };
}

export const packageResetAllErrors = () => {
    return {
        type: PackagesActionTypes.RESET_ALL_ERRORS,
        payload: {},
    };
};