// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

function readAllAddresses(query): any {
    let baseUrl = config.ADDRESSBOOK_URL;

    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }

    return api.get(`${baseUrl}`, {});
}

function createAddress(addressRequest: any): any {
    const baseUrl = config.ADDRESSBOOK_URL;
    return api.create(`${baseUrl}`, addressRequest);
}

function updateAddress(addressId: string, addressRequest: any): any {
    const baseUrl = config.ADDRESSBOOK_URL + '/' + addressId;
    return api.update(`${baseUrl}`, addressRequest);
}

function readAddress(addressId: string): any {
    const baseUrl = config.ADDRESSBOOK_URL + '/' + addressId;
    return api.get(`${baseUrl}`, {});
}

function deleteAddress(addressId: string): any {
    const baseUrl = config.ADDRESSBOOK_URL + '/' + addressId;
    return api.delete(`${baseUrl}`, {});
}

function verifyAddress(addressRequest: any): any {
    const baseUrl = config.VERIFY_ADDRESS_URL;
    return api.create(`${baseUrl}`, addressRequest);
}

function readDefaultAddress(): any {
    const baseUrl = config.ADDRESSBOOK_URL + '/default';
    return api.get(`${baseUrl}`);
}

export {
    readAllAddresses,
    createAddress,
    updateAddress,
    readAddress,
    deleteAddress,
    verifyAddress,
    readDefaultAddress,
};
