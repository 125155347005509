// @flow
import { BillingActionTypes } from './constants';

import { APICore } from '../../helpers/api/apiCore';

const api = new APICore();

const INIT_STATE = {
    loading: false,
    paymentMethods: [],
};

type BillingAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { loading?: boolean, paymentMethods?: [] };

const Billing = (state: State = INIT_STATE, action: BillingAction): any => {
    switch (action.type) {
        case BillingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BillingActionTypes.LOAD_PAYMENT_METHODS:
                    return {
                        ...state,
                        loading: false,
                        paymentMethods: action.payload.data,
                    };
                default:
                    return { ...state };
            }

        case BillingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BillingActionTypes.LOAD_PAYMENT_METHODS:
                    return {
                        ...state,
                        loading: false,
                        error: action.payload.error,
                    };
                default:
                    return { ...state };
            }

        case BillingActionTypes.LOAD_PAYMENT_METHODS:
            return { ...state, loading: true, paymentMethods: [] };
        default:
            return { ...state };
    }
};

export default Billing;
