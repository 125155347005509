import { EditOrderActionTypes } from './constants';
import { OrderSpec } from '../types';

const INIT_STATE = {
    purchasedOrder: false,
    rates: [],
    loadingRates: false,
    error: null,
    loadingOrder: false,
    updatingOrder: false,
    updatedOrder: false,
    orderSpec: {
        fromAddress: {},
        toAddress: {},
        shipDate: null,
        warehouseId: null,
        service: null,
        shipmentOptions: {},
        returnsIndicator: null,
        package: {},
        items: [],
    },
};

type EditOrderAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = {
    purchasedOrder: Boolean,
    error?: null,
    loadingOrder: Boolean,
    updatingOrder: Boolean,
    updatedOrder: Boolean,
    rates: [],
    orderSpec?: OrderSpec,
};

const EditOrder = (state: State = INIT_STATE, action: EditOrderAction): any => {
    //console.log(action);
    switch (action.type) {
        case EditOrderActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EditOrderActionTypes.GET_ORDER: {
                    return { ...state, loadingOrder: false, orderSpec: action.payload.data, updatedOrder: false };
                }
                case EditOrderActionTypes.UPDATE_ORDER: {
                    return { ...state, updatingOrder: false, updatedOrder: true, order: action.payload.data };
                }
                case EditOrderActionTypes.GET_RATES: {
                    return { ...state, rates: action.payload.data, loadingRates: false };
                }
                case EditOrderActionTypes.PURCHASE_ORDER: {
                    return { ...state, purchasedOrder: true };
                }
                default:
                    return { ...state };
            }

        case EditOrderActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EditOrderActionTypes.GET_ORDER: {
                    return { ...state, loadingOrder: false, error: action.payload.error };
                }
                case EditOrderActionTypes.UPDATE_ORDER: {
                    return { ...state, updatingOrder: false, updatedOrder: false, error: action.payload.error };
                }
                case EditOrderActionTypes.GET_RATES: {
                    return { ...state, error: action.payload.error, loadingRates: false };
                }
                case EditOrderActionTypes.PURCHASE_ORDER: {
                    console.log('error', action.payload.error);
                    return { ...state, error: action.payload.error, purchasedOrder: false };
                }
                default:
                    return { ...state };
            }

        case EditOrderActionTypes.PURCHASE_ORDER:
            return { ...state, purchasedOrder: false };
        case EditOrderActionTypes.GET_RATES:
            return { ...state, loadingRates: true, rates: [] };
        case EditOrderActionTypes.RESET_RATES:
            return { ...state, rates: [] };
        case EditOrderActionTypes.GET_ORDER:
            return { ...state, loadingOrder: true, orderSpec: null };
        case EditOrderActionTypes.UPDATE_ORDER_SPEC:
            return { ...state, orderSpec: { ...state.orderSpec, ...action.payload.data } };
        case EditOrderActionTypes.RESET_ORDER_SPEC:
            return { ...state, orderSpec: { ...INIT_STATE.orderSpec } };
        case EditOrderActionTypes.UPDATE_ORDER:
            return { ...state, updatingOrder: true };
        case EditOrderActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        case EditOrderActionTypes.RESET_STATE:
            const x = INIT_STATE;
            x.orderSpec.items = [];
            return x;
        default:
            return { ...state };
    }
};

export default EditOrder;
