// @flow
import { APICore } from './apiCore';
import config from '../../config';
import { PAGINATION_DEFAULT_VALUE } from '../defaultValue';

const api = new APICore();

type Query = {
    currentPage: number,
    pageSize: number,
    search: string,
};

function getAllAnalyticsRecords(query: Query): any {
    const page = query?.currentPage - 1 || PAGINATION_DEFAULT_VALUE.currentPage - 1;
    const page_size = query?.pageSize || PAGINATION_DEFAULT_VALUE.pageSize;

    let baseUrl = config.ANALYTICS_RECORDS_URL;

    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }

    const queryParam = {
        page: page.toString(),
        page_size: page_size.toString(),
    };
    
    return api.get(`${baseUrl}`, typeof query === 'object' ? queryParam : undefined, {});
}

function launchRatingJob(integrationId: string): any {
    const baseUrl = config.ANALYTICS_RECORDS_URL + '/launch_rating_job';
    return api.create(`${baseUrl}?integrationId=${integrationId}`);
}

function getAllAnalyticsRatingErrors(query: Query): any {    
    const page = query?.currentPage - 1 || PAGINATION_DEFAULT_VALUE.currentPage - 1;
    const page_size = query?.pageSize || PAGINATION_DEFAULT_VALUE.pageSize;

    let baseUrl = `${config.ANALYTICS_RECORDS_URL}/analytics_rating_errors`;

    if (typeof query === 'string') {
        baseUrl += `?${query}`;
    }

    const queryParam = {
        page: page.toString(),
        page_size: page_size.toString(),
    };

    return api.get(`${baseUrl}`, typeof query === 'object' ? queryParam : undefined, {
        headers: { 'full-response': true },
    });
}

export { getAllAnalyticsRecords, launchRatingJob, getAllAnalyticsRatingErrors };
