const isEmpty = (obj) => {
    if (obj === undefined) {
        return true;
    }
    if (obj == null) {
        return true;
    }
    return Object.keys(obj).length === 0;
};

function formatStringAsDecimal(str, fallback = '') {
    let number = Number(str);

    if (isNaN(number)) {
        return fallback;
    }

    return number.toFixed(2);
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function formatNumber(value) {
    return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(value);
}

function camelToSnake(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
}

function snakeToCamel(str) {
    return str.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());
}

function convertObjectKeysToSnakeCase(obj) {
    const result = {};
    for (let key in obj) {
        result[camelToSnake(key)] = obj[key];
    }
    return result;
}

function convertObjectKeysToCamelCase(obj) {
    const result = {};
    for (let key in obj) {
        result[snakeToCamel(key)] = obj[key];
    }
    return result;
}

const copyToClipboard = async (text) => {
    try {
        if (text) {
            await navigator.clipboard.writeText(text);
        }
    } catch (error) {
        console.log('error', error);
    }
};

function filterMenuItems(menuItems, removeKeys) {
    // Function to remove children items
    const removeChildren = (children) => {
        return children.filter((child) => !removeKeys.includes(child.key));
    };

    // Remove from the main menu items
    return menuItems
        .map((item) => {
            // Check if the item has children
            if (item.children && item.children.length > 0) {
                const filteredChildren = removeChildren(item.children);
                // Return the item with filtered children, or null if all children are removed
                return filteredChildren.length > 0 ? { ...item, children: filteredChildren } : null;
            }
            return item;
        })
        .filter((item) => item !== null && !removeKeys.includes(item.key)); // Filter out null items and items in removeKeys
}

function getCurrentSearchParam(searchParams, arrayFields) {
    if (searchParams.size === 0) {
        return {};
    }

    let data = {};

    for (const [key, value] of searchParams.entries()) {
        if (data[key]) {
            if (Array.isArray(data[key])) {
                data[key] = [...data[key], value];
            } else {
                data[key] = [data[key], value];
            }
        } else {
            if (arrayFields.includes(key)) {
                data[key] = [value];
            } else {
                data[key] = value;
            }
        }
    }

    return data;
}

export {
    getCurrentSearchParam,
    filterMenuItems,
    isEmpty,
    camelToSnake,
    snakeToCamel,
    formatStringAsDecimal,
    capitalizeFirstLetter,
    formatNumber,
    copyToClipboard,
    convertObjectKeysToSnakeCase,
    convertObjectKeysToCamelCase,
};
