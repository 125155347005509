import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import { createIntegrationAndStartOAuth as createIntegrationAndStartOAuthApi, } from '../../helpers';
import { shopifyApiResponseSuccess, shopifyApiResponseError, } from './actions';
import { ShopifyActionTypes } from './constants';
import { addToast } from '../actions';

export function* createIntegrationAndStartOAuthSaga({ payload: { shopifyIntegration } }) {
    try {
        const response = yield call(createIntegrationAndStartOAuthApi, shopifyIntegration);
        if (response.redirected) {
            console.log('redirecting to', response.url);
            window.location.href = response.url;
        } else {
            //TODO: this one should probably be a failure
            yield put(shopifyApiResponseSuccess(ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST));
        }
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(shopifyApiResponseError(ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST, error));
    }
}

export function* watchCreateIntegrationAndStartOAuthSaga(): any {
    yield takeLatest(ShopifyActionTypes.CREATE_INTEGRATION_AND_START_OAUTH_REQUEST, createIntegrationAndStartOAuthSaga);
}

function* shopifySaga() {
    yield all([
        fork(watchCreateIntegrationAndStartOAuthSaga),
    ]);
}

export default shopifySaga;
