// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    getAllLabelSettings as getAllLabelSettingsApi,
    updateLabelSettings as updateLabelSettingsApi,
} from '../../helpers/';

import {
    labelsDocsSettingsApiResponseSuccess,
    labelsDocsSettingsApiResponseError,
    labelsDocsSettingsGetLabelsDocsSettings,
} from './actions';
import { LabelsDocsSettingsActionTypes } from './constants';
import { addToast } from '../actions';
import {
    labelSettingsEntryResponseTolabelSettingsEntryObject,
    labelSettingsEntryObjectTolabelSettingsEntryRequest,
} from '../../helpers/mappers/labelSettings';

function* getAllLabelSettings() {
    try {
        const getLabelSettingsResponse = yield call(getAllLabelSettingsApi);
        const labelSettings = getLabelSettingsResponse.data.map(labelSettingsEntryResponseTolabelSettingsEntryObject);

        yield put(
            labelsDocsSettingsApiResponseSuccess(LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS, labelSettings)
        );
    } catch (error) {
        yield put(labelsDocsSettingsApiResponseError(LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS, error));
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

function* updateLabelSettings({ payload: { labelSettingId, data } }) {
    try {
        const getLabelSettingsResponse = yield call(
            updateLabelSettingsApi,
            labelSettingId,
            labelSettingsEntryObjectTolabelSettingsEntryRequest(data)
        );
        yield put(labelsDocsSettingsGetLabelsDocsSettings());
    } catch (error) {
        yield put(labelsDocsSettingsApiResponseError(LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS, error));
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    }
}

export function* watchGetAllLabelSettings(): any {
    yield takeLatest(LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS, getAllLabelSettings);
}

export function* watchUpdateLabelSettings(): any {
    yield takeLatest(LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS, updateLabelSettings);
}

function* labelSettingsSaga(): any {
    yield all([fork(watchGetAllLabelSettings)]);
    yield all([fork(watchUpdateLabelSettings)]);
}

export default labelSettingsSaga;
