import { AnalyticsRatingErrorsActionTypes } from './constants';

type AnalyticsRatingErrorsAction = { type: string, payload: {} | string };

export const analyticsRatingErrorsApiResponseSuccess = (actionType: string, data: any): AnalyticsRatingErrorsAction => ({
    type: AnalyticsRatingErrorsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const analyticsRatingErrorsApiResponseError = (actionType: string, error: string): AnalyticsRatingErrorsAction => ({
    type: AnalyticsRatingErrorsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAllAnalyticsRatingErrors = (query): AnalyticsRatingErrorsAction => ({
    type: AnalyticsRatingErrorsActionTypes.GET_ALL_ANALYTICS_RATING_ERRORS,
    payload: { query },
});

export const analyticsRatingErrorsResetState = (): AnalyticsRatingErrorsAction => ({
    type: AnalyticsRatingErrorsActionTypes.RESET_STATE,
    payload: {},
});

export const analyticsRatingErrorsResetAllErrors = (): AnalyticsRatingErrorsAction => ({
    type: AnalyticsRatingErrorsActionTypes.RESET_ALL_ERRORS,
    payload: {},
});