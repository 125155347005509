import { AutomationActionTypes } from './constants';

const INIT_STATE = {
    automation: [],
    automationLoading: false,
    error: null,
};

type AutomationAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};

type State = { automation?: [] };

const Automation = (state: State = INIT_STATE, action: AutomationAction): any => {
    switch (action.type) {
        case AutomationActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AutomationActionTypes.GET_AUTOMATION: {
                    return { ...state, automation: action.payload.data, automationLoading: false };
                }

                default:
                    return { ...state };
            }

        case AutomationActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AutomationActionTypes.GET_RATES: {
                    return { ...state, error: action.payload.error, automationLoading: false };
                }
                default:
                    return { ...state };
            }

        case AutomationActionTypes.GET_AUTOMATION:
            return { ...state, automationLoading: true, error: null, automation: [] };

        default:
            return { ...state };
    }
};

export default Automation;
