// @flow

import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
    getPackagesSettings as getPackagesApi,
    createPackageSettings as createPackageApi,
    updatePackageSettings as updatePackageApi,
    deletePackageSettings as deletePackageApi,
} from '../../helpers';
import { getPackagesSettings, packageSettingsApiResponseError, packageSettingsApiResponseSuccess } from './actions';
import { PackageSettingsActionTypes } from './constants';
import { addToast } from '../actions';
import { packageObjectToPackageRequest } from '../../helpers/mappers/misc';

function* getPackagesSettingsSaga({ payload: {} }) {
    try {
        const packagesResponse = yield call(getPackagesApi);
        const packagesSettings = packagesResponse.data;
        yield put(packageSettingsApiResponseSuccess(PackageSettingsActionTypes.GET_PACKAGES, packagesSettings));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageSettingsApiResponseError(PackageSettingsActionTypes.GET_PACKAGES, error));
    }
}

function* deletePackagesSettingsSaga({ payload: { id } }) {
    try {
        const packagesResponse = yield call(deletePackageApi, id);
        yield put(getPackagesSettings());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageSettingsApiResponseError(PackageSettingsActionTypes.REMOVE_PACKAGE, error));
    }
}

function* createPackageSettingsSaga({ payload: { data, fullResponse } }) {
    try {
        const packageResponse = yield call(createPackageApi, data, fullResponse);
        const singlePackage = packageResponse.data;
        yield put(packageSettingsApiResponseSuccess(PackageSettingsActionTypes.CREATE_PACKAGE, singlePackage));
        yield put(getPackagesSettings());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageSettingsApiResponseError(PackageSettingsActionTypes.CREATE_PACKAGE, error));
    }
}

function* updatePackageSettingsSaga({ payload: { data } }) {
    try {
        const updatePackageResponse = yield call(updatePackageApi, data);
        const singlePackage = updatePackageResponse.data;
        yield put(packageSettingsApiResponseSuccess(PackageSettingsActionTypes.UPDATE_PACKAGE, singlePackage));
        yield put(getPackagesSettings());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageSettingsApiResponseError(PackageSettingsActionTypes.UPDATE_PACKAGE, error));
    }
}

function* removePackageSettingsSaga({ payload: { id } }) {
    try {
        const packageResponse = yield call(deletePackageApi, id);
        const singlePackage = packageResponse.data;
        yield put(packageSettingsApiResponseSuccess(PackageSettingsActionTypes.REMOVE_PACKAGE, singlePackage));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(packageSettingsApiResponseError(PackageSettingsActionTypes.REMOVE_PACKAGE, error));
    } finally {
        yield put(getPackagesSettings());
    }
}

export function* watchCreatePackageSettings(): any {
    yield takeLatest(PackageSettingsActionTypes.CREATE_PACKAGE, createPackageSettingsSaga);
}

export function* watchUpdatePackageSettings(): any {
    yield takeLatest(PackageSettingsActionTypes.UPDATE_PACKAGE, updatePackageSettingsSaga);
}

export function* watchRemovePackageSettings(): any {
    yield takeLatest(PackageSettingsActionTypes.REMOVE_PACKAGE, removePackageSettingsSaga);
}

export function* watchGetPackagesSettings(): any {
    yield takeLatest(PackageSettingsActionTypes.GET_PACKAGES, getPackagesSettingsSaga);
}

function* packageSettingsSaga(): any {
    yield all([
        fork(watchCreatePackageSettings),
        fork(watchUpdatePackageSettings),
        fork(watchRemovePackageSettings),
        fork(watchGetPackagesSettings),
    ]);
}

export default packageSettingsSaga;
