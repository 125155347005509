import { OrderAnalyticsActionTypes } from './constants';

type OrderAnalyticsAction = { type: string, payload: {} | string };

export const orderAnalyticsApiResponseSuccess = (actionType: string, data: any): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const orderAnalyticsApiResponseError = (actionType: string, error: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const orderAnalyticsGetNoCustomers = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_NO_CUSTOMERS_REQUEST,
    payload: { query },
});

export const orderAnalyticsGetNoOrders = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_NO_ORDERS_REQUEST,
    payload: { query },
});

export const orderAnalyticsGetTotalOrderRevenue = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_TOTAL_ORDER_REVENUE_REQUEST,
    payload: { query },
});

export const orderAnalyticsGetDailyRevenue = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_DAILY_REVENUE_REQUEST,
    payload: { query },
});

export const orderAnalyticsGetTopCustomers = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_TOP_CUSTOMERS_REQUEST,
    payload: { query },
});

export const orderAnalyticsGetTopProducts = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_TOP_PRODUCTS_REQUEST,
    payload: { query },
});

export const orderAnalyticsGetTopSources = (query: string): OrderAnalyticsAction => ({
    type: OrderAnalyticsActionTypes.GET_TOP_SOURCES_REQUEST,
    payload: { query },
});