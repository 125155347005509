export const IntegrationsActionTypes = {
    API_RESPONSE_SUCCESS: '@@integrations/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@integrations/API_RESPONSE_ERROR',
    CREATE_CSV_INTEGRATION: '@@integrations/CREATE_CSV_INTEGRATION',
    GET_CSV_MAPPING: '@@integrations/GET_CSV_MAPPING',
    GET_ALL_INTEGRATIONS: '@@integrations/GET_ALL_INTEGRATIONS',
    GET_ALL_TEMPLATES: '@@integrations/GET_ALL_TEMPLATES',
    CREATE_TEMPLATE: '@@integrations/CREATE_TEMPLATE',
    EXPORT_TEMPLATE: '@@integrations/EXPORT_TEMPLATE',
    // Integration API key
    GET_INTEGRATION_APIS: '@@integrations/GET_INTEGRATION_API',
    CREATE_INTEGRATION_API: '@@integrations/CREATE_INTEGRATION_API',
    UPDATE_INTEGRATION_API: '@@integrations/UPDATE_INTEGRATION_API',

    RESET_ALL_ERRORS: '@@integrations/RESET_ALL_ERRORS',
};
