// @flow
import { BillingActionTypes } from './constants';

type BillingAction = { type: string, payload: {} | string };

export class BillingActions {
  // common success
  static apiResponseSuccess(actionType: string, data: any): BillingModalAction {
    return {
      type: BillingActionTypes.API_RESPONSE_SUCCESS,
      payload: { actionType, data },
    };
  }
  
  // common error
  static apiResponseError(actionType: string, error: string): BillingModalAction {
    return {
      type: BillingActionTypes.API_RESPONSE_ERROR,
      payload: { actionType, error },
    };
  }
  
  static loadPaymentMethods(): BillingModalAction {
    return {
      type: BillingActionTypes.LOAD_PAYMENT_METHODS,
      payload: {},
    };
  }
}
