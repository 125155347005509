// @flow

import { LabelsDocsSettingsActionTypes } from './constants';

type LabelAndDocsDataType = Array<{
    type: string,
    size: {
        width: number,
        height: number,
        unit: string,
    },
    fileFormat: {
        label: string,
        value: string,
    },
    printOption: string,
}>;

type LabelAndDocsSettingsAction = {
    type: string,
    payload: {
        actionType?: string,
        data: any,
        error?: string,
    },
};

type State = {
    labelAndDocsData: LabelAndDocsDataType | null,
    error?: null,
};

const initialState: State = {
    labelAndDocsData: null,
    loadingLabelAndDocsData: false,
    error: null,
};

const LabelAndDocsSettings = (state: State = initialState, action: LabelAndDocsSettingsAction): any => {
    switch (action.type) {
        case LabelsDocsSettingsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS: {
                    return { ...state, labelAndDocsData: action.payload.data, loadingLabelAndDocsData: false };
                }
                case LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS: {
                    return { ...state, labelAndDocsData: action.payload.data };
                }
                default:
                    return { ...state };
            }

        case LabelsDocsSettingsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS: {
                    return { ...state, error: action.payload.error, loadingLabelAndDocsData: false };
                }
                case LabelsDocsSettingsActionTypes.UPDATE_LABELS_DOCS_SETTINGS: {
                    return { ...state, error: action.payload.error };
                }

                default:
                    return { ...state };
            }

        case LabelsDocsSettingsActionTypes.GET_LABELS_DOCS_SETTINGS:
            return { ...state, labelAndDocsData: null, loadingLabelAndDocsData: true };

        default:
            return { ...state };
    }
};

export default LabelAndDocsSettings;
