import { all, fork, put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
    getInvoiceAnalyticsNoShipments as getInvoiceAnalyticsNoShipmentsApi,
    getInvoiceAnalyticsTopZone as getInvoiceAnalyticsTopZoneApi,
    getInvoiceAnalyticsTopWeight as getInvoiceAnalyticsTopWeightApi,
    getInvoiceAnalyticsCost as getInvoiceAnalyticsCostApi,
    getInvoiceAnalyticsAverageCost as getInvoiceAnalyticsAverageCostApi,
    getComparisonAnalyticsRecords as getAllAnalyticsRecordsApi,
    getComparisonCost as getComparisonRatesTotalChargeAmountApi,
    getComparisonAvgCost as getComparisonRatesAvgTotalChargeAmountApi,
} from '../../helpers';
import { invoiceAnalyticsApiResponseSuccess, invoiceAnalyticsApiResponseError, } from './actions';
import { InvoiceAnalyticsActionTypes } from './constants';
import { analyticsRecordResponseToAnalyticsRecordObject } from '../../helpers/mappers/analyticsRecord';
import { addToast } from '../actions';
import { paginationHeadersResponse } from '../../helpers/mappers/pagination';

export function* getInvoiceAnalyticsNoShipments({ payload: { query } }) {
    try {
        console.log('query', query);
        const response = yield call(getInvoiceAnalyticsNoShipmentsApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsTopZoneSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsTopZoneApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsTopWeightSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsTopWeightApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsCostApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COST_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COST_REQUEST, error));
    }
}

export function* getInvoiceAnalyticsAverageCostSaga({ payload: { query } }) {
    try {
        const response = yield call(getInvoiceAnalyticsAverageCostApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST, response.data));
    } catch (error) {
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST, error));
    }
}

function* getComparisonAnalyticsRecords({ payload: { query } }) {
    try {
        const getAllAnalyticsRecordsResponse = yield call(getAllAnalyticsRecordsApi, query);
        const analyticsRecords = getAllAnalyticsRecordsResponse.data.map((analyticsRecordResponse) =>
            analyticsRecordResponseToAnalyticsRecordObject(analyticsRecordResponse)
        );
        const pagination = { ...paginationHeadersResponse(getAllAnalyticsRecordsResponse.headers), ...query };
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS, { analyticsRecords, pagination }));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS, error));
    }
}

function* getComparisonRatesTotalChargeAmount({ payload: { query } }) {
    try {
        const getComparisonRatesTotalChargeAmountResponse = yield call(getComparisonRatesTotalChargeAmountApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT, getComparisonRatesTotalChargeAmountResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT, error));
    }
}

function* getComparisonRatesAvgTotalChargeAmount({ payload: { query } }) {
    try {
        const getComparisonRatesAvgTotalChargeAmountResponse = yield call(getComparisonRatesAvgTotalChargeAmountApi, query);
        yield put(invoiceAnalyticsApiResponseSuccess(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT, getComparisonRatesAvgTotalChargeAmountResponse.data));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invoiceAnalyticsApiResponseError(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT, error));
    }
}

export function* watchGetInvoiceAnalyticsNoShipments(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_NO_SHIPMENTS_REQUEST, getInvoiceAnalyticsNoShipments);
}

export function* watchGetInvoiceAnalyticsTopZone(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOP_ZONE_REQUEST, getInvoiceAnalyticsTopZoneSaga);
}

export function* watchGetInvoiceAnalyticsTopWeight(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_TOP_WEIGHT_REQUEST, getInvoiceAnalyticsTopWeightSaga);
}

export function* watchGetInvoiceAnalyticsCost(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COST_REQUEST, getInvoiceAnalyticsCostSaga);
}

export function* watchGetInvoiceAnalyticsAverageCost(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_AVG_COST_REQUEST, getInvoiceAnalyticsAverageCostSaga);
}

export function* watchGetComparisonAnalyticsRecords(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COMPARISON_ANALYTICS_RECORDS, getComparisonAnalyticsRecords);
}

export function* watchGetComparisonRatesTotalChargeAmount(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_TOTAL_CHARGE_AMOUNT, getComparisonRatesTotalChargeAmount);
}

export function* watchGetComparisonRatesAvgTotalChargeAmount(): any {
    yield takeLatest(InvoiceAnalyticsActionTypes.GET_COMPARISON_RATES_AVG_TOTAL_CHARGE_AMOUNT, getComparisonRatesAvgTotalChargeAmount);
}

function* invoiceAnalyticsSaga() {
    yield all([
        fork(watchGetInvoiceAnalyticsNoShipments),
        fork(watchGetInvoiceAnalyticsTopZone),
        fork(watchGetInvoiceAnalyticsTopWeight),
        fork(watchGetInvoiceAnalyticsCost),
        fork(watchGetInvoiceAnalyticsAverageCost),
        fork(watchGetComparisonAnalyticsRecords),
        fork(watchGetComparisonRatesTotalChargeAmount),
        fork(watchGetComparisonRatesAvgTotalChargeAmount),
    ]);
}

export default invoiceAnalyticsSaga;
