// @flow
import { all, fork, put, takeLatest, call, join, select, delay } from 'redux-saga/effects';

import { getRates as getRatesApi, createOrder as createOrderApi } from '../../helpers/';
import { orderObjectToOrderRequest, orderResponseToOrderObject } from '../../helpers/mappers/order';

import { rateObjectToRateRequest, ratesResponseToRateObject } from '../../helpers/mappers/rate';

import { newOrderApiResponseSuccess, newOrderApiResponseError } from './actions';
import { NewOrderActionTypes } from './constants';
import { addToast, ordersGetAllOrders, removeLocalStorageItem, setLocalStorageItem } from '../actions';

function* getRates({ payload: { orderSpec, fullResponse } }) {
    try {
        const ratesRequest = rateObjectToRateRequest(orderSpec);
        const ratesResponse = yield call(getRatesApi, ratesRequest, fullResponse);
        const rates = ratesResponseToRateObject(ratesResponse.data);
        yield put(newOrderApiResponseSuccess(NewOrderActionTypes.GET_RATES, rates));
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(newOrderApiResponseError(NewOrderActionTypes.GET_RATES, error));
    }
}

function* createOrder({ payload: { orderSpec, returnLabel, fullResponse } }) {
    try {
        const request = orderObjectToOrderRequest(orderSpec);
        const createOrderResponse = yield call(createOrderApi, request, returnLabel, fullResponse);
        // const { orderPagination } = yield select((state) => ({ orderPagination: state.Orders.pagination }));
        yield put(newOrderApiResponseSuccess(NewOrderActionTypes.CREATE_ORDER, createOrderResponse.data));
        yield put(removeLocalStorageItem('packageItems'));
        // yield put(
        //     ordersGetAllOrders(false, true, {
        //         page: orderPagination.currentPage,
        //         pageSize: orderPagination.pageSize,
        //     })
        // );
        
        // there is something about toasts and redirects that makes the toast not show up
        // so they had to be moved from the saga to the component; they should work for the error case
        //yield put(addToast({ desc: 'Order created successfully', type: 'success' }));
    } catch (error) {
        yield put(addToast({ desc: error.errors?.[0]?.message, type: 'error' }));
        yield put(newOrderApiResponseError(NewOrderActionTypes.CREATE_ORDER, error));
    }
}

export function* watchGetRates(): any {
    yield takeLatest(NewOrderActionTypes.GET_RATES, getRates);
}

export function* watchCreateOrder(): any {
    yield takeLatest(NewOrderActionTypes.CREATE_ORDER, createOrder);
}

function* newOrderSaga(): any {
    yield all([
        //fork(watchGetAllPrices),
        fork(watchGetRates),
        fork(watchCreateOrder),
    ]);
}

export default newOrderSaga;
