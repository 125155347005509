export const PackagesActionTypes = {
    API_RESPONSE_SUCCESS: '@@package/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@package/API_RESPONSE_ERROR',

    GET_PACKAGES: '@@package/GET_PACKAGES',

    GET_PACKAGE: '@@package/GET_PACKAGE',

    CREATE_PACKAGE: '@@package/CREATE_PACKAGE',

    UPDATE_PACKAGE: '@@package/UPDATE_PACKAGE',

    REMOVE_PACKAGE: '@@package/REMOVE_PACKAGE',

    RESET_STATE: '@@package/RESET_STATE',

    RESET_ALL_ERRORS: '@@package/RESET_ALL_ERRORS',
};
