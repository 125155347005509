// @flow
import { APICore } from './apiCore';
import config from '../../config';

const api = new APICore();

const getPackagesSettings = () => {
    const baseUrl = config.USERS_URL;
    const userId = api.getLoggedInUser().id;
    return api.get(`${baseUrl}/${userId}/package_settings`);
};

const createPackageSettings = (packageData: any) => {
    const userId = api.getLoggedInUser().id;
    const baseUrl = config.USERS_URL;
    return api.create(`${baseUrl}/${userId}/package_settings`, packageData);
};

const updatePackageSettings = (packageItemData: any) => {
    const userId = api.getLoggedInUser().id;
    const baseUrl = config.USERS_URL;
    return api.update(`${baseUrl}/${userId}/package_settings/${packageItemData.id}`, packageItemData);
};

const deletePackageSettings = (packageSettingsId: string) => {
    const userId = api.getLoggedInUser().id;
    const baseUrl = config.USERS_URL;
    return api.delete(`${baseUrl}/${userId}/package_settings/${packageSettingsId}`);
};

export { getPackagesSettings, createPackageSettings, updatePackageSettings, deletePackageSettings };
