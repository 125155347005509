import { PackagesActionTypes } from './constants';

const INITIAL_STATE = {
    packages: [],
    loading: false,
    error: null,
    package: {},
    creating: false,
    created: false,
    createdPackageId: null,
};

const Packages = (state = INITIAL_STATE, action) => {
    //console.log(action);
    switch (action.type) {
        case PackagesActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case PackagesActionTypes.GET_PACKAGES: {
                    console.log('packages', action.payload.data);
                    return { ...state, packages: action.payload.data, loading: false };
                }
                case PackagesActionTypes.GET_PACKAGE: {
                    console.log('package', action.payload.data);
                    return { ...state, package: action.payload.data, loading: false };
                }
                case PackagesActionTypes.CREATE_PACKAGE: {
                    return { ...state, creating: false, created: true, createdPackageId: action.payload.data };
                }
                case PackagesActionTypes.UPDATE_PACKAGE: {
                    return { ...state, creating: false, created: true, createdPackageId: action.payload.data };
                }
                case PackagesActionTypes.REMOVE_PACKAGE: {
                    return { ...state, creating: false, created: true, createdPackageId: action.payload.data };
                }
                default:
                    return { ...state };
            }

        case PackagesActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case PackagesActionTypes.GET_PACKAGES: {
                    return { ...state, error: action.payload.error, loading: false };
                }
                case PackagesActionTypes.GET_PACKAGE: {
                    return { ...state, error: action.payload.error, loading: false };
                }
                case PackagesActionTypes.CREATE_PACKAGE: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                case PackagesActionTypes.UPDATE_PACKAGE: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                case PackagesActionTypes.REMOVE_PACKAGE: {
                    return { ...state, creating: false, created: false, error: action.payload.error };
                }
                default:
                    return { ...state };
            }

        case PackagesActionTypes.GET_PACKAGES:
            return { ...state, loading: true, packages: [] };
        case PackagesActionTypes.GET_PACKAGE:
            return { ...state, loading: true, package: {} };
        case PackagesActionTypes.CREATE_PACKAGE:
            return { ...state, creating: true, created: false };
        case PackagesActionTypes.UPDATE_PACKAGE:
            return { ...state, creating: true, created: false };
        case PackagesActionTypes.REMOVE_PACKAGE:
            return { ...state, creating: true, created: false };
        case PackagesActionTypes.RESET_STATE:
            const x = INITIAL_STATE;
            x.packages = [];
            return x;
        case PackagesActionTypes.RESET_ALL_ERRORS:
            return { ...state, error: null };
        default:
            return { ...state };
    }
};

export default Packages;
