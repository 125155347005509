// @flow
import React, { useState } from 'react';
import { Dropdown, Form, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { NumericFormat } from 'react-number-format';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import PopoverTooltip from '../components/PopoverTooltip';

/* Password Input */
const PasswordInput = ({ name, placeholder, refCallback, errors, register, className, showIcon, otherProps }) => {
    const [showPassword, setShowPassword] = useState(false);

    return (
        <>
            <InputGroup className="mb-0">
                <Form.Control
                    type={showPassword ? 'text' : 'password'}
                    placeholder={placeholder}
                    name={name}
                    id={name}
                    as="input"
                    ref={(r) => {
                        if (refCallback) refCallback(r);
                    }}
                    className={className}
                    isInvalid={errors && errors[name] ? true : false}
                    {...(register ? register(name) : {})}
                    autoComplete={name}
                    {...otherProps}
                />
                {showIcon && (
                    <div
                        className={classNames('input-group-text', 'input-group-password', {
                            'show-password': showPassword,
                        })}
                        data-password={showPassword ? 'true' : 'false'}>
                        <span
                            className="password-eye"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}></span>
                    </div>
                )}
            </InputGroup>
        </>
    );
};

type FormInputProps = {
    label?: string,
    type?: string,
    name?: string,
    placeholder?: string,
    tooltipLabel?: string,
    register?: any,
    errors?: any,
    className?: string,
    labelClassName?: string,
    containerClass?: string,
    refCallback?: any,
    children?: any,
    showIcon?: boolean,
    onChange?: any,
    value?: any,
    thousandSeparator?: any,
    decimalScale?: any,
    fixedDecimalScale?: any,
    defaultValue?: boolean,
    isDropdownCountryDisable?: Boolean,
    disabled?: Boolean,
    onChange?: (value: any) => void,
    handleSuggestionClick?: (suggestion) => void,
    autocompleteSuggestions?: any[],
    isAutoComplete?: boolean,
};

const FormInput = ({
    label,
    type,
    name,
    placeholder,
    register,
    errors,
    className,
    labelClassName,
    containerClass,
    refCallback,
    tooltipLabel,
    showIcon = true,
    children,
    defaultValue,
    isDropdownCountryDisable,
    handleSuggestionClick,
    autocompleteSuggestions,
    isAutoComplete = false,
    ...otherProps
}: FormInputProps): React$Element<React$FragmentType> => {
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    // handle input type
    const comp = type === 'textarea' ? 'textarea' : type === 'select' ? 'select' : 'input';

    return (
        <>
            {type === 'hidden' ? (
                <input type={type} name={name} {...(register ? register(name) : {})} {...otherProps} />
            ) : (
                <>
                    {type === 'password' ? (
                        <>
                            <Form.Group className={containerClass}>
                                {label ? (
                                    <>
                                        <Form.Label htmlFor={name} className={labelClassName}>
                                            {label}
                                        </Form.Label>
                                        {tooltipLabel && (
                                            <PopoverTooltip message={tooltipLabel}>
                                                <i
                                                    className="mdi mdi-information-outline ms-1"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-title={tooltipLabel}></i>
                                            </PopoverTooltip>
                                        )}
                                    </>
                                ) : null}
                                <PasswordInput
                                    name={name}
                                    placeholder={placeholder}
                                    refCallback={refCallback}
                                    errors={errors}
                                    register={register}
                                    className={className}
                                    showIcon={showIcon}
                                    otherProps={otherProps}
                                />
                                {errors && errors[name] ? (
                                    <Form.Control.Feedback type="invalid" className="d-block">
                                        {errors[name]['message']}
                                    </Form.Control.Feedback>
                                ) : null}
                            </Form.Group>
                        </>
                    ) : (
                        <>
                            {type === 'select' ? (
                                <>
                                    <Form.Group className={containerClass}>
                                        {label ? (
                                            <>
                                                <Form.Label htmlFor={name} className={labelClassName}>
                                                    {label}
                                                </Form.Label>
                                                {tooltipLabel && (
                                                    <PopoverTooltip message={tooltipLabel}>
                                                        <i
                                                            className="mdi mdi-information-outline ms-1"
                                                            data-bs-toggle="tooltip"
                                                            data-bs-title={tooltipLabel}></i>
                                                    </PopoverTooltip>
                                                )}
                                            </>
                                        ) : null}
                                        <Form.Select
                                            type={type}
                                            label={label}
                                            name={name}
                                            id={name}
                                            ref={(r) => {
                                                if (refCallback) refCallback(r);
                                            }}
                                            comp={comp}
                                            className={className}
                                            isInvalid={errors && errors[name] ? true : false}
                                            {...(register ? register(name) : {})}
                                            {...otherProps}>
                                            {children}
                                        </Form.Select>

                                        {errors && errors[name] ? (
                                            <Form.Control.Feedback type="invalid">
                                                {errors[name]['message']}
                                            </Form.Control.Feedback>
                                        ) : null}
                                    </Form.Group>
                                </>
                            ) : (
                                <>
                                    {type === 'checkbox' || type === 'radio' ? (
                                        <>
                                            <Form.Group className={containerClass}>
                                                <Form.Check
                                                    type={type}
                                                    label={label}
                                                    name={name}
                                                    id={name}
                                                    ref={(r) => {
                                                        if (refCallback) refCallback(r);
                                                    }}
                                                    className={className}
                                                    isInvalid={errors && errors[name] ? true : false}
                                                    defaultChecked={defaultValue}
                                                    {...(register ? register(name) : {})}
                                                    {...otherProps}
                                                />
                                                {errors && errors[name] ? (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors[name]['message']}
                                                    </Form.Control.Feedback>
                                                ) : null}
                                            </Form.Group>
                                        </>
                                    ) : type === 'number' ? (
                                        <Form.Group className={containerClass}>
                                            {label ? (
                                                <>
                                                    <Form.Label htmlFor={name} className={labelClassName}>
                                                        {label}
                                                    </Form.Label>
                                                    {tooltipLabel && (
                                                        <PopoverTooltip message={tooltipLabel}>
                                                            <i
                                                                className="mdi mdi-information-outline ms-1"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title={tooltipLabel}></i>
                                                        </PopoverTooltip>
                                                    )}
                                                </>
                                            ) : null}
                                            <NumericFormat
                                                name={name}
                                                onValueChange={(values) =>
                                                    otherProps.onChange
                                                        ? otherProps.onChange({
                                                              target: {
                                                                  value: values.value,
                                                              },
                                                          })
                                                        : null
                                                }
                                                thousandSeparator={otherProps?.thousandSeparator || true}
                                                decimalScale={otherProps?.decimalScale ?? 2}
                                                fixedDecimalScale={otherProps?.fixedDecimalScale || true}
                                                placeholder={placeholder}
                                                isnumericstring="true"
                                                customInput={Form.Control}
                                                value={otherProps?.value ? otherProps.value : null}
                                                disabled={otherProps?.disabled ? otherProps.disabled : null}
                                            />
                                            {errors && errors[name] ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[name]['message']}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    ) : type === 'phone' ? (
                                        <Form.Group className={containerClass}>
                                            {label ? (
                                                <>
                                                    <Form.Label htmlFor={name} className={labelClassName}>
                                                        {label}
                                                    </Form.Label>
                                                    {tooltipLabel && (
                                                        <PopoverTooltip message={tooltipLabel}>
                                                            <i
                                                                className="mdi mdi-information-outline ms-1"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title={tooltipLabel}></i>
                                                        </PopoverTooltip>
                                                    )}
                                                </>
                                            ) : null}

                                            <PhoneInput
                                                onFocus={() => setIsDropdownShow(true)}
                                                onBlur={() =>
                                                    setTimeout(() => {
                                                        setIsDropdownShow(false);
                                                    }, 200)
                                                }
                                                disableDropdown={isDropdownCountryDisable}
                                                countryCodeEditable={!isDropdownCountryDisable}
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                }}
                                                country={'us'}
                                                value={otherProps?.value ? otherProps.value : null}
                                                onChange={(value) => {
                                                    if (otherProps && otherProps.onChange) {
                                                        otherProps.onChange({ target: { value: `+${value}` } });
                                                    }
                                                }}
                                                inputclassName={classNames('form-control py-1 w-100', {
                                                    'is-invalid': errors && errors[name] ? true : false,
                                                })}
                                                buttonStyle={{
                                                    borderColor: errors && errors[name] ? '#fa5c7c' : '#dee2e6',
                                                }}
                                                searchclassName="form-control"
                                                inputStyle={{
                                                    borderColor: errors && errors[name] ? '#fa5c7c' : '#dee2e6',
                                                    color: '#6c757d',
                                                }}
                                            />

                                            {isAutoComplete && otherProps.value && (
                                                <Dropdown
                                                    show={autocompleteSuggestions.length > 0 && isDropdownShow}
                                                    align="start"
                                                    className="w-100"
                                                    onSelect={handleSuggestionClick}>
                                                    <Dropdown.Menu className="w-100">
                                                        {autocompleteSuggestions.map((suggestion, index) => (
                                                            <OverlayTrigger
                                                                key={index}
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-${index}`}>
                                                                        {suggestion}
                                                                    </Tooltip>
                                                                }>
                                                                <Dropdown.Item key={index} eventKey={index}>
                                                                    <div
                                                                        style={{
                                                                            maxHeight: '40px', // Set the maximum height you want
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap', // This prevents the text from wrapping to the next line
                                                                        }}>
                                                                        {suggestion}
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </OverlayTrigger>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}

                                            {errors && errors[name] ? (
                                                <Form.Control.Feedback className="d-block" type="invalid">
                                                    {errors[name]['message']}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    ) : (
                                        <Form.Group className={containerClass}>
                                            {label ? (
                                                <>
                                                    <Form.Label htmlFor={name} className={labelClassName}>
                                                        {label}
                                                    </Form.Label>
                                                    {tooltipLabel && (
                                                        <PopoverTooltip message={tooltipLabel}>
                                                            <i
                                                                className="mdi mdi-information-outline ms-1"
                                                                data-bs-toggle="tooltip"
                                                                data-bs-title={tooltipLabel}></i>
                                                        </PopoverTooltip>
                                                    )}
                                                </>
                                            ) : null}
                                            <Form.Control
                                                onFocus={() => setIsDropdownShow(true)}
                                                onBlur={() =>
                                                    setTimeout(() => {
                                                        setIsDropdownShow(false);
                                                    }, 200)
                                                }
                                                type={type}
                                                placeholder={placeholder}
                                                name={name}
                                                id={name}
                                                as={comp}
                                                value={defaultValue}
                                                onChange={(value) => otherProps.onChange(value)}
                                                ref={(r) => {
                                                    if (refCallback) refCallback(r);
                                                }}
                                                className={className}
                                                isInvalid={errors && errors[name] ? true : false}
                                                {...(register ? register(name) : {})}
                                                {...otherProps}
                                                autoComplete={name}>
                                                {children ? children : null}
                                            </Form.Control>
                                            {isAutoComplete && otherProps.value && (
                                                <Dropdown
                                                    show={autocompleteSuggestions.length > 0 && isDropdownShow}
                                                    align="start"
                                                    className="w-100"
                                                    onSelect={handleSuggestionClick}>
                                                    <Dropdown.Menu className="w-100">
                                                        {autocompleteSuggestions.map((suggestion, index) => (
                                                            <OverlayTrigger
                                                                key={index}
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-${index}`}>
                                                                        {suggestion}
                                                                    </Tooltip>
                                                                }>
                                                                <Dropdown.Item key={index} eventKey={index}>
                                                                    <div
                                                                        style={{
                                                                            maxHeight: '40px', // Set the maximum height you want
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap', // This prevents the text from wrapping to the next line
                                                                        }}>
                                                                        {suggestion}
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </OverlayTrigger>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            )}

                                            {errors && errors[name] ? (
                                                <Form.Control.Feedback type="invalid">
                                                    {errors[name]['message']}
                                                </Form.Control.Feedback>
                                            ) : null}
                                        </Form.Group>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default FormInput;
