export const OrderAnalyticsActionTypes = {
    API_RESPONSE_SUCCESS: '@@order-analytics/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@order-analytics/API_RESPONSE_ERROR',
    GET_NO_CUSTOMERS_REQUEST: '@@order-analytics/GET_NO_CUSTOMERS_REQUEST',
    GET_NO_ORDERS_REQUEST: '@@order-analytics/GET_NO_ORDERS_REQUEST',
    GET_TOTAL_ORDER_REVENUE_REQUEST: '@@order-analytics/GET_TOTAL_ORDER_REVENUE_REQUEST',
    GET_DAILY_REVENUE_REQUEST: '@@order-analytics/GET_DAILY_REVENUE_REQUEST',
    GET_TOP_CUSTOMERS_REQUEST: '@@order-analytics/GET_TOP_CUSTOMERS_REQUEST',
    GET_TOP_PRODUCTS_REQUEST: '@@order-analytics/GET_TOP_PRODUCTS_REQUEST',
    GET_TOP_SOURCES_REQUEST: '@@order-analytics/GET_TOP_SOURCES_REQUEST',
};
