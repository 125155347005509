// @flow
import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import {
    createInvitation,
    deleteInvitation,
    getAllInvitation,
    resendInvitation,
    updateInvitation,
    updateUserRoles,
} from '../../helpers/';
import { invitationApiResponseSuccess, invitationApiResponseError } from './actions';
import { InvitationActionTypes } from './constants';
import { addToast, profileGetAllUser } from '../actions';
import { invitationResponseToInvitationObject } from '../../helpers/mappers/invitation';

function* invitationGetAllInvitation() {
    try {
        const response = yield call(getAllInvitation);
        yield put(
            invitationApiResponseSuccess(
                InvitationActionTypes.GET_ALL_INVITATION,
                response.data.map(invitationResponseToInvitationObject)
            )
        );
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
        yield put(invitationApiResponseError(InvitationActionTypes.GET_ALL_INVITATION, error));
    }
}

function* invitationCreateInvitation({ payload: { data, callback } }) {
    try {
        yield call(createInvitation, data);
        yield put(profileGetAllUser());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback();
    }
}

function* invitationDeleteInvitation({ payload: { id, callback } }) {
    try {
        yield call(deleteInvitation, id);
        yield put(profileGetAllUser());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback();
    }
}

function* invitationResendInvitation({ payload: { invitationId, callback } }) {
    try {
        yield call(resendInvitation, invitationId);
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback();
    }
}

function* invitationUpdateInvitation({ payload: { invitationId, data, callback } }) {
    try {
        yield call(updateInvitation, invitationId, data);
        yield put(profileGetAllUser());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback();
    }
}

function* invitationUpdateUserRoles({ payload: { userId, data, callback } }) {
    try {
        yield call(updateUserRoles, userId, data);
        yield put(profileGetAllUser());
    } catch (error) {
        yield put(addToast({ desc: error.errors[0].message, type: 'error' }));
    } finally {
        callback();
    }
}

export function* watchInvitationGetAllInvitation(): any {
    yield takeLatest(InvitationActionTypes.GET_ALL_INVITATION, invitationGetAllInvitation);
}

export function* watchInvitationCreateInvitation(): any {
    yield takeLatest(InvitationActionTypes.CREATE_INVITATION, invitationCreateInvitation);
}

export function* watchInvitationDeleteInvitation(): any {
    yield takeLatest(InvitationActionTypes.DELETE_INVITATION, invitationDeleteInvitation);
}

export function* watchInvitationResendInvitation(): any {
    yield takeLatest(InvitationActionTypes.RESEND_INVITATION, invitationResendInvitation);
}

export function* watchInvitationUpdateInvitation(): any {
    yield takeLatest(InvitationActionTypes.UPDATE_INVITATION, invitationUpdateInvitation);
}

export function* watchInvitationUpdateUserRoles(): any {
    yield takeLatest(InvitationActionTypes.UPDATE_USER_ROLES, invitationUpdateUserRoles);
}

function* invitationSaga(): any {
    yield all([
        fork(watchInvitationGetAllInvitation),
        fork(watchInvitationCreateInvitation),
        fork(watchInvitationDeleteInvitation),
        fork(watchInvitationResendInvitation),
        fork(watchInvitationUpdateInvitation),
        fork(watchInvitationUpdateUserRoles),
    ]);
}

export default invitationSaga;
